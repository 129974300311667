import React, { useEffect } from "react";
import { closeModal, ContextModalProps, openContextModal } from "@mantine/modals";
import { Button, TextInput, Stack, Text, Box, Image, Group } from "@mantine/core";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import * as Analytics from "../../Utils/Analytics";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import {
	FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult,
	FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultPersonalInfoRequirements,
	FxIdApplicationStoreOrderProductData,
	FxIdApplicationStorePriceData
} from "@app/Api/gen";
import { useIsMobile } from "@app/Screens/WebPlayer/Hooks";
import AnalyticsSender from "@app/Api/AnalyticsSender";

// Schema for email and phone validation
const createSchema = (t: (key: string) => string) =>
	z.object({
		email: z
			.string()
			.email({ message: t("invalid_email") })
			.optional(),
		phone: z
			.string()
			.regex(/^\+?[1-9]\d{1,14}$/, { message: t("invalid_phone_number") })
			.optional()
	});

type FormValues = z.infer<ReturnType<typeof createSchema>>;

// Add this new interface
export interface PersonalInfoSubmitData {
	email?: string;
	phone?: string;
	autoConfirm?: boolean;
}

// Extend your props interface with an optional "mode" prop.
// mode === "vertical" (default) will use the current layout (with skip added next to submit),
// and mode === "horizontal" will use the alternative layout.
export interface PersonalInfoInputModalInnerProps extends Record<string, unknown> {
	onSubmit: (data: PersonalInfoSubmitData) => void | Promise<void>;
	onClose?: () => void;
	/**
	 * Если тру то не будет показана инфа кроме названия товара
	 */
	compact?: boolean;
	mode?: "vertical" | "horizontal";
	source: "modal" | "store" | "component";
	paymentResult: {
		TransactionId: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult["TransactionId"];
		PersonalInfoRequirements?: Pick<
			FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultPersonalInfoRequirements,
			"EmailRequirement"
		> | null;
		OrderProduct: Pick<
			FxIdApplicationStoreOrderProductData,
			"LocalizedProductName" | "LocalizedProductDescription" | "ImageUrl" | "Price" | "Currency"
		>;
		PriceData: Pick<FxIdApplicationStorePriceData, "LocalizedPrice">;
	};
}

interface ModalParams extends PersonalInfoInputModalInnerProps {
	target?: string;
	analyticsData?: Parameters<(typeof Analytics)["site"]["openConfirmPurchaseWithPersonalInformation"]>[number];
}

export const ConfirmPurchaseWithPersonalInfoInput = (props: PersonalInfoInputModalInnerProps) => {
	const { t } = useTranslation();
	const schema = createSchema(t);
	const { onSubmit, onClose, paymentResult, compact, mode = "horizontal" } = props;
	const isMobile = useIsMobile();

	void AnalyticsSender.SendWebUiPersonalInformationFormShown(props.paymentResult.TransactionId, props.source);

	const {
		register,
		handleSubmit,
		formState: { errors },
		setFocus,
		getValues,
		setError
	} = useForm<FormValues>({
		resolver: zodResolver(schema),
		defaultValues: {
			email: paymentResult?.PersonalInfoRequirements?.EmailRequirement?.DefaultValue ?? undefined,
			phone: undefined
		}
	});

	const onSubmitForm = (data: FormValues) => {
		const submitData: PersonalInfoSubmitData = {
			email: data.email?.trim(),
			phone: data.phone?.trim()
		};
		onSubmit(submitData);
	};

	// A handler for the Skip button – it calls onSubmit with an empty object.
	const handleSkip = async (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();

		// Validate the form
		const result = await schema.safeParseAsync(getValues());

		if (result.success) {
			const submitData: PersonalInfoSubmitData = {
				email: result.data.email?.trim(),
				phone: result.data.phone?.trim(),
				autoConfirm: true
			};
			onSubmit(submitData);
		} else {
			// Handle validation errors
			result.error.issues.forEach((issue) => {
				setError(issue.path[0] as keyof FormValues, {
					type: "manual",
					message: t(issue.message as any) // Use t() to translate the error message
				});
			});
		}
	};

	useEffect(() => {
		return () => {
			onClose?.();
		};
	}, [onClose]);

	useEffect(() => {
		setFocus("email");
	}, [setFocus]);

	return (
		<form onSubmit={handleSubmit(onSubmitForm)}>
			{mode === "horizontal" ? (
				<Stack spacing="md">
					{/* Top row: product info (optionally with image if compact is false) */}
					<Box>
						<Group spacing="sm" align="center">
							{/* Only show image if not in compact mode */}
							{!compact && paymentResult.OrderProduct.ImageUrl && (
								<Image
									src={paymentResult.OrderProduct.ImageUrl}
									alt={paymentResult.OrderProduct.LocalizedProductName}
									width={50}
									height={50}
								/>
							)}
							<Text color="dark">{paymentResult.OrderProduct.LocalizedProductDescription}</Text>
						</Group>
					</Box>

					<Box>
						<Text size="sm" weight={500} color="dark">
							{t("personal_info_modal.main_text")}
						</Text>
					</Box>

					{/* Email input field */}
					<TextInput
						{...register("email")}
						placeholder="Email"
						error={errors.email?.message}
						size="lg"
						styles={(theme) => ({
							input: {
								fontSize: theme.fontSizes.lg,
								padding: theme.spacing.lg
							}
						})}
					/>

					{/* Action buttons */}
					<Group spacing="md">
						<Button variant="outline" type="submit">
							{t("personal_info_modal.button_text")}
						</Button>
						<Button onClick={handleSkip}>{t("personal_info_modal.auto_confirm_button_text")}</Button>
					</Group>
				</Stack>
			) : (
				// Vertical mode layout: similar to current, but with the skip button next to Submit.
				<Stack spacing="md">
					<Box>
						<Text size="sm" weight={500} color="dark">
							{t("personal_info_modal.main_text")}
						</Text>
					</Box>

					<TextInput
						{...register("email")}
						// label={t("personal_info_modal.email_label")}
						error={errors.email?.message}
						size="lg"
						placeholder="Email"
						styles={(theme) => ({
							input: {
								fontSize: theme.fontSizes.lg,
								padding: theme.spacing.lg
							},
							label: {
								fontSize: theme.fontSizes.lg,
								marginBottom: theme.spacing.lg,
								marginTop: theme.spacing.lg
							}
						})}
					/>

					<Group spacing="md">
						<Button variant="outline" type="submit">
							{t("personal_info_modal.button_text")}
						</Button>
						<Button onClick={handleSkip}>{t("personal_info_modal.auto_confirm_button_text")}</Button>
					</Group>

					<Box>
						<Text weight={700} color="dark">
							{paymentResult.OrderProduct.LocalizedProductName}
						</Text>
						<Text color="dark">{paymentResult.OrderProduct.LocalizedProductDescription}</Text>
						{compact !== true && (
							<>
								<Image
									src={paymentResult.OrderProduct.ImageUrl}
									alt={paymentResult.OrderProduct.LocalizedProductName}
								/>
							</>
						)}
					</Box>
				</Stack>
			)}
		</form>
	);
};

export const ConfirmPurchaseWithPersonalInfoInputModal = ({
	context,
	id,
	innerProps
}: ContextModalProps<PersonalInfoInputModalInnerProps>) => {
	const handleSubmit = async (data: PersonalInfoSubmitData) => {
		try {
			await innerProps.onSubmit(data);
			closeModal(id);
		} catch (error) {
			// Handle any errors that occur during submission
			log.error("Error submitting personal info:", error);
			// Optionally, you can show an error message to the user here
		}
	};

	return <ConfirmPurchaseWithPersonalInfoInput {...innerProps} onSubmit={handleSubmit} />;
};

export const openConfirmPurchaseWithPersonalInfoModal = (props: ModalParams) => {
	const { target, analyticsData, onClose, ...innerProps } = props;

	void Analytics.site.openConfirmPurchaseWithPersonalInformation?.({
		...analyticsData
	});

	return openContextModal({
		modal: "confirmPurchaseWithPersonalInfoModal",
		title: i18next.t("login_from_email.message"),
		innerProps: { ...innerProps, onClose },
		styles: (theme) => ({
			header: { marginBottom: theme.spacing.md },
			title: { fontWeight: 700 }
		}),
		size: "sm",
		target,
		centered: true
	});
};
