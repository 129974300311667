/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdApplicationStoreYandexGamesYandexBonusErrorResult
 */
export interface FxIdApplicationStoreYandexGamesYandexBonusErrorResult {
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationStoreYandexGamesYandexBonusErrorResult
     */
    Error: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationStoreYandexGamesYandexBonusErrorResult
     */
    ConsumeToken: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationStoreYandexGamesYandexBonusErrorResult
     */
    UniqueId: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationStoreYandexGamesYandexBonusErrorResult
     */
    Sku: string;
}

/**
 * Check if a given object implements the FxIdApplicationStoreYandexGamesYandexBonusErrorResult interface.
 */
export function instanceOfFxIdApplicationStoreYandexGamesYandexBonusErrorResult(value: object): value is FxIdApplicationStoreYandexGamesYandexBonusErrorResult {
    if (!('Error' in value) || value['Error'] === undefined) return false;
    if (!('ConsumeToken' in value) || value['ConsumeToken'] === undefined) return false;
    if (!('UniqueId' in value) || value['UniqueId'] === undefined) return false;
    if (!('Sku' in value) || value['Sku'] === undefined) return false;
    return true;
}

export function FxIdApplicationStoreYandexGamesYandexBonusErrorResultFromJSON(json: any): FxIdApplicationStoreYandexGamesYandexBonusErrorResult {
    return FxIdApplicationStoreYandexGamesYandexBonusErrorResultFromJSONTyped(json, false);
}

export function FxIdApplicationStoreYandexGamesYandexBonusErrorResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationStoreYandexGamesYandexBonusErrorResult {
    if (json == null) {
        return json;
    }
    return {
        
        'Error': json['Error'],
        'ConsumeToken': json['ConsumeToken'],
        'UniqueId': json['UniqueId'],
        'Sku': json['Sku'],
    };
}

export function FxIdApplicationStoreYandexGamesYandexBonusErrorResultToJSON(json: any): FxIdApplicationStoreYandexGamesYandexBonusErrorResult {
    return FxIdApplicationStoreYandexGamesYandexBonusErrorResultToJSONTyped(json, false);
}

export function FxIdApplicationStoreYandexGamesYandexBonusErrorResultToJSONTyped(value?: FxIdApplicationStoreYandexGamesYandexBonusErrorResult | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'Error': value['Error'],
        'ConsumeToken': value['ConsumeToken'],
        'UniqueId': value['UniqueId'],
        'Sku': value['Sku'],
    };
}

