/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdApplicationStoreCreatePaymentHandlerPersonalInfoRequirementEmail
 */
export interface FxIdApplicationStoreCreatePaymentHandlerPersonalInfoRequirementEmail {
    /**
     * 
     * @type {boolean}
     * @memberof FxIdApplicationStoreCreatePaymentHandlerPersonalInfoRequirementEmail
     */
    IsRequired: boolean;
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationStoreCreatePaymentHandlerPersonalInfoRequirementEmail
     */
    DefaultValue?: string | null;
}

/**
 * Check if a given object implements the FxIdApplicationStoreCreatePaymentHandlerPersonalInfoRequirementEmail interface.
 */
export function instanceOfFxIdApplicationStoreCreatePaymentHandlerPersonalInfoRequirementEmail(value: object): value is FxIdApplicationStoreCreatePaymentHandlerPersonalInfoRequirementEmail {
    if (!('IsRequired' in value) || value['IsRequired'] === undefined) return false;
    return true;
}

export function FxIdApplicationStoreCreatePaymentHandlerPersonalInfoRequirementEmailFromJSON(json: any): FxIdApplicationStoreCreatePaymentHandlerPersonalInfoRequirementEmail {
    return FxIdApplicationStoreCreatePaymentHandlerPersonalInfoRequirementEmailFromJSONTyped(json, false);
}

export function FxIdApplicationStoreCreatePaymentHandlerPersonalInfoRequirementEmailFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationStoreCreatePaymentHandlerPersonalInfoRequirementEmail {
    if (json == null) {
        return json;
    }
    return {
        
        'IsRequired': json['IsRequired'],
        'DefaultValue': json['DefaultValue'] == null ? undefined : json['DefaultValue'],
    };
}

export function FxIdApplicationStoreCreatePaymentHandlerPersonalInfoRequirementEmailToJSON(json: any): FxIdApplicationStoreCreatePaymentHandlerPersonalInfoRequirementEmail {
    return FxIdApplicationStoreCreatePaymentHandlerPersonalInfoRequirementEmailToJSONTyped(json, false);
}

export function FxIdApplicationStoreCreatePaymentHandlerPersonalInfoRequirementEmailToJSONTyped(value?: FxIdApplicationStoreCreatePaymentHandlerPersonalInfoRequirementEmail | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'IsRequired': value['IsRequired'],
        'DefaultValue': value['DefaultValue'],
    };
}

