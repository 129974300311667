/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxGamesLibWebPublicDataBase } from './FxGamesLibWebPublicDataBase';
import {
    instanceOfFxGamesLibWebPublicDataBase,
    FxGamesLibWebPublicDataBaseFromJSON,
    FxGamesLibWebPublicDataBaseFromJSONTyped,
    FxGamesLibWebPublicDataBaseToJSON,
} from './FxGamesLibWebPublicDataBase';

/**
 * @type FxIdWebFeaturesGamesChooseGameResponsePublicGameData
 * 
 * @export
 */
export type FxIdWebFeaturesGamesChooseGameResponsePublicGameData = FxGamesLibWebPublicDataBase;

export function FxIdWebFeaturesGamesChooseGameResponsePublicGameDataFromJSON(json: any): FxIdWebFeaturesGamesChooseGameResponsePublicGameData {
    return FxIdWebFeaturesGamesChooseGameResponsePublicGameDataFromJSONTyped(json, false);
}

export function FxIdWebFeaturesGamesChooseGameResponsePublicGameDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesGamesChooseGameResponsePublicGameData {
    if (json == null) {
        return json;
    }
    if (instanceOfFxGamesLibWebPublicDataBase(json)) {
        return FxGamesLibWebPublicDataBaseFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdWebFeaturesGamesChooseGameResponsePublicGameDataToJSON(json: any): any {
    return FxIdWebFeaturesGamesChooseGameResponsePublicGameDataToJSONTyped(json, false);
}

export function FxIdWebFeaturesGamesChooseGameResponsePublicGameDataToJSONTyped(value?: FxIdWebFeaturesGamesChooseGameResponsePublicGameData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxGamesLibWebPublicDataBase(value)) {
        return FxGamesLibWebPublicDataBaseToJSON(value as FxGamesLibWebPublicDataBase);
    }

    return {};
}

