/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdDomainSettingsPaymentPersonalInformationFormOptions } from './FxIdDomainSettingsPaymentPersonalInformationFormOptions';
import {
    instanceOfFxIdDomainSettingsPaymentPersonalInformationFormOptions,
    FxIdDomainSettingsPaymentPersonalInformationFormOptionsFromJSON,
    FxIdDomainSettingsPaymentPersonalInformationFormOptionsFromJSONTyped,
    FxIdDomainSettingsPaymentPersonalInformationFormOptionsToJSON,
} from './FxIdDomainSettingsPaymentPersonalInformationFormOptions';

/**
 * @type FxIdDomainSettingsPublicWebClientConfigOptionsPersonalInformationForm
 * Параметры для формы сбора персональных данных для платежа (например емейл для юкассы)
 * @export
 */
export type FxIdDomainSettingsPublicWebClientConfigOptionsPersonalInformationForm = FxIdDomainSettingsPaymentPersonalInformationFormOptions;

export function FxIdDomainSettingsPublicWebClientConfigOptionsPersonalInformationFormFromJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsPersonalInformationForm {
    return FxIdDomainSettingsPublicWebClientConfigOptionsPersonalInformationFormFromJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsPersonalInformationFormFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsPublicWebClientConfigOptionsPersonalInformationForm {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdDomainSettingsPaymentPersonalInformationFormOptions(json)) {
        return FxIdDomainSettingsPaymentPersonalInformationFormOptionsFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsPersonalInformationFormToJSON(json: any): any {
    return FxIdDomainSettingsPublicWebClientConfigOptionsPersonalInformationFormToJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsPersonalInformationFormToJSONTyped(value?: FxIdDomainSettingsPublicWebClientConfigOptionsPersonalInformationForm | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdDomainSettingsPaymentPersonalInformationFormOptions(value)) {
        return FxIdDomainSettingsPaymentPersonalInformationFormOptionsToJSON(value as FxIdDomainSettingsPaymentPersonalInformationFormOptions);
    }

    return {};
}

