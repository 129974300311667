/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum FxIdDomainEntitiesPlayGameType {
    Unity = 0,
    Iframe = 1,
    NUMBER_unknown_default_open_api = 11184809
}


export function instanceOfFxIdDomainEntitiesPlayGameType(value: any): boolean {
    for (const key in FxIdDomainEntitiesPlayGameType) {
        if (Object.prototype.hasOwnProperty.call(FxIdDomainEntitiesPlayGameType, key)) {
            if (FxIdDomainEntitiesPlayGameType[key as keyof typeof FxIdDomainEntitiesPlayGameType] === value) {
                return true;
            }
        }
    }
    return false;
}

export function FxIdDomainEntitiesPlayGameTypeFromJSON(json: any): FxIdDomainEntitiesPlayGameType {
    return FxIdDomainEntitiesPlayGameTypeFromJSONTyped(json, false);
}

export function FxIdDomainEntitiesPlayGameTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainEntitiesPlayGameType {
    return json as FxIdDomainEntitiesPlayGameType;
}

export function FxIdDomainEntitiesPlayGameTypeToJSON(value?: FxIdDomainEntitiesPlayGameType | null): any {
    return value as any;
}

export function FxIdDomainEntitiesPlayGameTypeToJSONTyped(value: any, ignoreDiscriminator: boolean): FxIdDomainEntitiesPlayGameType {
    return value as FxIdDomainEntitiesPlayGameType;
}

