import { useLocation, useNavigate } from "react-router-dom";

export async function rerouteTo500OnError<T>(
	history: ReturnType<typeof useNavigate>,
	func: () => Promise<T>
): Promise<T | undefined> {
	return redirectOnError("/error", history, func);
}

export type ErrorResponseData = {
	status: number;
	statusText: string;
	body: string;
	url: string;
};

export async function redirectOnError<T>(
	to: string,
	history: ReturnType<typeof useNavigate>,
	func: () => Promise<T>
): Promise<T | undefined> {
	return new Promise<T | undefined>((resolve, reject) => {
		func()
			.then(resolve)
			.catch(async (e) => {
				log.error(e);
				let responseData: ErrorResponseData | undefined = undefined;
				if (e instanceof Response) {
					responseData = {
						status: e.status,
						statusText: e.statusText,
						body: await e.text(),
						url: e.url
					};
				}
				history(to, { state: responseData, replace: true });
				resolve(undefined);
			});
	});
}

/**
 * https://stackoverflow.com/a/58362609
 */
export const exitFullscreen = async () => {
	try {
		// As correctly mentioned in the accepted answer, exitFullscreen only works on document
		const cancellFullScreen =
			document.exitFullscreen ||
			(document as any).mozCancelFullScreen ||
			(document as any).webkitExitFullscreen ||
			(document as any).msExitFullscreen;
		log.info("Exiting fullscreen");
		await cancellFullScreen.call(document);
		log.info("Exited fullscreen");
	} catch (err) {
		// Тут будет ошибка дескать "мы не вышли из фулскрина потому-что мы не там - нам не важно
		// ignore
	}
};

export function errorToString(error: Error | unknown): string {
	if (!error) {
		return "Unknown error";
	}

	// If it's an Error instance
	if (error instanceof Error) {
		const errorMessage = error.message;

		// Handle case where error.message is an object
		if (typeof errorMessage === "object" && errorMessage !== null) {
			try {
				return JSON.stringify(errorMessage);
			} catch {
				return "[Complex Error Object]";
			}
		}

		// Handle string messages and add stack trace in development
		return process.env.NODE_ENV === "development" ? `${errorMessage}\n${error.stack || ""}` : errorMessage;
	}

	// Handle non-Error objects
	if (typeof error === "object") {
		try {
			return JSON.stringify(error);
		} catch {
			return "[Unstringifiable Error Object]";
		}
	}

	// Handle primitive values
	return String(error);
}
