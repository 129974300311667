/// THIS IS AUTOGENERATED FILE!
export interface IBaseAnalyticsEvent {
UserId: number;
CreatedAt: number;
ServerCreatedAt: number;
Build: number;
Platform: number;
}

export interface IAnalyticsEvent extends IBaseAnalyticsEvent {
EventId: number;
I1?: number | null;
I2?: number | null;
I3?: number | null;
I4?: number | null;
I5?: number | null;
I6?: number | null;
I7?: number | null;
I8?: number | null;
I9?: number | null;
I10?: number | null;
F1?: number | null;
F2?: number | null;
F3?: number | null;
F4?: number | null;
F5?: number | null;
F6?: number | null;
F7?: number | null;
F8?: number | null;
F9?: number | null;
F10?: number | null;
S1?: string | null;
S2?: string | null;
S3?: string | null;
S4?: string | null;
S5?: string | null;
S6?: string | null;
S7?: string | null;
S8?: string | null;
S9?: string | null;
S10?: string | null;
S11?: string | null;
S12?: string | null;
S13?: string | null;
S14?: string | null;
S15?: string | null;
J1?: string | null;
J2?: string | null;
J3?: string | null;
J4?: string | null;
}

export interface IAnalyticsEventsSender {
BuildEvent: (createdAt?: number) => IBaseAnalyticsEvent;
SendEvent: (analyticsEvent: IAnalyticsEvent) => Promise<void>;
}

export enum AnalyticsEventDownloadStatusState{
Added = 0,Resuming = 1,Checking = 2,Loading = 3,Downloading = 4,Finished = 5,Seeding = 6,Idle = 7
}


export class AnalyticsEvents {
private readonly _sender: IAnalyticsEventsSender;
	constructor(sender: IAnalyticsEventsSender) {
this._sender = sender;
}

public BuildDownloadRequestTrack(analyticsEvent: IBaseAnalyticsEvent,game:string,sessionIdStr:string,url:string,queryString:string,query:string,sessionId:number,cookies:string): IAnalyticsEvent{
return {
...analyticsEvent,
EventId: 781965163,
S1: game,
S2: sessionIdStr,
S3: url,
S4: queryString,
S5: query,
I1: sessionId,
J2: cookies,
};
}

public SendDownloadRequestTrack(game:string,sessionIdStr:string,url:string,queryString:string,query:string,sessionId:number,cookies:string, createdAt?: number): Promise<void>{
const analyticsEvent: IAnalyticsEvent = this.BuildDownloadRequestTrack(
this._sender.BuildEvent(createdAt),
game,
sessionIdStr,
url,
queryString,
query,
sessionId,
cookies,
)
return this._sender.SendEvent(analyticsEvent);
}

public BuildDownloadTrack(analyticsEvent: IBaseAnalyticsEvent,game:string,sessionIdStr:string,sessionId:number): IAnalyticsEvent{
return {
...analyticsEvent,
EventId: 781965166,
S1: game,
S2: sessionIdStr,
I1: sessionId,
};
}

public SendDownloadTrack(game:string,sessionIdStr:string,sessionId:number, createdAt?: number): Promise<void>{
const analyticsEvent: IAnalyticsEvent = this.BuildDownloadTrack(
this._sender.BuildEvent(createdAt),
game,
sessionIdStr,
sessionId,
)
return this._sender.SendEvent(analyticsEvent);
}

public BuildAuthenticateWithExtIdSignup(analyticsEvent: IBaseAnalyticsEvent,game:string,sessionIdStr:string,email:string,sessionId:number): IAnalyticsEvent{
return {
...analyticsEvent,
EventId: 781965164,
S1: game,
S2: sessionIdStr,
S3: email,
I1: sessionId,
};
}

public SendAuthenticateWithExtIdSignup(game:string,sessionIdStr:string,email:string,sessionId:number, createdAt?: number): Promise<void>{
const analyticsEvent: IAnalyticsEvent = this.BuildAuthenticateWithExtIdSignup(
this._sender.BuildEvent(createdAt),
game,
sessionIdStr,
email,
sessionId,
)
return this._sender.SendEvent(analyticsEvent);
}

public BuildAuthenticateWithExtIdLogin(analyticsEvent: IBaseAnalyticsEvent,game:string,sessionIdStr:string,email:string,sessionId:number): IAnalyticsEvent{
return {
...analyticsEvent,
EventId: 781965165,
S1: game,
S2: sessionIdStr,
S3: email,
I1: sessionId,
};
}

public SendAuthenticateWithExtIdLogin(game:string,sessionIdStr:string,email:string,sessionId:number, createdAt?: number): Promise<void>{
const analyticsEvent: IAnalyticsEvent = this.BuildAuthenticateWithExtIdLogin(
this._sender.BuildEvent(createdAt),
game,
sessionIdStr,
email,
sessionId,
)
return this._sender.SendEvent(analyticsEvent);
}

public BuildDownloadStatus(analyticsEvent: IBaseAnalyticsEvent,branch:string,game:string,sessionId:number,state:AnalyticsEventDownloadStatusState,uploadSpeedBytes:number,downloadSeedBytes:number,buildId:number,progress:number): IAnalyticsEvent{
return {
...analyticsEvent,
EventId: 781965176,
S1: branch,
S2: game,
I1: sessionId,
I2: state,
I3: uploadSpeedBytes,
I4: downloadSeedBytes,
I5: buildId,
F1: progress,
};
}

public SendDownloadStatus(branch:string,game:string,sessionId:number,state:AnalyticsEventDownloadStatusState,uploadSpeedBytes:number,downloadSeedBytes:number,buildId:number,progress:number, createdAt?: number): Promise<void>{
const analyticsEvent: IAnalyticsEvent = this.BuildDownloadStatus(
this._sender.BuildEvent(createdAt),
branch,
game,
sessionId,
state,
uploadSpeedBytes,
downloadSeedBytes,
buildId,
progress,
)
return this._sender.SendEvent(analyticsEvent);
}

public BuildChangeState(analyticsEvent: IBaseAnalyticsEvent,game:string,sessionId:number,fromState:number,toState:number,timeBetweenSeconds:number): IAnalyticsEvent{
return {
...analyticsEvent,
EventId: 781965177,
S1: game,
I1: sessionId,
I2: fromState,
I3: toState,
F1: timeBetweenSeconds,
};
}

public SendChangeState(game:string,sessionId:number,fromState:number,toState:number,timeBetweenSeconds:number, createdAt?: number): Promise<void>{
const analyticsEvent: IAnalyticsEvent = this.BuildChangeState(
this._sender.BuildEvent(createdAt),
game,
sessionId,
fromState,
toState,
timeBetweenSeconds,
)
return this._sender.SendEvent(analyticsEvent);
}

public BuildGameConnected(analyticsEvent: IBaseAnalyticsEvent,game:string,gameId:string): IAnalyticsEvent{
return {
...analyticsEvent,
EventId: 781965178,
S1: game,
S2: gameId,
};
}

public SendGameConnected(game:string,gameId:string, createdAt?: number): Promise<void>{
const analyticsEvent: IAnalyticsEvent = this.BuildGameConnected(
this._sender.BuildEvent(createdAt),
game,
gameId,
)
return this._sender.SendEvent(analyticsEvent);
}

public BuildLauncherStarted(analyticsEvent: IBaseAnalyticsEvent,appCode:string,clientGuid:string,fxapiApiType:string,fxapiCefVersion:string,fxapiAppVersion:string,fxapiLoaderName:string,fxapiHwProfileGuid:string,fxapiIsSteamdeck:number): IAnalyticsEvent{
return {
...analyticsEvent,
EventId: 781965179,
S1: appCode,
S2: clientGuid,
S3: fxapiApiType,
S4: fxapiCefVersion,
S5: fxapiAppVersion,
S6: fxapiLoaderName,
S7: fxapiHwProfileGuid,
I1: fxapiIsSteamdeck,
};
}

public SendLauncherStarted(appCode:string,clientGuid:string,fxapiApiType:string,fxapiCefVersion:string,fxapiAppVersion:string,fxapiLoaderName:string,fxapiHwProfileGuid:string,fxapiIsSteamdeck:number, createdAt?: number): Promise<void>{
const analyticsEvent: IAnalyticsEvent = this.BuildLauncherStarted(
this._sender.BuildEvent(createdAt),
appCode,
clientGuid,
fxapiApiType,
fxapiCefVersion,
fxapiAppVersion,
fxapiLoaderName,
fxapiHwProfileGuid,
fxapiIsSteamdeck,
)
return this._sender.SendEvent(analyticsEvent);
}

public BuildOrderStateChanged(analyticsEvent: IBaseAnalyticsEvent,game:string,skuCode:string,additionalProductsSkus:string,currency:string,currencySkuPrice:string,merchant:string,merchantCurrency:string,paymentMethod:string,orderId:string,orderPublicId:string,usdPriceTier:number,merchantValue:number): IAnalyticsEvent{
return {
...analyticsEvent,
EventId: 781965182,
S1: game,
S2: skuCode,
S3: additionalProductsSkus,
S4: currency,
S5: currencySkuPrice,
S6: merchant,
S7: merchantCurrency,
S8: paymentMethod,
S9: orderId,
S10: orderPublicId,
F1: usdPriceTier,
F2: merchantValue,
};
}

public SendOrderStateChanged(game:string,skuCode:string,additionalProductsSkus:string,currency:string,currencySkuPrice:string,merchant:string,merchantCurrency:string,paymentMethod:string,orderId:string,orderPublicId:string,usdPriceTier:number,merchantValue:number, createdAt?: number): Promise<void>{
const analyticsEvent: IAnalyticsEvent = this.BuildOrderStateChanged(
this._sender.BuildEvent(createdAt),
game,
skuCode,
additionalProductsSkus,
currency,
currencySkuPrice,
merchant,
merchantCurrency,
paymentMethod,
orderId,
orderPublicId,
usdPriceTier,
merchantValue,
)
return this._sender.SendEvent(analyticsEvent);
}

public BuildSocialIdentifierAdded(analyticsEvent: IBaseAnalyticsEvent,identifier:string): IAnalyticsEvent{
return {
...analyticsEvent,
EventId: 781965183,
S1: identifier,
};
}

public SendSocialIdentifierAdded(identifier:string, createdAt?: number): Promise<void>{
const analyticsEvent: IAnalyticsEvent = this.BuildSocialIdentifierAdded(
this._sender.BuildEvent(createdAt),
identifier,
)
return this._sender.SendEvent(analyticsEvent);
}

public BuildUserReview(analyticsEvent: IBaseAnalyticsEvent,game:string,reviewText:string,score:number): IAnalyticsEvent{
return {
...analyticsEvent,
EventId: 781965184,
S1: game,
S2: reviewText,
I1: score,
};
}

public SendUserReview(game:string,reviewText:string,score:number, createdAt?: number): Promise<void>{
const analyticsEvent: IAnalyticsEvent = this.BuildUserReview(
this._sender.BuildEvent(createdAt),
game,
reviewText,
score,
)
return this._sender.SendEvent(analyticsEvent);
}

public BuildWebUiPersonalInformationFormShown(analyticsEvent: IBaseAnalyticsEvent,publicOrderId:string,source:string): IAnalyticsEvent{
return {
...analyticsEvent,
EventId: 781965186,
S1: publicOrderId,
S2: source,
};
}

public SendWebUiPersonalInformationFormShown(publicOrderId:string,source:string, createdAt?: number): Promise<void>{
const analyticsEvent: IAnalyticsEvent = this.BuildWebUiPersonalInformationFormShown(
this._sender.BuildEvent(createdAt),
publicOrderId,
source,
)
return this._sender.SendEvent(analyticsEvent);
}

public BuildWebUiPersonalInformationFormAutofilled(analyticsEvent: IBaseAnalyticsEvent,publicOrderId:string,source:string): IAnalyticsEvent{
return {
...analyticsEvent,
EventId: 781965187,
S1: publicOrderId,
S2: source,
};
}

public SendWebUiPersonalInformationFormAutofilled(publicOrderId:string,source:string, createdAt?: number): Promise<void>{
const analyticsEvent: IAnalyticsEvent = this.BuildWebUiPersonalInformationFormAutofilled(
this._sender.BuildEvent(createdAt),
publicOrderId,
source,
)
return this._sender.SendEvent(analyticsEvent);
}

public BuildWebUiPersonalInformationFormSubmitted(analyticsEvent: IBaseAnalyticsEvent,publicOrderId:string,source:string): IAnalyticsEvent{
return {
...analyticsEvent,
EventId: 781965188,
S1: publicOrderId,
S2: source,
};
}

public SendWebUiPersonalInformationFormSubmitted(publicOrderId:string,source:string, createdAt?: number): Promise<void>{
const analyticsEvent: IAnalyticsEvent = this.BuildWebUiPersonalInformationFormSubmitted(
this._sender.BuildEvent(createdAt),
publicOrderId,
source,
)
return this._sender.SendEvent(analyticsEvent);
}

public BuildWebUiBank131FormShown(analyticsEvent: IBaseAnalyticsEvent,publicOrderId:string,source:string): IAnalyticsEvent{
return {
...analyticsEvent,
EventId: 781965189,
S1: publicOrderId,
S2: source,
};
}

public SendWebUiBank131FormShown(publicOrderId:string,source:string, createdAt?: number): Promise<void>{
const analyticsEvent: IAnalyticsEvent = this.BuildWebUiBank131FormShown(
this._sender.BuildEvent(createdAt),
publicOrderId,
source,
)
return this._sender.SendEvent(analyticsEvent);
}

public BuildWebUiBank131FormCardClicked(analyticsEvent: IBaseAnalyticsEvent,publicOrderId:string,source:string): IAnalyticsEvent{
return {
...analyticsEvent,
EventId: 781965190,
S1: publicOrderId,
S2: source,
};
}

public SendWebUiBank131FormCardClicked(publicOrderId:string,source:string, createdAt?: number): Promise<void>{
const analyticsEvent: IAnalyticsEvent = this.BuildWebUiBank131FormCardClicked(
this._sender.BuildEvent(createdAt),
publicOrderId,
source,
)
return this._sender.SendEvent(analyticsEvent);
}

public BuildWebUiBank131FormFpsClicked(analyticsEvent: IBaseAnalyticsEvent,publicOrderId:string,source:string): IAnalyticsEvent{
return {
...analyticsEvent,
EventId: 781965191,
S1: publicOrderId,
S2: source,
};
}

public SendWebUiBank131FormFpsClicked(publicOrderId:string,source:string, createdAt?: number): Promise<void>{
const analyticsEvent: IAnalyticsEvent = this.BuildWebUiBank131FormFpsClicked(
this._sender.BuildEvent(createdAt),
publicOrderId,
source,
)
return this._sender.SendEvent(analyticsEvent);
}

public BuildWebUiBank131FormCardSubmitted(analyticsEvent: IBaseAnalyticsEvent,publicOrderId:string,source:string): IAnalyticsEvent{
return {
...analyticsEvent,
EventId: 781965192,
S1: publicOrderId,
S2: source,
};
}

public SendWebUiBank131FormCardSubmitted(publicOrderId:string,source:string, createdAt?: number): Promise<void>{
const analyticsEvent: IAnalyticsEvent = this.BuildWebUiBank131FormCardSubmitted(
this._sender.BuildEvent(createdAt),
publicOrderId,
source,
)
return this._sender.SendEvent(analyticsEvent);
}

public BuildWebUiBank131FormFpsSubmitted(analyticsEvent: IBaseAnalyticsEvent,publicOrderId:string,source:string): IAnalyticsEvent{
return {
...analyticsEvent,
EventId: 781965193,
S1: publicOrderId,
S2: source,
};
}

public SendWebUiBank131FormFpsSubmitted(publicOrderId:string,source:string, createdAt?: number): Promise<void>{
const analyticsEvent: IAnalyticsEvent = this.BuildWebUiBank131FormFpsSubmitted(
this._sender.BuildEvent(createdAt),
publicOrderId,
source,
)
return this._sender.SendEvent(analyticsEvent);
}

public BuildPaymentBank131FpsDataReceived(analyticsEvent: IBaseAnalyticsEvent,publicOrderId:string,source:string): IAnalyticsEvent{
return {
...analyticsEvent,
EventId: 781965194,
S1: publicOrderId,
S2: source,
};
}

public SendPaymentBank131FpsDataReceived(publicOrderId:string,source:string, createdAt?: number): Promise<void>{
const analyticsEvent: IAnalyticsEvent = this.BuildPaymentBank131FpsDataReceived(
this._sender.BuildEvent(createdAt),
publicOrderId,
source,
)
return this._sender.SendEvent(analyticsEvent);
}

public BuildPaymentBank131FpsDataReceivingError(analyticsEvent: IBaseAnalyticsEvent,publicOrderId:string,source:string): IAnalyticsEvent{
return {
...analyticsEvent,
EventId: 781965195,
S1: publicOrderId,
S2: source,
};
}

public SendPaymentBank131FpsDataReceivingError(publicOrderId:string,source:string, createdAt?: number): Promise<void>{
const analyticsEvent: IAnalyticsEvent = this.BuildPaymentBank131FpsDataReceivingError(
this._sender.BuildEvent(createdAt),
publicOrderId,
source,
)
return this._sender.SendEvent(analyticsEvent);
}

public BuildPaymentBank131CardSuccess(analyticsEvent: IBaseAnalyticsEvent,publicOrderId:string,source:string): IAnalyticsEvent{
return {
...analyticsEvent,
EventId: 781965196,
S1: publicOrderId,
S2: source,
};
}

public SendPaymentBank131CardSuccess(publicOrderId:string,source:string, createdAt?: number): Promise<void>{
const analyticsEvent: IAnalyticsEvent = this.BuildPaymentBank131CardSuccess(
this._sender.BuildEvent(createdAt),
publicOrderId,
source,
)
return this._sender.SendEvent(analyticsEvent);
}

public BuildPaymentBank131CardCancelled(analyticsEvent: IBaseAnalyticsEvent,orderId:number): IAnalyticsEvent{
return {
...analyticsEvent,
EventId: 781965197,
I1: orderId,
};
}

public SendPaymentBank131CardCancelled(orderId:number, createdAt?: number): Promise<void>{
const analyticsEvent: IAnalyticsEvent = this.BuildPaymentBank131CardCancelled(
this._sender.BuildEvent(createdAt),
orderId,
)
return this._sender.SendEvent(analyticsEvent);
}

public BuildPaymentBank131FpsSuccess(analyticsEvent: IBaseAnalyticsEvent,orderId:number): IAnalyticsEvent{
return {
...analyticsEvent,
EventId: 781965198,
I1: orderId,
};
}

public SendPaymentBank131FpsSuccess(orderId:number, createdAt?: number): Promise<void>{
const analyticsEvent: IAnalyticsEvent = this.BuildPaymentBank131FpsSuccess(
this._sender.BuildEvent(createdAt),
orderId,
)
return this._sender.SendEvent(analyticsEvent);
}

}
