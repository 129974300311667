/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLocalizedString } from './FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLocalizedString';
import {
    instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLocalizedString,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLocalizedStringFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLocalizedStringFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLocalizedStringToJSON,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLocalizedString';

/**
 * @type FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLeaderboardConfigName
 * Название лидерборда
 * @export
 */
export type FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLeaderboardConfigName = FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLocalizedString;

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLeaderboardConfigNameFromJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLeaderboardConfigName {
    return FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLeaderboardConfigNameFromJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLeaderboardConfigNameFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLeaderboardConfigName {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLocalizedString(json)) {
        return FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLocalizedStringFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLeaderboardConfigNameToJSON(json: any): any {
    return FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLeaderboardConfigNameToJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLeaderboardConfigNameToJSONTyped(value?: FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLeaderboardConfigName | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLocalizedString(value)) {
        return FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLocalizedStringToJSON(value as FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLocalizedString);
    }

    return {};
}

