/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesDocumentsAcceptDocumentAcceptDocumentRequest
 */
export interface FxIdWebFeaturesDocumentsAcceptDocumentAcceptDocumentRequest {
    /**
     * 
     * @type {number}
     * @memberof FxIdWebFeaturesDocumentsAcceptDocumentAcceptDocumentRequest
     */
    DocumentId: number;
    /**
     * 
     * @type {number}
     * @memberof FxIdWebFeaturesDocumentsAcceptDocumentAcceptDocumentRequest
     */
    DocumentVersionId: number;
}

/**
 * Check if a given object implements the FxIdWebFeaturesDocumentsAcceptDocumentAcceptDocumentRequest interface.
 */
export function instanceOfFxIdWebFeaturesDocumentsAcceptDocumentAcceptDocumentRequest(value: object): value is FxIdWebFeaturesDocumentsAcceptDocumentAcceptDocumentRequest {
    if (!('DocumentId' in value) || value['DocumentId'] === undefined) return false;
    if (!('DocumentVersionId' in value) || value['DocumentVersionId'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesDocumentsAcceptDocumentAcceptDocumentRequestFromJSON(json: any): FxIdWebFeaturesDocumentsAcceptDocumentAcceptDocumentRequest {
    return FxIdWebFeaturesDocumentsAcceptDocumentAcceptDocumentRequestFromJSONTyped(json, false);
}

export function FxIdWebFeaturesDocumentsAcceptDocumentAcceptDocumentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesDocumentsAcceptDocumentAcceptDocumentRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'DocumentId': json['DocumentId'],
        'DocumentVersionId': json['DocumentVersionId'],
    };
}

export function FxIdWebFeaturesDocumentsAcceptDocumentAcceptDocumentRequestToJSON(json: any): FxIdWebFeaturesDocumentsAcceptDocumentAcceptDocumentRequest {
    return FxIdWebFeaturesDocumentsAcceptDocumentAcceptDocumentRequestToJSONTyped(json, false);
}

export function FxIdWebFeaturesDocumentsAcceptDocumentAcceptDocumentRequestToJSONTyped(value?: FxIdWebFeaturesDocumentsAcceptDocumentAcceptDocumentRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'DocumentId': value['DocumentId'],
        'DocumentVersionId': value['DocumentVersionId'],
    };
}

