import {
	FxIdDomainStoreEnumsSupportedWebPublishingPlatform,
	FxIdWebFeaturesGamesChooseGamePortalConfig,
	FxIdWebFeaturesGamesChooseGameRequest,
	FxIdWebFeaturesGamesChooseGameResponse,
	FxIdWebFeaturesGamesChooseGameResponsePublicGameData,
	FxGamesLibWebPublicDataBase
} from "@app/Api/gen";
import OpenApiClient from "@app/Api/OpenApiClient";
import { produce } from "immer";
import create from "zustand"; // Исправленный импорт
import { IframeType, IframeTypeToSupportedPlatformMap } from "../Hooks";
import { DEFAULT_GAME_CONFIG, DEFAULT_PORTAL_CONFIG } from "../utils/constants";

export interface IWebPlayerStore {
	chooseGameResponse: FxIdWebFeaturesGamesChooseGameResponse | null;
	fetchChosenGame: (payload: FxIdWebFeaturesGamesChooseGameRequest) => Promise<void>;

	gameId: string; //GameSystemName
	gameConfig: FxIdWebFeaturesGamesChooseGameResponsePublicGameData;
	setGameConfig: (
		gameConfig: FxIdWebFeaturesGamesChooseGameResponsePublicGameData | FxGamesLibWebPublicDataBase
	) => void;
	portalConfig: FxIdWebFeaturesGamesChooseGamePortalConfig;
	adapter: IframeType;
	setAdapter: (adapter: IframeType) => void;
	platform: FxIdDomainStoreEnumsSupportedWebPublishingPlatform;
	setPlatform: (platform: FxIdDomainStoreEnumsSupportedWebPublishingPlatform) => void;

	loading: boolean;
	error: string | null;
}

const appDefaultAdapter: IframeType = import.meta.env.VITE_APP_DEFAULT_ADAPTER ?? "fxid";

export const webPlayerStore = create<IWebPlayerStore>((set, get) => ({
	chooseGameResponse: null,

	gameConfig: DEFAULT_GAME_CONFIG,
	portalConfig: DEFAULT_PORTAL_CONFIG,
	adapter: appDefaultAdapter,
	platform: IframeTypeToSupportedPlatformMap[appDefaultAdapter],
	loading: false,
	error: null,
	gameId: "",

	fetchChosenGame: async (payload: FxIdWebFeaturesGamesChooseGameRequest) => {
		log.info("Requesting ChosenGame");
		set(
			produce((store: IWebPlayerStore) => {
				store.loading = true;
				store.error = null;
			})
		);

		try {
			const response = await OpenApiClient.Play.fxIdWebFeaturesGamesChooseGameEndpoint({
				GameSystemName: payload.GameSystemName,
				OriginUrl: payload.OriginUrl
			});
			const gameConfig = response.PublicGameData;
			log.info("Response ChosenGame", response);

			const defaultAdapter: IframeType = (gameConfig?.PublicWebClientConfig.ForcedAdapter ??
				appDefaultAdapter) as IframeType;
			const defaultPlatform = IframeTypeToSupportedPlatformMap[defaultAdapter];

			set(
				produce((store: IWebPlayerStore) => {
					store.chooseGameResponse = response;
					store.gameConfig = gameConfig || DEFAULT_GAME_CONFIG;
					store.portalConfig = response.PortalConfig;
					store.adapter = defaultAdapter;
					store.platform = defaultPlatform;
					store.gameId = gameConfig?.GameSystemName || "";
				})
			);
		} catch (error: any) {
			set(
				produce((store: IWebPlayerStore) => {
					store.error = error.message || "Unknown error";
				})
			);
		} finally {
			set(
				produce((store: IWebPlayerStore) => {
					store.loading = false;
				})
			);
		}
	},
	setAdapter: (adapter: IframeType) => {
		set(
			produce((store: IWebPlayerStore) => {
				store.adapter = adapter;
			})
		);
	},
	setPlatform: (platform: FxIdDomainStoreEnumsSupportedWebPublishingPlatform) => {
		set(
			produce((store: IWebPlayerStore) => {
				store.platform = platform;
			})
		);
	},
	setGameConfig: (gameConfig: FxIdWebFeaturesGamesChooseGameResponsePublicGameData) => {
		log.info("setGameConfig...", gameConfig);

		set(
			produce((store: IWebPlayerStore) => {
				store.gameConfig = gameConfig;
			})
		);
	}
}));
