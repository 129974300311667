import React from "react";
import { GlobalMessageProps } from "../../Stores";

type ContextValue = {
	messages: Record<string, ((props: GlobalMessageProps) => JSX.Element) | undefined>;
};

const defaultValue: ContextValue = { messages: {} };

const GlobalMessagesContext = React.createContext<ContextValue | undefined>(undefined);

export const GlobalMessagesProvider = (props: ContextValue & { children?: React.ReactNode }) => {
	const { children, ...contextValue } = props;
	return <GlobalMessagesContext.Provider value={contextValue}>{children}</GlobalMessagesContext.Provider>;
};

export const useGlobalMessagesContext = () => {
	const contextValue = React.useContext(GlobalMessagesContext);
	if (!contextValue) {
		log.warn(
			"%No Provider for LoadingContext!",
			"font-weight: bold;",
			"Add it in component tree above a <GlobalMessagesProvider>."
		);
		return defaultValue;
	}
	return contextValue;
};
