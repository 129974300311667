/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FxIdWebModelsTagLinkView } from './FxIdWebModelsTagLinkView';
import {
    FxIdWebModelsTagLinkViewFromJSON,
    FxIdWebModelsTagLinkViewFromJSONTyped,
    FxIdWebModelsTagLinkViewToJSON,
    FxIdWebModelsTagLinkViewToJSONTyped,
} from './FxIdWebModelsTagLinkView';
import type { FxIdApplicationTagsProvidersSurveyData } from './FxIdApplicationTagsProvidersSurveyData';
import {
    FxIdApplicationTagsProvidersSurveyDataFromJSON,
    FxIdApplicationTagsProvidersSurveyDataFromJSONTyped,
    FxIdApplicationTagsProvidersSurveyDataToJSON,
    FxIdApplicationTagsProvidersSurveyDataToJSONTyped,
} from './FxIdApplicationTagsProvidersSurveyData';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesProfileGetResponseConnectedGame
 */
export interface FxIdWebFeaturesProfileGetResponseConnectedGame {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesProfileGetResponseConnectedGame
     */
    Game: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesProfileGetResponseConnectedGame
     */
    GameJwt: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesProfileGetResponseConnectedGame
     */
    GameUid: string;
    /**
     * 
     * @type {boolean}
     * @memberof FxIdWebFeaturesProfileGetResponseConnectedGame
     */
    StoreEnabled: boolean;
    /**
     * 
     * @type {Array<FxIdWebModelsTagLinkView>}
     * @memberof FxIdWebFeaturesProfileGetResponseConnectedGame
     */
    TagsLinks: Array<FxIdWebModelsTagLinkView>;
    /**
     * 
     * @type {{ [key: string]: FxIdApplicationTagsProvidersSurveyData; }}
     * @memberof FxIdWebFeaturesProfileGetResponseConnectedGame
     */
    Surveys: { [key: string]: FxIdApplicationTagsProvidersSurveyData; };
}

/**
 * Check if a given object implements the FxIdWebFeaturesProfileGetResponseConnectedGame interface.
 */
export function instanceOfFxIdWebFeaturesProfileGetResponseConnectedGame(value: object): value is FxIdWebFeaturesProfileGetResponseConnectedGame {
    if (!('Game' in value) || value['Game'] === undefined) return false;
    if (!('GameJwt' in value) || value['GameJwt'] === undefined) return false;
    if (!('GameUid' in value) || value['GameUid'] === undefined) return false;
    if (!('StoreEnabled' in value) || value['StoreEnabled'] === undefined) return false;
    if (!('TagsLinks' in value) || value['TagsLinks'] === undefined) return false;
    if (!('Surveys' in value) || value['Surveys'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesProfileGetResponseConnectedGameFromJSON(json: any): FxIdWebFeaturesProfileGetResponseConnectedGame {
    return FxIdWebFeaturesProfileGetResponseConnectedGameFromJSONTyped(json, false);
}

export function FxIdWebFeaturesProfileGetResponseConnectedGameFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesProfileGetResponseConnectedGame {
    if (json == null) {
        return json;
    }
    return {
        
        'Game': json['Game'],
        'GameJwt': json['GameJwt'],
        'GameUid': json['GameUid'],
        'StoreEnabled': json['StoreEnabled'],
        'TagsLinks': ((json['TagsLinks'] as Array<any>).map(FxIdWebModelsTagLinkViewFromJSON)),
        'Surveys': (mapValues(json['Surveys'], FxIdApplicationTagsProvidersSurveyDataFromJSON)),
    };
}

export function FxIdWebFeaturesProfileGetResponseConnectedGameToJSON(json: any): FxIdWebFeaturesProfileGetResponseConnectedGame {
    return FxIdWebFeaturesProfileGetResponseConnectedGameToJSONTyped(json, false);
}

export function FxIdWebFeaturesProfileGetResponseConnectedGameToJSONTyped(value?: FxIdWebFeaturesProfileGetResponseConnectedGame | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'Game': value['Game'],
        'GameJwt': value['GameJwt'],
        'GameUid': value['GameUid'],
        'StoreEnabled': value['StoreEnabled'],
        'TagsLinks': ((value['TagsLinks'] as Array<any>).map(FxIdWebModelsTagLinkViewToJSON)),
        'Surveys': (mapValues(value['Surveys'], FxIdApplicationTagsProvidersSurveyDataToJSON)),
    };
}

