/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxGamesLibWebPublicDataBaseStatisticsOptions } from './FxGamesLibWebPublicDataBaseStatisticsOptions';
import {
    instanceOfFxGamesLibWebPublicDataBaseStatisticsOptions,
    FxGamesLibWebPublicDataBaseStatisticsOptionsFromJSON,
    FxGamesLibWebPublicDataBaseStatisticsOptionsFromJSONTyped,
    FxGamesLibWebPublicDataBaseStatisticsOptionsToJSON,
} from './FxGamesLibWebPublicDataBaseStatisticsOptions';

/**
 * @type FxGamesLibWebPublicDataBaseStatistics
 * 
 * @export
 */
export type FxGamesLibWebPublicDataBaseStatistics = FxGamesLibWebPublicDataBaseStatisticsOptions;

export function FxGamesLibWebPublicDataBaseStatisticsFromJSON(json: any): FxGamesLibWebPublicDataBaseStatistics {
    return FxGamesLibWebPublicDataBaseStatisticsFromJSONTyped(json, false);
}

export function FxGamesLibWebPublicDataBaseStatisticsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxGamesLibWebPublicDataBaseStatistics {
    if (json == null) {
        return json;
    }
    if (instanceOfFxGamesLibWebPublicDataBaseStatisticsOptions(json)) {
        return FxGamesLibWebPublicDataBaseStatisticsOptionsFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxGamesLibWebPublicDataBaseStatisticsToJSON(json: any): any {
    return FxGamesLibWebPublicDataBaseStatisticsToJSONTyped(json, false);
}

export function FxGamesLibWebPublicDataBaseStatisticsToJSONTyped(value?: FxGamesLibWebPublicDataBaseStatistics | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxGamesLibWebPublicDataBaseStatisticsOptions(value)) {
        return FxGamesLibWebPublicDataBaseStatisticsOptionsToJSON(value as FxGamesLibWebPublicDataBaseStatisticsOptions);
    }

    return {};
}

