/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxGamesLibWebPlatformDataOptionsWindowsOptions
 */
export interface FxGamesLibWebPlatformDataOptionsWindowsOptions {
    /**
     * 
     * @type {string}
     * @memberof FxGamesLibWebPlatformDataOptionsWindowsOptions
     */
    AppLinkUrl: string;
    /**
     * 
     * @type {string}
     * @memberof FxGamesLibWebPlatformDataOptionsWindowsOptions
     */
    AppId: string;
}

/**
 * Check if a given object implements the FxGamesLibWebPlatformDataOptionsWindowsOptions interface.
 */
export function instanceOfFxGamesLibWebPlatformDataOptionsWindowsOptions(value: object): value is FxGamesLibWebPlatformDataOptionsWindowsOptions {
    if (!('AppLinkUrl' in value) || value['AppLinkUrl'] === undefined) return false;
    if (!('AppId' in value) || value['AppId'] === undefined) return false;
    return true;
}

export function FxGamesLibWebPlatformDataOptionsWindowsOptionsFromJSON(json: any): FxGamesLibWebPlatformDataOptionsWindowsOptions {
    return FxGamesLibWebPlatformDataOptionsWindowsOptionsFromJSONTyped(json, false);
}

export function FxGamesLibWebPlatformDataOptionsWindowsOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxGamesLibWebPlatformDataOptionsWindowsOptions {
    if (json == null) {
        return json;
    }
    return {
        
        'AppLinkUrl': json['AppLinkUrl'],
        'AppId': json['AppId'],
    };
}

export function FxGamesLibWebPlatformDataOptionsWindowsOptionsToJSON(json: any): FxGamesLibWebPlatformDataOptionsWindowsOptions {
    return FxGamesLibWebPlatformDataOptionsWindowsOptionsToJSONTyped(json, false);
}

export function FxGamesLibWebPlatformDataOptionsWindowsOptionsToJSONTyped(value?: FxGamesLibWebPlatformDataOptionsWindowsOptions | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'AppLinkUrl': value['AppLinkUrl'],
        'AppId': value['AppId'],
    };
}

