/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfig } from './FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfig';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfigFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfigFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfigToJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfigToJSONTyped,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfig';
import type { FxIdDomainSettingsPublicWebClientConfigOptionsFirebase } from './FxIdDomainSettingsPublicWebClientConfigOptionsFirebase';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsFirebaseFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsFirebaseFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsFirebaseToJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsFirebaseToJSONTyped,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsFirebase';
import type { FxIdDomainSettingsPublicWebClientConfigOptionsFxEvents } from './FxIdDomainSettingsPublicWebClientConfigOptionsFxEvents';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsFxEventsFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsFxEventsFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsFxEventsToJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsFxEventsToJSONTyped,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsFxEvents';
import type { FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverrides } from './FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverrides';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverridesFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverridesFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverridesToJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverridesToJSONTyped,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverrides';
import type { FxIdDomainSettingsPublicWebClientConfigOptionsPersonalInformationForm } from './FxIdDomainSettingsPublicWebClientConfigOptionsPersonalInformationForm';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsPersonalInformationFormFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsPersonalInformationFormFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsPersonalInformationFormToJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsPersonalInformationFormToJSONTyped,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsPersonalInformationForm';
import type { FxIdDomainSettingsPublicWebClientConfigOptionsUnity } from './FxIdDomainSettingsPublicWebClientConfigOptionsUnity';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsUnityFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsUnityFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsUnityToJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsUnityToJSONTyped,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsUnity';
import type { FxIdDomainSettingsPublicWebClientConfigOptionsBackground } from './FxIdDomainSettingsPublicWebClientConfigOptionsBackground';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsBackgroundFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsBackgroundFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsBackgroundToJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsBackgroundToJSONTyped,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsBackground';
import type { FxIdDomainSettingsPublicWebClientConfigOptionsAdvertisement } from './FxIdDomainSettingsPublicWebClientConfigOptionsAdvertisement';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsAdvertisementFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsAdvertisementFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsAdvertisementToJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsAdvertisementToJSONTyped,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsAdvertisement';
import type { FxIdDomainSettingsPublicWebClientConfigOptionsDiagItem } from './FxIdDomainSettingsPublicWebClientConfigOptionsDiagItem';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsDiagItemFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsDiagItemFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsDiagItemToJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsDiagItemToJSONTyped,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsDiagItem';
import type { FxIdDomainSettingsPublicWebClientConfigOptionsAuth } from './FxIdDomainSettingsPublicWebClientConfigOptionsAuth';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsAuthFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsAuthFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsAuthToJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsAuthToJSONTyped,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsAuth';
import type { FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactsValue } from './FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactsValue';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactsValueFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactsValueFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactsValueToJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactsValueToJSONTyped,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactsValue';
import type { FxIdDomainSettingsPublicWebClientConfigOptionsLauncher } from './FxIdDomainSettingsPublicWebClientConfigOptionsLauncher';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherToJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherToJSONTyped,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsLauncher';

/**
 * 
 * @export
 * @interface FxIdDomainSettingsPublicWebClientConfigOptions
 */
export interface FxIdDomainSettingsPublicWebClientConfigOptions {
    /**
     * 
     * @type {Array<FxIdDomainSettingsPublicWebClientConfigOptionsDiagItem>}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptions
     */
    Diag?: Array<FxIdDomainSettingsPublicWebClientConfigOptionsDiagItem> | null;
    /**
     * 
     * @type {FxIdDomainSettingsPublicWebClientConfigOptionsLauncher}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptions
     */
    Launcher?: FxIdDomainSettingsPublicWebClientConfigOptionsLauncher | null;
    /**
     * Изображение для карточки игры на лендинге
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptions
     */
    CardImageUrl: string;
    /**
     * Полноценный логотип игры (помещается на загрузочный экран веб-плеера)
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptions
     */
    FullLogoUrl?: string | null;
    /**
     * Фоновая картинка для загрузочного экрана веб-плеера
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptions
     */
    SplashImageUrl?: string | null;
    /**
     * Ставится в хидер в FirebaseModal
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptions
     */
    BrandLogoUrl?: string | null;
    /**
     * 
     * @type {FxIdDomainSettingsPublicWebClientConfigOptionsBackground}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptions
     */
    Background?: FxIdDomainSettingsPublicWebClientConfigOptionsBackground | null;
    /**
     * Небольшой логотип игры (помещается на карточку игры на лендинге)
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptions
     */
    SmallLogoUrl: string;
    /**
     * Логотип компании
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptions
     */
    HeaderLogo?: string | null;
    /**
     * Иконка игры (для установки веб-версии на устройство)
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptions
     */
    IconUrl: string;
    /**
     * URL для запуска игры на любом устройстве
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptions
     */
    ExternalPlayUrl?: string | null;
    /**
     * Промо-баннер для игры
     * @type {boolean}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptions
     */
    PromoEnabled: boolean;
    /**
     * 
     * @type {FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverrides}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptions
     */
    AvailabilityOverrides?: FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverrides | null;
    /**
     * Если включен, то в хидере будут отображаться друзья
     * @type {boolean}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptions
     */
    SocialSectionAvailable?: boolean | null;
    /**
     * Отключение кнопки "Магазин ресурсов"
     * @type {boolean}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptions
     */
    DisableStoreLink?: boolean | null;
    /**
     * 
     * @type {FxIdDomainSettingsPublicWebClientConfigOptionsFirebase}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptions
     */
    Firebase?: FxIdDomainSettingsPublicWebClientConfigOptionsFirebase | null;
    /**
     * 
     * @type {FxIdDomainSettingsPublicWebClientConfigOptionsAuth}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptions
     */
    Auth?: FxIdDomainSettingsPublicWebClientConfigOptionsAuth | null;
    /**
     * 
     * @type {{ [key: string]: FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactsValue; }}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptions
     */
    LegalContacts?: { [key: string]: FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactsValue; } | null;
    /**
     * Если установлен то адаптер будет форсирован
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptions
     */
    ForcedAdapter?: string | null;
    /**
     * Настройки для адаптеров
     * @type {Array<FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfig>}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptions
     */
    Adapters?: Array<FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfig> | null;
    /**
     * 
     * @type {FxIdDomainSettingsPublicWebClientConfigOptionsFxEvents}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptions
     */
    FxEvents?: FxIdDomainSettingsPublicWebClientConfigOptionsFxEvents | null;
    /**
     * 
     * @type {FxIdDomainSettingsPublicWebClientConfigOptionsAdvertisement}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptions
     */
    Advertisement?: FxIdDomainSettingsPublicWebClientConfigOptionsAdvertisement | null;
    /**
     * 
     * @type {FxIdDomainSettingsPublicWebClientConfigOptionsUnity}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptions
     */
    Unity?: FxIdDomainSettingsPublicWebClientConfigOptionsUnity | null;
    /**
     * 
     * @type {FxIdDomainSettingsPublicWebClientConfigOptionsPersonalInformationForm}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptions
     */
    PersonalInformationForm?: FxIdDomainSettingsPublicWebClientConfigOptionsPersonalInformationForm | null;
}

/**
 * Check if a given object implements the FxIdDomainSettingsPublicWebClientConfigOptions interface.
 */
export function instanceOfFxIdDomainSettingsPublicWebClientConfigOptions(value: object): value is FxIdDomainSettingsPublicWebClientConfigOptions {
    if (!('CardImageUrl' in value) || value['CardImageUrl'] === undefined) return false;
    if (!('SmallLogoUrl' in value) || value['SmallLogoUrl'] === undefined) return false;
    if (!('IconUrl' in value) || value['IconUrl'] === undefined) return false;
    if (!('PromoEnabled' in value) || value['PromoEnabled'] === undefined) return false;
    return true;
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsFromJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptions {
    return FxIdDomainSettingsPublicWebClientConfigOptionsFromJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsPublicWebClientConfigOptions {
    if (json == null) {
        return json;
    }
    return {
        
        'Diag': json['Diag'] == null ? undefined : ((json['Diag'] as Array<any>).map(FxIdDomainSettingsPublicWebClientConfigOptionsDiagItemFromJSON)),
        'Launcher': json['Launcher'] == null ? undefined : FxIdDomainSettingsPublicWebClientConfigOptionsLauncherFromJSON(json['Launcher']),
        'CardImageUrl': json['CardImageUrl'],
        'FullLogoUrl': json['FullLogoUrl'] == null ? undefined : json['FullLogoUrl'],
        'SplashImageUrl': json['SplashImageUrl'] == null ? undefined : json['SplashImageUrl'],
        'BrandLogoUrl': json['BrandLogoUrl'] == null ? undefined : json['BrandLogoUrl'],
        'Background': json['Background'] == null ? undefined : FxIdDomainSettingsPublicWebClientConfigOptionsBackgroundFromJSON(json['Background']),
        'SmallLogoUrl': json['SmallLogoUrl'],
        'HeaderLogo': json['HeaderLogo'] == null ? undefined : json['HeaderLogo'],
        'IconUrl': json['IconUrl'],
        'ExternalPlayUrl': json['ExternalPlayUrl'] == null ? undefined : json['ExternalPlayUrl'],
        'PromoEnabled': json['PromoEnabled'],
        'AvailabilityOverrides': json['AvailabilityOverrides'] == null ? undefined : FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverridesFromJSON(json['AvailabilityOverrides']),
        'SocialSectionAvailable': json['SocialSectionAvailable'] == null ? undefined : json['SocialSectionAvailable'],
        'DisableStoreLink': json['DisableStoreLink'] == null ? undefined : json['DisableStoreLink'],
        'Firebase': json['Firebase'] == null ? undefined : FxIdDomainSettingsPublicWebClientConfigOptionsFirebaseFromJSON(json['Firebase']),
        'Auth': json['Auth'] == null ? undefined : FxIdDomainSettingsPublicWebClientConfigOptionsAuthFromJSON(json['Auth']),
        'LegalContacts': json['LegalContacts'] == null ? undefined : (mapValues(json['LegalContacts'], FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactsValueFromJSON)),
        'ForcedAdapter': json['ForcedAdapter'] == null ? undefined : json['ForcedAdapter'],
        'Adapters': json['Adapters'] == null ? undefined : ((json['Adapters'] as Array<any>).map(FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfigFromJSON)),
        'FxEvents': json['FxEvents'] == null ? undefined : FxIdDomainSettingsPublicWebClientConfigOptionsFxEventsFromJSON(json['FxEvents']),
        'Advertisement': json['Advertisement'] == null ? undefined : FxIdDomainSettingsPublicWebClientConfigOptionsAdvertisementFromJSON(json['Advertisement']),
        'Unity': json['Unity'] == null ? undefined : FxIdDomainSettingsPublicWebClientConfigOptionsUnityFromJSON(json['Unity']),
        'PersonalInformationForm': json['PersonalInformationForm'] == null ? undefined : FxIdDomainSettingsPublicWebClientConfigOptionsPersonalInformationFormFromJSON(json['PersonalInformationForm']),
    };
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsToJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptions {
    return FxIdDomainSettingsPublicWebClientConfigOptionsToJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsToJSONTyped(value?: FxIdDomainSettingsPublicWebClientConfigOptions | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'Diag': value['Diag'] == null ? undefined : ((value['Diag'] as Array<any>).map(FxIdDomainSettingsPublicWebClientConfigOptionsDiagItemToJSON)),
        'Launcher': FxIdDomainSettingsPublicWebClientConfigOptionsLauncherToJSON(value['Launcher']),
        'CardImageUrl': value['CardImageUrl'],
        'FullLogoUrl': value['FullLogoUrl'],
        'SplashImageUrl': value['SplashImageUrl'],
        'BrandLogoUrl': value['BrandLogoUrl'],
        'Background': FxIdDomainSettingsPublicWebClientConfigOptionsBackgroundToJSON(value['Background']),
        'SmallLogoUrl': value['SmallLogoUrl'],
        'HeaderLogo': value['HeaderLogo'],
        'IconUrl': value['IconUrl'],
        'ExternalPlayUrl': value['ExternalPlayUrl'],
        'PromoEnabled': value['PromoEnabled'],
        'AvailabilityOverrides': FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverridesToJSON(value['AvailabilityOverrides']),
        'SocialSectionAvailable': value['SocialSectionAvailable'],
        'DisableStoreLink': value['DisableStoreLink'],
        'Firebase': FxIdDomainSettingsPublicWebClientConfigOptionsFirebaseToJSON(value['Firebase']),
        'Auth': FxIdDomainSettingsPublicWebClientConfigOptionsAuthToJSON(value['Auth']),
        'LegalContacts': value['LegalContacts'] == null ? undefined : (mapValues(value['LegalContacts'], FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactsValueToJSON)),
        'ForcedAdapter': value['ForcedAdapter'],
        'Adapters': value['Adapters'] == null ? undefined : ((value['Adapters'] as Array<any>).map(FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfigToJSON)),
        'FxEvents': FxIdDomainSettingsPublicWebClientConfigOptionsFxEventsToJSON(value['FxEvents']),
        'Advertisement': FxIdDomainSettingsPublicWebClientConfigOptionsAdvertisementToJSON(value['Advertisement']),
        'Unity': FxIdDomainSettingsPublicWebClientConfigOptionsUnityToJSON(value['Unity']),
        'PersonalInformationForm': FxIdDomainSettingsPublicWebClientConfigOptionsPersonalInformationFormToJSON(value['PersonalInformationForm']),
    };
}

