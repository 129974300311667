/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FastEndpointsErrorResponse,
  FxGamesLibWebRpcContractsResponse,
  FxIdApplicationHandlersSupportSendMessageSendSupportMessageCommand,
} from '../models/index';
import {
    FastEndpointsErrorResponseFromJSON,
    FastEndpointsErrorResponseToJSON,
    FxGamesLibWebRpcContractsResponseFromJSON,
    FxGamesLibWebRpcContractsResponseToJSON,
    FxIdApplicationHandlersSupportSendMessageSendSupportMessageCommandFromJSON,
    FxIdApplicationHandlersSupportSendMessageSendSupportMessageCommandToJSON,
} from '../models/index';

export interface FxIdWebFeaturesSupportSendSupportMessageSendSupportMessageEndpointRequest {
    fxIdApplicationHandlersSupportSendMessageSendSupportMessageCommand: FxIdApplicationHandlersSupportSendMessageSendSupportMessageCommand;
}

/**
 * 
 */
export class SupportApi extends runtime.BaseAPI {

    /**
     */
    async fxIdWebFeaturesSupportSendSupportMessageSendSupportMessageEndpointRaw(requestParameters: FxIdWebFeaturesSupportSendSupportMessageSendSupportMessageEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FxGamesLibWebRpcContractsResponse>> {
        if (requestParameters['fxIdApplicationHandlersSupportSendMessageSendSupportMessageCommand'] == null) {
            throw new runtime.RequiredError(
                'fxIdApplicationHandlersSupportSendMessageSendSupportMessageCommand',
                'Required parameter "fxIdApplicationHandlersSupportSendMessageSendSupportMessageCommand" was null or undefined when calling fxIdWebFeaturesSupportSendSupportMessageSendSupportMessageEndpoint().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWTBearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/support/send-message`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FxIdApplicationHandlersSupportSendMessageSendSupportMessageCommandToJSON(requestParameters['fxIdApplicationHandlersSupportSendMessageSendSupportMessageCommand']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FxGamesLibWebRpcContractsResponseFromJSON(jsonValue));
    }

    /**
     */
    async fxIdWebFeaturesSupportSendSupportMessageSendSupportMessageEndpoint(fxIdApplicationHandlersSupportSendMessageSendSupportMessageCommand: FxIdApplicationHandlersSupportSendMessageSendSupportMessageCommand, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FxGamesLibWebRpcContractsResponse> {
        const response = await this.fxIdWebFeaturesSupportSendSupportMessageSendSupportMessageEndpointRaw({ fxIdApplicationHandlersSupportSendMessageSendSupportMessageCommand: fxIdApplicationHandlersSupportSendMessageSendSupportMessageCommand }, initOverrides);
        return await response.value();
    }

}
