import "./index.css";

import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { FaCog } from "react-icons/fa";

import { logout } from "../../Api/Auth";
import { FxIdWebFeaturesProfileGetResponse } from "../../Api/gen";
import { Button, MantineProvider, Menu } from "@mantine/core";
import { Link } from "react-router-dom";
import { useUserProfileSettingsButtonState } from "../../Stores";

const UserProfileSettingsButton = (props: { profileData?: FxIdWebFeaturesProfileGetResponse }) => {
	const { t } = useTranslation();
	const { profileData } = props;

	const [isEnabled] = useUserProfileSettingsButtonState();

	if (!profileData || !isEnabled) return null;

	const isInIframe = window.location !== window.parent.location;

	const handleLogoutButtonClick = async () => {
		await logout();
		window.location.reload();
	};

	return (
		<MantineProvider theme={{ components: { Menu: { styles: { itemLabel: { color: "#000" } } } } }}>
			<Menu position="bottom-end" withinPortal styles={(theme) => ({ label: { fontSize: theme.fontSizes.sm } })}>
				<Menu.Target>
					<Button>
						<FaCog size={24} />
					</Button>
				</Menu.Target>
				<Menu.Dropdown>
					{profileData.Email && (
						<Menu.Label>
							<Trans i18nKey="user_settings_menu.email" values={{ email: profileData.Email }} />
						</Menu.Label>
					)}
					<Menu.Item component={Link} to="/profile">
						{t("button_to_profile")}
					</Menu.Item>
					{!isInIframe && (
						<Menu.Item onClick={() => void handleLogoutButtonClick()}>
							{t("user_settings_menu.logout")}
						</Menu.Item>
					)}
				</Menu.Dropdown>
			</Menu>
		</MantineProvider>
	);
};

export default UserProfileSettingsButton;
