import { Group, Popover, SimpleGrid, Text, UnstyledButton, useMantineTheme, UnstyledButtonProps } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import React from "react";
import { useTranslation } from "react-i18next";
import { IconBase } from "react-icons";
import { MdCheck, MdLanguage, MdUnfoldMore } from "react-icons/md";
import { SUPPORTED_LANGUAGES } from "../../i18n";
import { capitalize } from "../../Utils/Functions";

const LanguageItem = React.forwardRef<
	HTMLButtonElement,
	UnstyledButtonProps & React.HTMLAttributes<HTMLButtonElement> & { locale: Intl.Locale }
>(function LanguageItem(props, ref) {
	const { i18n } = useTranslation();
	const { locale, onClick } = props;

	const theme = useMantineTheme();

	const languageName = capitalize(new Intl.DisplayNames(locale, { type: "language" }).of(locale.language));
	const isActive = i18n.language.toLowerCase() === locale.baseName.toLowerCase();
	return (
		<UnstyledButton
			ref={ref}
			onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
				void i18n.changeLanguage(locale.baseName);
				onClick?.(e);
			}}
			sx={{
				color: isActive ? theme.colors.gray[0] : theme.colors.gray[5],
				"&:hover": { color: theme.colors.gray[0] }
			}}
		>
			<Group spacing="xs" align="flex-start">
				{isActive ? <MdCheck color={theme.colors.blue[5]} size={20} /> : <IconBase size={20} />}
				<Text>{languageName}</Text>
			</Group>
		</UnstyledButton>
	);
});

export const LanguageSelector = () => {
	const { i18n } = useTranslation();
	const [opened, { close, toggle }] = useDisclosure(false);

	const theme = useMantineTheme();

	const selectedLanguage = capitalize(
		new Intl.DisplayNames(i18n.languages[0], { type: "language" }).of(i18n.languages[0])
	);
	return (
		<Popover width={300} opened={opened} onClose={close}>
			<Popover.Target>
				<UnstyledButton
					onClick={toggle}
					sx={{
						color: theme.colors.gray[3],
						"&:hover": { color: theme.colors.gray[0] }
					}}
				>
					<Group spacing="xs" align="center">
						<MdLanguage size={24} color={theme.colors.blue[5]} />
						<Text>{selectedLanguage}</Text>
						<MdUnfoldMore />
					</Group>
				</UnstyledButton>
			</Popover.Target>
			<Popover.Dropdown>
				<SimpleGrid cols={2}>
					{[...SUPPORTED_LANGUAGES].map(([locale], index) => (
						<LanguageItem key={index} locale={locale} onClick={close} />
					))}
				</SimpleGrid>
			</Popover.Dropdown>
		</Popover>
	);
};
