/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdApplicationHandlersStoreUpdateOrderPIHandlerOrderPersonalInfo
 */
export interface FxIdApplicationHandlersStoreUpdateOrderPIHandlerOrderPersonalInfo {
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationHandlersStoreUpdateOrderPIHandlerOrderPersonalInfo
     */
    Email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationHandlersStoreUpdateOrderPIHandlerOrderPersonalInfo
     */
    PhoneNumber?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FxIdApplicationHandlersStoreUpdateOrderPIHandlerOrderPersonalInfo
     */
    IsAutoConfirm?: boolean | null;
}

/**
 * Check if a given object implements the FxIdApplicationHandlersStoreUpdateOrderPIHandlerOrderPersonalInfo interface.
 */
export function instanceOfFxIdApplicationHandlersStoreUpdateOrderPIHandlerOrderPersonalInfo(value: object): value is FxIdApplicationHandlersStoreUpdateOrderPIHandlerOrderPersonalInfo {
    return true;
}

export function FxIdApplicationHandlersStoreUpdateOrderPIHandlerOrderPersonalInfoFromJSON(json: any): FxIdApplicationHandlersStoreUpdateOrderPIHandlerOrderPersonalInfo {
    return FxIdApplicationHandlersStoreUpdateOrderPIHandlerOrderPersonalInfoFromJSONTyped(json, false);
}

export function FxIdApplicationHandlersStoreUpdateOrderPIHandlerOrderPersonalInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationHandlersStoreUpdateOrderPIHandlerOrderPersonalInfo {
    if (json == null) {
        return json;
    }
    return {
        
        'Email': json['Email'] == null ? undefined : json['Email'],
        'PhoneNumber': json['PhoneNumber'] == null ? undefined : json['PhoneNumber'],
        'IsAutoConfirm': json['IsAutoConfirm'] == null ? undefined : json['IsAutoConfirm'],
    };
}

export function FxIdApplicationHandlersStoreUpdateOrderPIHandlerOrderPersonalInfoToJSON(json: any): FxIdApplicationHandlersStoreUpdateOrderPIHandlerOrderPersonalInfo {
    return FxIdApplicationHandlersStoreUpdateOrderPIHandlerOrderPersonalInfoToJSONTyped(json, false);
}

export function FxIdApplicationHandlersStoreUpdateOrderPIHandlerOrderPersonalInfoToJSONTyped(value?: FxIdApplicationHandlersStoreUpdateOrderPIHandlerOrderPersonalInfo | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'Email': value['Email'],
        'PhoneNumber': value['PhoneNumber'],
        'IsAutoConfirm': value['IsAutoConfirm'],
    };
}

