/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MassTransitCommonSupportServiceMessageData } from './MassTransitCommonSupportServiceMessageData';
import {
    MassTransitCommonSupportServiceMessageDataFromJSON,
    MassTransitCommonSupportServiceMessageDataFromJSONTyped,
    MassTransitCommonSupportServiceMessageDataToJSON,
    MassTransitCommonSupportServiceMessageDataToJSONTyped,
} from './MassTransitCommonSupportServiceMessageData';

/**
 * 
 * @export
 * @interface FxIdApplicationHandlersSupportSendMessageSendSupportMessageCommand
 */
export interface FxIdApplicationHandlersSupportSendMessageSendSupportMessageCommand {
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationHandlersSupportSendMessageSendSupportMessageCommand
     */
    GameSystemName: string;
    /**
     * 
     * @type {MassTransitCommonSupportServiceMessageData}
     * @memberof FxIdApplicationHandlersSupportSendMessageSendSupportMessageCommand
     */
    Data: MassTransitCommonSupportServiceMessageData;
}

/**
 * Check if a given object implements the FxIdApplicationHandlersSupportSendMessageSendSupportMessageCommand interface.
 */
export function instanceOfFxIdApplicationHandlersSupportSendMessageSendSupportMessageCommand(value: object): value is FxIdApplicationHandlersSupportSendMessageSendSupportMessageCommand {
    if (!('GameSystemName' in value) || value['GameSystemName'] === undefined) return false;
    if (!('Data' in value) || value['Data'] === undefined) return false;
    return true;
}

export function FxIdApplicationHandlersSupportSendMessageSendSupportMessageCommandFromJSON(json: any): FxIdApplicationHandlersSupportSendMessageSendSupportMessageCommand {
    return FxIdApplicationHandlersSupportSendMessageSendSupportMessageCommandFromJSONTyped(json, false);
}

export function FxIdApplicationHandlersSupportSendMessageSendSupportMessageCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationHandlersSupportSendMessageSendSupportMessageCommand {
    if (json == null) {
        return json;
    }
    return {
        
        'GameSystemName': json['GameSystemName'],
        'Data': MassTransitCommonSupportServiceMessageDataFromJSON(json['Data']),
    };
}

export function FxIdApplicationHandlersSupportSendMessageSendSupportMessageCommandToJSON(json: any): FxIdApplicationHandlersSupportSendMessageSendSupportMessageCommand {
    return FxIdApplicationHandlersSupportSendMessageSendSupportMessageCommandToJSONTyped(json, false);
}

export function FxIdApplicationHandlersSupportSendMessageSendSupportMessageCommandToJSONTyped(value?: FxIdApplicationHandlersSupportSendMessageSendSupportMessageCommand | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'GameSystemName': value['GameSystemName'],
        'Data': MassTransitCommonSupportServiceMessageDataToJSON(value['Data']),
    };
}

