/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersEpicGamesStoreAuthDataEgsAuthData } from './FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersEpicGamesStoreAuthDataEgsAuthData';
import {
    instanceOfFxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersEpicGamesStoreAuthDataEgsAuthData,
    FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersEpicGamesStoreAuthDataEgsAuthDataFromJSON,
    FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersEpicGamesStoreAuthDataEgsAuthDataFromJSONTyped,
    FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersEpicGamesStoreAuthDataEgsAuthDataToJSON,
} from './FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersEpicGamesStoreAuthDataEgsAuthData';

/**
 * @type FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestEgsLogin
 * 
 * @export
 */
export type FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestEgsLogin = FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersEpicGamesStoreAuthDataEgsAuthData;

export function FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestEgsLoginFromJSON(json: any): FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestEgsLogin {
    return FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestEgsLoginFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestEgsLoginFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestEgsLogin {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersEpicGamesStoreAuthDataEgsAuthData(json)) {
        return FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersEpicGamesStoreAuthDataEgsAuthDataFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestEgsLoginToJSON(json: any): any {
    return FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestEgsLoginToJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestEgsLoginToJSONTyped(value?: FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestEgsLogin | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersEpicGamesStoreAuthDataEgsAuthData(value)) {
        return FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersEpicGamesStoreAuthDataEgsAuthDataToJSON(value as FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersEpicGamesStoreAuthDataEgsAuthData);
    }

    return {};
}

