export interface IGoogleTagManagerConfiguration {
	tag: string;
	env?: {
		auth: string;
		preview: string;
	};
}

export const GOOGLE_TAG_MANAGER_IDS: Map<string, IGoogleTagManagerConfiguration> = new Map([
	// ["id-dev.fx.gl", "G-BMHL1VPT2R"],
	// ["id.fx.gl", "G-YQLK7CX75G"],
	// ["galaxycontrol.app", "G-9737TFYD27"],
	// ["dragonlord.games", "G-N34FBC0TJY"],
	// {
	// 	domains: ["battlearena.games", "game-portal-battle.stage.rbwtech.net"]
	// }
	// ["game-portal-battle.stage.rbwtech.net", "GTM-5WG5379"],
	// ["game-portal-battle.beta.rbwtech.net", "G-C0HCWGWW5N"],
	// ["stage.lastrush.games", "G-R3QXSS3RKE"],
	// ["beta.lastrush.games", "G-HVEDKQRNDV"],
	// ["lastrush.games", "G-1RE26XLXRK"],
	// ["battlearena.games", "GTM-5WG5379"],
	[
		"battlearena.games",
		{
			tag: "GTM-T69WBBJT"
		}
	],
	[
		"game-portal-battle.stage.rbwtech.net",
		{
			tag: "GTM-K35P5L83"
		}
	],
	[
		"game-portal-battle.beta.rbwtech.net",
		{
			tag: "GTM-WK6WSM45",
			env: {
				auth: "9cyMHjzrX5mM3dWOCmoUGg",
				preview: "env-2"
			}
		}
	],
	[
		"globalcity-game.com",
		{
			tag: "GTM-WV9DGNN3"
		}
	],
	[
		"dev.globalcity-game.com",
		{
			tag: "GTM-WV9DGNN3"
		}
	],
	[
		"beta.globalcity-game.com",
		{
			tag: "GTM-WV9DGNN3"
		}
	],
	[
		"empirecity.games",
		{
			tag: "GTM-5CCH5RDX"
		}
	],
	[
		"steamcity-game.com",
		{
			tag: "GTM-P79RXXT4"
		}
	],
	[
		"dev.steamcity-game.com",
		{
			tag: "GTM-P79RXXT4"
		}
	],
	[
		"beta.steamcity-game.com",
		{
			tag: "GTM-P79RXXT4"
		}
	],
	[
		"alpha.steamcity-game.com",
		{
			tag: "GTM-P79RXXT4"
		}
	],
	[
		"web.ludus-game.ru",
		{
			tag: "GTM-M3T643D3"
		}
	],
	[
		"web.ludus-app.com",
		{
			tag: "GTM-5JNCK422"
		}
	],
	[
		"ludus-game.com",
		{
			tag: "GTM-5JNCK422"
		}
	]
]);
