/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxGamesLibWebPublicDataBaseStatisticsOptionsDevToDevOptions
 */
export interface FxGamesLibWebPublicDataBaseStatisticsOptionsDevToDevOptions {
    /**
     * 
     * @type {string}
     * @memberof FxGamesLibWebPublicDataBaseStatisticsOptionsDevToDevOptions
     */
    AppId: string;
}

/**
 * Check if a given object implements the FxGamesLibWebPublicDataBaseStatisticsOptionsDevToDevOptions interface.
 */
export function instanceOfFxGamesLibWebPublicDataBaseStatisticsOptionsDevToDevOptions(value: object): value is FxGamesLibWebPublicDataBaseStatisticsOptionsDevToDevOptions {
    if (!('AppId' in value) || value['AppId'] === undefined) return false;
    return true;
}

export function FxGamesLibWebPublicDataBaseStatisticsOptionsDevToDevOptionsFromJSON(json: any): FxGamesLibWebPublicDataBaseStatisticsOptionsDevToDevOptions {
    return FxGamesLibWebPublicDataBaseStatisticsOptionsDevToDevOptionsFromJSONTyped(json, false);
}

export function FxGamesLibWebPublicDataBaseStatisticsOptionsDevToDevOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxGamesLibWebPublicDataBaseStatisticsOptionsDevToDevOptions {
    if (json == null) {
        return json;
    }
    return {
        
        'AppId': json['AppId'],
    };
}

export function FxGamesLibWebPublicDataBaseStatisticsOptionsDevToDevOptionsToJSON(json: any): FxGamesLibWebPublicDataBaseStatisticsOptionsDevToDevOptions {
    return FxGamesLibWebPublicDataBaseStatisticsOptionsDevToDevOptionsToJSONTyped(json, false);
}

export function FxGamesLibWebPublicDataBaseStatisticsOptionsDevToDevOptionsToJSONTyped(value?: FxGamesLibWebPublicDataBaseStatisticsOptionsDevToDevOptions | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'AppId': value['AppId'],
    };
}

