import { useDebugValue } from "react";
import { useAuthToken } from "../../Api/Auth";
import { useProfileDataQuery } from "../../Api/Queries";
import { getUserStatus } from "../Functions";

export const useUserStatus = () => {
	const authToken = useAuthToken();
	const { data, isInitialLoading } = useProfileDataQuery();
	const userStatus = getUserStatus(data, isInitialLoading, authToken !== null);
	useDebugValue(userStatus);

	return userStatus;
};
