/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLocalizedStringTranslationsOptions
 */
export interface FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLocalizedStringTranslationsOptions {
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLocalizedStringTranslationsOptions
     */
    En: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLocalizedStringTranslationsOptions
     */
    Pl: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLocalizedStringTranslationsOptions
     */
    Fr: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLocalizedStringTranslationsOptions
     */
    De: string;
}

/**
 * Check if a given object implements the FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLocalizedStringTranslationsOptions interface.
 */
export function instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLocalizedStringTranslationsOptions(value: object): value is FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLocalizedStringTranslationsOptions {
    if (!('En' in value) || value['En'] === undefined) return false;
    if (!('Pl' in value) || value['Pl'] === undefined) return false;
    if (!('Fr' in value) || value['Fr'] === undefined) return false;
    if (!('De' in value) || value['De'] === undefined) return false;
    return true;
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLocalizedStringTranslationsOptionsFromJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLocalizedStringTranslationsOptions {
    return FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLocalizedStringTranslationsOptionsFromJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLocalizedStringTranslationsOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLocalizedStringTranslationsOptions {
    if (json == null) {
        return json;
    }
    return {
        
        'En': json['en'],
        'Pl': json['pl'],
        'Fr': json['fr'],
        'De': json['de'],
    };
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLocalizedStringTranslationsOptionsToJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLocalizedStringTranslationsOptions {
    return FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLocalizedStringTranslationsOptionsToJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLocalizedStringTranslationsOptionsToJSONTyped(value?: FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLocalizedStringTranslationsOptions | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'en': value['En'],
        'pl': value['Pl'],
        'fr': value['Fr'],
        'de': value['De'],
    };
}

