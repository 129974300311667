/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxGamesLibWebPublicDataBaseStoreOptions
 */
export interface FxGamesLibWebPublicDataBaseStoreOptions {
    /**
     * 
     * @type {boolean}
     * @memberof FxGamesLibWebPublicDataBaseStoreOptions
     */
    XsollaEnabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FxGamesLibWebPublicDataBaseStoreOptions
     */
    YookassaEnabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FxGamesLibWebPublicDataBaseStoreOptions
     */
    StripeEnabled: boolean;
}

/**
 * Check if a given object implements the FxGamesLibWebPublicDataBaseStoreOptions interface.
 */
export function instanceOfFxGamesLibWebPublicDataBaseStoreOptions(value: object): value is FxGamesLibWebPublicDataBaseStoreOptions {
    if (!('XsollaEnabled' in value) || value['XsollaEnabled'] === undefined) return false;
    if (!('YookassaEnabled' in value) || value['YookassaEnabled'] === undefined) return false;
    if (!('StripeEnabled' in value) || value['StripeEnabled'] === undefined) return false;
    return true;
}

export function FxGamesLibWebPublicDataBaseStoreOptionsFromJSON(json: any): FxGamesLibWebPublicDataBaseStoreOptions {
    return FxGamesLibWebPublicDataBaseStoreOptionsFromJSONTyped(json, false);
}

export function FxGamesLibWebPublicDataBaseStoreOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxGamesLibWebPublicDataBaseStoreOptions {
    if (json == null) {
        return json;
    }
    return {
        
        'XsollaEnabled': json['XsollaEnabled'],
        'YookassaEnabled': json['YookassaEnabled'],
        'StripeEnabled': json['StripeEnabled'],
    };
}

export function FxGamesLibWebPublicDataBaseStoreOptionsToJSON(json: any): FxGamesLibWebPublicDataBaseStoreOptions {
    return FxGamesLibWebPublicDataBaseStoreOptionsToJSONTyped(json, false);
}

export function FxGamesLibWebPublicDataBaseStoreOptionsToJSONTyped(value?: FxGamesLibWebPublicDataBaseStoreOptions | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'XsollaEnabled': value['XsollaEnabled'],
        'YookassaEnabled': value['YookassaEnabled'],
        'StripeEnabled': value['StripeEnabled'],
    };
}

