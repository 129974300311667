/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FxGamesLibWebRpcContractsResponseResponseError } from './FxGamesLibWebRpcContractsResponseResponseError';
import {
    FxGamesLibWebRpcContractsResponseResponseErrorFromJSON,
    FxGamesLibWebRpcContractsResponseResponseErrorFromJSONTyped,
    FxGamesLibWebRpcContractsResponseResponseErrorToJSON,
    FxGamesLibWebRpcContractsResponseResponseErrorToJSONTyped,
} from './FxGamesLibWebRpcContractsResponseResponseError';

/**
 * 
 * @export
 * @interface FxGamesLibWebRpcContractsResponse
 */
export interface FxGamesLibWebRpcContractsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof FxGamesLibWebRpcContractsResponse
     */
    Success: boolean;
    /**
     * 
     * @type {FxGamesLibWebRpcContractsResponseResponseError}
     * @memberof FxGamesLibWebRpcContractsResponse
     */
    ResponseError?: FxGamesLibWebRpcContractsResponseResponseError | null;
}

/**
 * Check if a given object implements the FxGamesLibWebRpcContractsResponse interface.
 */
export function instanceOfFxGamesLibWebRpcContractsResponse(value: object): value is FxGamesLibWebRpcContractsResponse {
    if (!('Success' in value) || value['Success'] === undefined) return false;
    return true;
}

export function FxGamesLibWebRpcContractsResponseFromJSON(json: any): FxGamesLibWebRpcContractsResponse {
    return FxGamesLibWebRpcContractsResponseFromJSONTyped(json, false);
}

export function FxGamesLibWebRpcContractsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxGamesLibWebRpcContractsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'Success': json['Success'],
        'ResponseError': json['ResponseError'] == null ? undefined : FxGamesLibWebRpcContractsResponseResponseErrorFromJSON(json['ResponseError']),
    };
}

export function FxGamesLibWebRpcContractsResponseToJSON(json: any): FxGamesLibWebRpcContractsResponse {
    return FxGamesLibWebRpcContractsResponseToJSONTyped(json, false);
}

export function FxGamesLibWebRpcContractsResponseToJSONTyped(value?: FxGamesLibWebRpcContractsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'Success': value['Success'],
        'ResponseError': FxGamesLibWebRpcContractsResponseResponseErrorToJSON(value['ResponseError']),
    };
}

