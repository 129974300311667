/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebModelsTagLinkView
 */
export interface FxIdWebModelsTagLinkView {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebModelsTagLinkView
     */
    TagName: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebModelsTagLinkView
     */
    TagData?: string | null;
}

/**
 * Check if a given object implements the FxIdWebModelsTagLinkView interface.
 */
export function instanceOfFxIdWebModelsTagLinkView(value: object): value is FxIdWebModelsTagLinkView {
    if (!('TagName' in value) || value['TagName'] === undefined) return false;
    return true;
}

export function FxIdWebModelsTagLinkViewFromJSON(json: any): FxIdWebModelsTagLinkView {
    return FxIdWebModelsTagLinkViewFromJSONTyped(json, false);
}

export function FxIdWebModelsTagLinkViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebModelsTagLinkView {
    if (json == null) {
        return json;
    }
    return {
        
        'TagName': json['TagName'],
        'TagData': json['TagData'] == null ? undefined : json['TagData'],
    };
}

export function FxIdWebModelsTagLinkViewToJSON(json: any): FxIdWebModelsTagLinkView {
    return FxIdWebModelsTagLinkViewToJSONTyped(json, false);
}

export function FxIdWebModelsTagLinkViewToJSONTyped(value?: FxIdWebModelsTagLinkView | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'TagName': value['TagName'],
        'TagData': value['TagData'],
    };
}

