/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLocalizedString } from './FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLocalizedString';
import {
    instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLocalizedString,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLocalizedStringFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLocalizedStringFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLocalizedStringToJSON,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLocalizedString';

/**
 * @type FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLeaderboardConfigDescriptionMarkdown
 * Описание лидерборда в формате Markdown
 * @export
 */
export type FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLeaderboardConfigDescriptionMarkdown = FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLocalizedString;

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLeaderboardConfigDescriptionMarkdownFromJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLeaderboardConfigDescriptionMarkdown {
    return FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLeaderboardConfigDescriptionMarkdownFromJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLeaderboardConfigDescriptionMarkdownFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLeaderboardConfigDescriptionMarkdown {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLocalizedString(json)) {
        return FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLocalizedStringFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLeaderboardConfigDescriptionMarkdownToJSON(json: any): any {
    return FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLeaderboardConfigDescriptionMarkdownToJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLeaderboardConfigDescriptionMarkdownToJSONTyped(value?: FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLeaderboardConfigDescriptionMarkdown | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLocalizedString(value)) {
        return FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLocalizedStringToJSON(value as FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLocalizedString);
    }

    return {};
}

