/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FxGamesLibWebPublicDataBaseStoreOptions } from './FxGamesLibWebPublicDataBaseStoreOptions';
import {
    FxGamesLibWebPublicDataBaseStoreOptionsFromJSON,
    FxGamesLibWebPublicDataBaseStoreOptionsFromJSONTyped,
    FxGamesLibWebPublicDataBaseStoreOptionsToJSON,
    FxGamesLibWebPublicDataBaseStoreOptionsToJSONTyped,
} from './FxGamesLibWebPublicDataBaseStoreOptions';
import type { FxIdDomainSettingsUrlOpenMethod } from './FxIdDomainSettingsUrlOpenMethod';
import {
    FxIdDomainSettingsUrlOpenMethodFromJSON,
    FxIdDomainSettingsUrlOpenMethodFromJSONTyped,
    FxIdDomainSettingsUrlOpenMethodToJSON,
    FxIdDomainSettingsUrlOpenMethodToJSONTyped,
} from './FxIdDomainSettingsUrlOpenMethod';
import type { FxGamesLibWebPlatformDataOptions } from './FxGamesLibWebPlatformDataOptions';
import {
    FxGamesLibWebPlatformDataOptionsFromJSON,
    FxGamesLibWebPlatformDataOptionsFromJSONTyped,
    FxGamesLibWebPlatformDataOptionsToJSON,
    FxGamesLibWebPlatformDataOptionsToJSONTyped,
} from './FxGamesLibWebPlatformDataOptions';
import type { FxGamesLibWebPublicDataBasePresentation } from './FxGamesLibWebPublicDataBasePresentation';
import {
    FxGamesLibWebPublicDataBasePresentationFromJSON,
    FxGamesLibWebPublicDataBasePresentationFromJSONTyped,
    FxGamesLibWebPublicDataBasePresentationToJSON,
    FxGamesLibWebPublicDataBasePresentationToJSONTyped,
} from './FxGamesLibWebPublicDataBasePresentation';
import type { FxIdWebFeaturesPlayGameResponseLaunchData } from './FxIdWebFeaturesPlayGameResponseLaunchData';
import {
    FxIdWebFeaturesPlayGameResponseLaunchDataFromJSON,
    FxIdWebFeaturesPlayGameResponseLaunchDataFromJSONTyped,
    FxIdWebFeaturesPlayGameResponseLaunchDataToJSON,
    FxIdWebFeaturesPlayGameResponseLaunchDataToJSONTyped,
} from './FxIdWebFeaturesPlayGameResponseLaunchData';
import type { FxIdDomainSettingsEGameOrientation } from './FxIdDomainSettingsEGameOrientation';
import {
    FxIdDomainSettingsEGameOrientationFromJSON,
    FxIdDomainSettingsEGameOrientationFromJSONTyped,
    FxIdDomainSettingsEGameOrientationToJSON,
    FxIdDomainSettingsEGameOrientationToJSONTyped,
} from './FxIdDomainSettingsEGameOrientation';
import type { FxIdDomainSettingsPublicWebClientConfigOptions } from './FxIdDomainSettingsPublicWebClientConfigOptions';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsToJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsToJSONTyped,
} from './FxIdDomainSettingsPublicWebClientConfigOptions';
import type { FxIdWebFeaturesPlayGameResponseIframeGame } from './FxIdWebFeaturesPlayGameResponseIframeGame';
import {
    FxIdWebFeaturesPlayGameResponseIframeGameFromJSON,
    FxIdWebFeaturesPlayGameResponseIframeGameFromJSONTyped,
    FxIdWebFeaturesPlayGameResponseIframeGameToJSON,
    FxIdWebFeaturesPlayGameResponseIframeGameToJSONTyped,
} from './FxIdWebFeaturesPlayGameResponseIframeGame';
import type { FxGamesLibWebPublicDataBaseSupport } from './FxGamesLibWebPublicDataBaseSupport';
import {
    FxGamesLibWebPublicDataBaseSupportFromJSON,
    FxGamesLibWebPublicDataBaseSupportFromJSONTyped,
    FxGamesLibWebPublicDataBaseSupportToJSON,
    FxGamesLibWebPublicDataBaseSupportToJSONTyped,
} from './FxGamesLibWebPublicDataBaseSupport';
import type { FxIdWebFeaturesPlayGameResponseUnityGameConfig } from './FxIdWebFeaturesPlayGameResponseUnityGameConfig';
import {
    FxIdWebFeaturesPlayGameResponseUnityGameConfigFromJSON,
    FxIdWebFeaturesPlayGameResponseUnityGameConfigFromJSONTyped,
    FxIdWebFeaturesPlayGameResponseUnityGameConfigToJSON,
    FxIdWebFeaturesPlayGameResponseUnityGameConfigToJSONTyped,
} from './FxIdWebFeaturesPlayGameResponseUnityGameConfig';
import type { FxIdDomainEntitiesPlayGameType } from './FxIdDomainEntitiesPlayGameType';
import {
    FxIdDomainEntitiesPlayGameTypeFromJSON,
    FxIdDomainEntitiesPlayGameTypeFromJSONTyped,
    FxIdDomainEntitiesPlayGameTypeToJSON,
    FxIdDomainEntitiesPlayGameTypeToJSONTyped,
} from './FxIdDomainEntitiesPlayGameType';
import type { FxGamesLibWebPublicDataBaseStatistics } from './FxGamesLibWebPublicDataBaseStatistics';
import {
    FxGamesLibWebPublicDataBaseStatisticsFromJSON,
    FxGamesLibWebPublicDataBaseStatisticsFromJSONTyped,
    FxGamesLibWebPublicDataBaseStatisticsToJSON,
    FxGamesLibWebPublicDataBaseStatisticsToJSONTyped,
} from './FxGamesLibWebPublicDataBaseStatistics';
import type { FxGamesLibWebPublicDataBaseFxLauncherOptions } from './FxGamesLibWebPublicDataBaseFxLauncherOptions';
import {
    FxGamesLibWebPublicDataBaseFxLauncherOptionsFromJSON,
    FxGamesLibWebPublicDataBaseFxLauncherOptionsFromJSONTyped,
    FxGamesLibWebPublicDataBaseFxLauncherOptionsToJSON,
    FxGamesLibWebPublicDataBaseFxLauncherOptionsToJSONTyped,
} from './FxGamesLibWebPublicDataBaseFxLauncherOptions';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesPlayGameResponse
 */
export interface FxIdWebFeaturesPlayGameResponse {
    /**
     * 
     * @type {FxGamesLibWebPlatformDataOptions}
     * @memberof FxIdWebFeaturesPlayGameResponse
     */
    PlatformData: FxGamesLibWebPlatformDataOptions;
    /**
     * 
     * @type {FxGamesLibWebPublicDataBaseStatistics}
     * @memberof FxIdWebFeaturesPlayGameResponse
     */
    Statistics?: FxGamesLibWebPublicDataBaseStatistics | null;
    /**
     * 
     * @type {FxIdDomainSettingsEGameOrientation}
     * @memberof FxIdWebFeaturesPlayGameResponse
     */
    GameOrientation: FxIdDomainSettingsEGameOrientation;
    /**
     * 
     * @type {FxIdDomainSettingsEGameOrientation}
     * @memberof FxIdWebFeaturesPlayGameResponse
     */
    MobileGameOrientation: FxIdDomainSettingsEGameOrientation;
    /**
     * 
     * @type {FxGamesLibWebPublicDataBasePresentation}
     * @memberof FxIdWebFeaturesPlayGameResponse
     */
    Presentation?: FxGamesLibWebPublicDataBasePresentation | null;
    /**
     * 
     * @type {FxGamesLibWebPublicDataBasePresentation}
     * @memberof FxIdWebFeaturesPlayGameResponse
     */
    MobilePresentation?: FxGamesLibWebPublicDataBasePresentation | null;
    /**
     * 
     * @type {FxGamesLibWebPublicDataBasePresentation}
     * @memberof FxIdWebFeaturesPlayGameResponse
     */
    ViewportPresentation?: FxGamesLibWebPublicDataBasePresentation | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FxIdWebFeaturesPlayGameResponse
     */
    SupportedLanguages: Array<string>;
    /**
     * 
     * @type {FxGamesLibWebPublicDataBaseSupport}
     * @memberof FxIdWebFeaturesPlayGameResponse
     */
    Support?: FxGamesLibWebPublicDataBaseSupport | null;
    /**
     * 
     * @type {FxGamesLibWebPublicDataBaseStoreOptions}
     * @memberof FxIdWebFeaturesPlayGameResponse
     */
    Store: FxGamesLibWebPublicDataBaseStoreOptions;
    /**
     * 
     * @type {FxGamesLibWebPublicDataBaseFxLauncherOptions}
     * @memberof FxIdWebFeaturesPlayGameResponse
     */
    FxLauncherOptions?: FxGamesLibWebPublicDataBaseFxLauncherOptions | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesPlayGameResponse
     */
    CompanyName: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesPlayGameResponse
     */
    TosUrl: string;
    /**
     * 
     * @type {FxIdDomainSettingsUrlOpenMethod}
     * @memberof FxIdWebFeaturesPlayGameResponse
     */
    TosUrlOpenMethod: FxIdDomainSettingsUrlOpenMethod;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesPlayGameResponse
     */
    PpUrl: string;
    /**
     * 
     * @type {FxIdDomainSettingsUrlOpenMethod}
     * @memberof FxIdWebFeaturesPlayGameResponse
     */
    PpUrlOpenMethod: FxIdDomainSettingsUrlOpenMethod;
    /**
     * 
     * @type {FxIdDomainSettingsPublicWebClientConfigOptions}
     * @memberof FxIdWebFeaturesPlayGameResponse
     */
    PublicWebClientConfig: FxIdDomainSettingsPublicWebClientConfigOptions;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesPlayGameResponse
     */
    GameSystemName: string;
    /**
     * 
     * @type {boolean}
     * @memberof FxIdWebFeaturesPlayGameResponse
     */
    NamespacedProductsL10N: boolean;
    /**
     * 
     * @type {FxIdDomainEntitiesPlayGameType}
     * @memberof FxIdWebFeaturesPlayGameResponse
     */
    PlayGameType: FxIdDomainEntitiesPlayGameType;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesPlayGameResponse
     */
    GameJwt: string;
    /**
     * 
     * @type {FxIdWebFeaturesPlayGameResponseUnityGameConfig}
     * @memberof FxIdWebFeaturesPlayGameResponse
     */
    UnityGameConfig?: FxIdWebFeaturesPlayGameResponseUnityGameConfig | null;
    /**
     * 
     * @type {FxIdWebFeaturesPlayGameResponseIframeGame}
     * @memberof FxIdWebFeaturesPlayGameResponse
     */
    IframeGame?: FxIdWebFeaturesPlayGameResponseIframeGame | null;
    /**
     * 
     * @type {FxIdWebFeaturesPlayGameResponseLaunchData}
     * @memberof FxIdWebFeaturesPlayGameResponse
     */
    LaunchData?: FxIdWebFeaturesPlayGameResponseLaunchData | null;
}



/**
 * Check if a given object implements the FxIdWebFeaturesPlayGameResponse interface.
 */
export function instanceOfFxIdWebFeaturesPlayGameResponse(value: object): value is FxIdWebFeaturesPlayGameResponse {
    if (!('PlatformData' in value) || value['PlatformData'] === undefined) return false;
    if (!('GameOrientation' in value) || value['GameOrientation'] === undefined) return false;
    if (!('MobileGameOrientation' in value) || value['MobileGameOrientation'] === undefined) return false;
    if (!('SupportedLanguages' in value) || value['SupportedLanguages'] === undefined) return false;
    if (!('Store' in value) || value['Store'] === undefined) return false;
    if (!('CompanyName' in value) || value['CompanyName'] === undefined) return false;
    if (!('TosUrl' in value) || value['TosUrl'] === undefined) return false;
    if (!('TosUrlOpenMethod' in value) || value['TosUrlOpenMethod'] === undefined) return false;
    if (!('PpUrl' in value) || value['PpUrl'] === undefined) return false;
    if (!('PpUrlOpenMethod' in value) || value['PpUrlOpenMethod'] === undefined) return false;
    if (!('PublicWebClientConfig' in value) || value['PublicWebClientConfig'] === undefined) return false;
    if (!('GameSystemName' in value) || value['GameSystemName'] === undefined) return false;
    if (!('NamespacedProductsL10N' in value) || value['NamespacedProductsL10N'] === undefined) return false;
    if (!('PlayGameType' in value) || value['PlayGameType'] === undefined) return false;
    if (!('GameJwt' in value) || value['GameJwt'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesPlayGameResponseFromJSON(json: any): FxIdWebFeaturesPlayGameResponse {
    return FxIdWebFeaturesPlayGameResponseFromJSONTyped(json, false);
}

export function FxIdWebFeaturesPlayGameResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesPlayGameResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'PlatformData': FxGamesLibWebPlatformDataOptionsFromJSON(json['PlatformData']),
        'Statistics': json['Statistics'] == null ? undefined : FxGamesLibWebPublicDataBaseStatisticsFromJSON(json['Statistics']),
        'GameOrientation': FxIdDomainSettingsEGameOrientationFromJSON(json['GameOrientation']),
        'MobileGameOrientation': FxIdDomainSettingsEGameOrientationFromJSON(json['MobileGameOrientation']),
        'Presentation': json['Presentation'] == null ? undefined : FxGamesLibWebPublicDataBasePresentationFromJSON(json['Presentation']),
        'MobilePresentation': json['MobilePresentation'] == null ? undefined : FxGamesLibWebPublicDataBasePresentationFromJSON(json['MobilePresentation']),
        'ViewportPresentation': json['ViewportPresentation'] == null ? undefined : FxGamesLibWebPublicDataBasePresentationFromJSON(json['ViewportPresentation']),
        'SupportedLanguages': json['SupportedLanguages'],
        'Support': json['Support'] == null ? undefined : FxGamesLibWebPublicDataBaseSupportFromJSON(json['Support']),
        'Store': FxGamesLibWebPublicDataBaseStoreOptionsFromJSON(json['Store']),
        'FxLauncherOptions': json['FxLauncherOptions'] == null ? undefined : FxGamesLibWebPublicDataBaseFxLauncherOptionsFromJSON(json['FxLauncherOptions']),
        'CompanyName': json['CompanyName'],
        'TosUrl': json['TosUrl'],
        'TosUrlOpenMethod': FxIdDomainSettingsUrlOpenMethodFromJSON(json['TosUrlOpenMethod']),
        'PpUrl': json['PpUrl'],
        'PpUrlOpenMethod': FxIdDomainSettingsUrlOpenMethodFromJSON(json['PpUrlOpenMethod']),
        'PublicWebClientConfig': FxIdDomainSettingsPublicWebClientConfigOptionsFromJSON(json['PublicWebClientConfig']),
        'GameSystemName': json['GameSystemName'],
        'NamespacedProductsL10N': json['NamespacedProductsL10N'],
        'PlayGameType': FxIdDomainEntitiesPlayGameTypeFromJSON(json['PlayGameType']),
        'GameJwt': json['GameJwt'],
        'UnityGameConfig': json['UnityGameConfig'] == null ? undefined : FxIdWebFeaturesPlayGameResponseUnityGameConfigFromJSON(json['UnityGameConfig']),
        'IframeGame': json['IframeGame'] == null ? undefined : FxIdWebFeaturesPlayGameResponseIframeGameFromJSON(json['IframeGame']),
        'LaunchData': json['LaunchData'] == null ? undefined : FxIdWebFeaturesPlayGameResponseLaunchDataFromJSON(json['LaunchData']),
    };
}

export function FxIdWebFeaturesPlayGameResponseToJSON(json: any): FxIdWebFeaturesPlayGameResponse {
    return FxIdWebFeaturesPlayGameResponseToJSONTyped(json, false);
}

export function FxIdWebFeaturesPlayGameResponseToJSONTyped(value?: FxIdWebFeaturesPlayGameResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'PlatformData': FxGamesLibWebPlatformDataOptionsToJSON(value['PlatformData']),
        'Statistics': FxGamesLibWebPublicDataBaseStatisticsToJSON(value['Statistics']),
        'GameOrientation': FxIdDomainSettingsEGameOrientationToJSON(value['GameOrientation']),
        'MobileGameOrientation': FxIdDomainSettingsEGameOrientationToJSON(value['MobileGameOrientation']),
        'Presentation': FxGamesLibWebPublicDataBasePresentationToJSON(value['Presentation']),
        'MobilePresentation': FxGamesLibWebPublicDataBasePresentationToJSON(value['MobilePresentation']),
        'ViewportPresentation': FxGamesLibWebPublicDataBasePresentationToJSON(value['ViewportPresentation']),
        'SupportedLanguages': value['SupportedLanguages'],
        'Support': FxGamesLibWebPublicDataBaseSupportToJSON(value['Support']),
        'Store': FxGamesLibWebPublicDataBaseStoreOptionsToJSON(value['Store']),
        'FxLauncherOptions': FxGamesLibWebPublicDataBaseFxLauncherOptionsToJSON(value['FxLauncherOptions']),
        'CompanyName': value['CompanyName'],
        'TosUrl': value['TosUrl'],
        'TosUrlOpenMethod': FxIdDomainSettingsUrlOpenMethodToJSON(value['TosUrlOpenMethod']),
        'PpUrl': value['PpUrl'],
        'PpUrlOpenMethod': FxIdDomainSettingsUrlOpenMethodToJSON(value['PpUrlOpenMethod']),
        'PublicWebClientConfig': FxIdDomainSettingsPublicWebClientConfigOptionsToJSON(value['PublicWebClientConfig']),
        'GameSystemName': value['GameSystemName'],
        'NamespacedProductsL10N': value['NamespacedProductsL10N'],
        'PlayGameType': FxIdDomainEntitiesPlayGameTypeToJSON(value['PlayGameType']),
        'GameJwt': value['GameJwt'],
        'UnityGameConfig': FxIdWebFeaturesPlayGameResponseUnityGameConfigToJSON(value['UnityGameConfig']),
        'IframeGame': FxIdWebFeaturesPlayGameResponseIframeGameToJSON(value['IframeGame']),
        'LaunchData': FxIdWebFeaturesPlayGameResponseLaunchDataToJSON(value['LaunchData']),
    };
}

