/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FxIdWebFeaturesDocumentsAcceptDocumentAcceptDocumentRequest,
  FxIdWebFeaturesDocumentsGetAcceptedDocumentsGetAcceptedDocumentsRequest,
  FxIdWebFeaturesDocumentsGetAcceptedDocumentsGetAcceptedDocumentsResponse,
  FxIdWebFeaturesDocumentsGetAllDocumentsWithVersionsGetAllDocumentsWithVersionsRequest,
  FxIdWebFeaturesDocumentsGetAllDocumentsWithVersionsGetAllDocumentsWithVersionsResponse,
  FxIdWebFeaturesGamesListResponse,
  FxIdWebFeaturesGamesListWithConfigsResponse,
} from '../models/index';
import {
    FxIdWebFeaturesDocumentsAcceptDocumentAcceptDocumentRequestFromJSON,
    FxIdWebFeaturesDocumentsAcceptDocumentAcceptDocumentRequestToJSON,
    FxIdWebFeaturesDocumentsGetAcceptedDocumentsGetAcceptedDocumentsRequestFromJSON,
    FxIdWebFeaturesDocumentsGetAcceptedDocumentsGetAcceptedDocumentsRequestToJSON,
    FxIdWebFeaturesDocumentsGetAcceptedDocumentsGetAcceptedDocumentsResponseFromJSON,
    FxIdWebFeaturesDocumentsGetAcceptedDocumentsGetAcceptedDocumentsResponseToJSON,
    FxIdWebFeaturesDocumentsGetAllDocumentsWithVersionsGetAllDocumentsWithVersionsRequestFromJSON,
    FxIdWebFeaturesDocumentsGetAllDocumentsWithVersionsGetAllDocumentsWithVersionsRequestToJSON,
    FxIdWebFeaturesDocumentsGetAllDocumentsWithVersionsGetAllDocumentsWithVersionsResponseFromJSON,
    FxIdWebFeaturesDocumentsGetAllDocumentsWithVersionsGetAllDocumentsWithVersionsResponseToJSON,
    FxIdWebFeaturesGamesListResponseFromJSON,
    FxIdWebFeaturesGamesListResponseToJSON,
    FxIdWebFeaturesGamesListWithConfigsResponseFromJSON,
    FxIdWebFeaturesGamesListWithConfigsResponseToJSON,
} from '../models/index';

export interface FxIdWebFeaturesDocumentsAcceptDocumentAcceptDocumentEndpointRequest {
    fxIdWebFeaturesDocumentsAcceptDocumentAcceptDocumentRequest: FxIdWebFeaturesDocumentsAcceptDocumentAcceptDocumentRequest;
}

export interface FxIdWebFeaturesDocumentsGetAcceptedDocumentsGetAcceptedDocumentsEndpointRequest {
    fxIdWebFeaturesDocumentsGetAcceptedDocumentsGetAcceptedDocumentsRequest: FxIdWebFeaturesDocumentsGetAcceptedDocumentsGetAcceptedDocumentsRequest;
}

export interface FxIdWebFeaturesDocumentsGetAllDocumentsWithVersionsGetAllDocumentsWithVersionsEndpointRequest {
    fxIdWebFeaturesDocumentsGetAllDocumentsWithVersionsGetAllDocumentsWithVersionsRequest: FxIdWebFeaturesDocumentsGetAllDocumentsWithVersionsGetAllDocumentsWithVersionsRequest;
}

/**
 * 
 */
export class GamesApi extends runtime.BaseAPI {

    /**
     */
    async fxIdWebFeaturesDocumentsAcceptDocumentAcceptDocumentEndpointRaw(requestParameters: FxIdWebFeaturesDocumentsAcceptDocumentAcceptDocumentEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['fxIdWebFeaturesDocumentsAcceptDocumentAcceptDocumentRequest'] == null) {
            throw new runtime.RequiredError(
                'fxIdWebFeaturesDocumentsAcceptDocumentAcceptDocumentRequest',
                'Required parameter "fxIdWebFeaturesDocumentsAcceptDocumentAcceptDocumentRequest" was null or undefined when calling fxIdWebFeaturesDocumentsAcceptDocumentAcceptDocumentEndpoint().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWTBearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/games/documents/accept`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FxIdWebFeaturesDocumentsAcceptDocumentAcceptDocumentRequestToJSON(requestParameters['fxIdWebFeaturesDocumentsAcceptDocumentAcceptDocumentRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async fxIdWebFeaturesDocumentsAcceptDocumentAcceptDocumentEndpoint(fxIdWebFeaturesDocumentsAcceptDocumentAcceptDocumentRequest: FxIdWebFeaturesDocumentsAcceptDocumentAcceptDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.fxIdWebFeaturesDocumentsAcceptDocumentAcceptDocumentEndpointRaw({ fxIdWebFeaturesDocumentsAcceptDocumentAcceptDocumentRequest: fxIdWebFeaturesDocumentsAcceptDocumentAcceptDocumentRequest }, initOverrides);
    }

    /**
     */
    async fxIdWebFeaturesDocumentsGetAcceptedDocumentsGetAcceptedDocumentsEndpointRaw(requestParameters: FxIdWebFeaturesDocumentsGetAcceptedDocumentsGetAcceptedDocumentsEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FxIdWebFeaturesDocumentsGetAcceptedDocumentsGetAcceptedDocumentsResponse>> {
        if (requestParameters['fxIdWebFeaturesDocumentsGetAcceptedDocumentsGetAcceptedDocumentsRequest'] == null) {
            throw new runtime.RequiredError(
                'fxIdWebFeaturesDocumentsGetAcceptedDocumentsGetAcceptedDocumentsRequest',
                'Required parameter "fxIdWebFeaturesDocumentsGetAcceptedDocumentsGetAcceptedDocumentsRequest" was null or undefined when calling fxIdWebFeaturesDocumentsGetAcceptedDocumentsGetAcceptedDocumentsEndpoint().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWTBearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/games/documents/accepted`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FxIdWebFeaturesDocumentsGetAcceptedDocumentsGetAcceptedDocumentsRequestToJSON(requestParameters['fxIdWebFeaturesDocumentsGetAcceptedDocumentsGetAcceptedDocumentsRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FxIdWebFeaturesDocumentsGetAcceptedDocumentsGetAcceptedDocumentsResponseFromJSON(jsonValue));
    }

    /**
     */
    async fxIdWebFeaturesDocumentsGetAcceptedDocumentsGetAcceptedDocumentsEndpoint(fxIdWebFeaturesDocumentsGetAcceptedDocumentsGetAcceptedDocumentsRequest: FxIdWebFeaturesDocumentsGetAcceptedDocumentsGetAcceptedDocumentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FxIdWebFeaturesDocumentsGetAcceptedDocumentsGetAcceptedDocumentsResponse> {
        const response = await this.fxIdWebFeaturesDocumentsGetAcceptedDocumentsGetAcceptedDocumentsEndpointRaw({ fxIdWebFeaturesDocumentsGetAcceptedDocumentsGetAcceptedDocumentsRequest: fxIdWebFeaturesDocumentsGetAcceptedDocumentsGetAcceptedDocumentsRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async fxIdWebFeaturesDocumentsGetAllDocumentsWithVersionsGetAllDocumentsWithVersionsEndpointRaw(requestParameters: FxIdWebFeaturesDocumentsGetAllDocumentsWithVersionsGetAllDocumentsWithVersionsEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FxIdWebFeaturesDocumentsGetAllDocumentsWithVersionsGetAllDocumentsWithVersionsResponse>> {
        if (requestParameters['fxIdWebFeaturesDocumentsGetAllDocumentsWithVersionsGetAllDocumentsWithVersionsRequest'] == null) {
            throw new runtime.RequiredError(
                'fxIdWebFeaturesDocumentsGetAllDocumentsWithVersionsGetAllDocumentsWithVersionsRequest',
                'Required parameter "fxIdWebFeaturesDocumentsGetAllDocumentsWithVersionsGetAllDocumentsWithVersionsRequest" was null or undefined when calling fxIdWebFeaturesDocumentsGetAllDocumentsWithVersionsGetAllDocumentsWithVersionsEndpoint().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/games/documents/list-with-versions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FxIdWebFeaturesDocumentsGetAllDocumentsWithVersionsGetAllDocumentsWithVersionsRequestToJSON(requestParameters['fxIdWebFeaturesDocumentsGetAllDocumentsWithVersionsGetAllDocumentsWithVersionsRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FxIdWebFeaturesDocumentsGetAllDocumentsWithVersionsGetAllDocumentsWithVersionsResponseFromJSON(jsonValue));
    }

    /**
     */
    async fxIdWebFeaturesDocumentsGetAllDocumentsWithVersionsGetAllDocumentsWithVersionsEndpoint(fxIdWebFeaturesDocumentsGetAllDocumentsWithVersionsGetAllDocumentsWithVersionsRequest: FxIdWebFeaturesDocumentsGetAllDocumentsWithVersionsGetAllDocumentsWithVersionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FxIdWebFeaturesDocumentsGetAllDocumentsWithVersionsGetAllDocumentsWithVersionsResponse> {
        const response = await this.fxIdWebFeaturesDocumentsGetAllDocumentsWithVersionsGetAllDocumentsWithVersionsEndpointRaw({ fxIdWebFeaturesDocumentsGetAllDocumentsWithVersionsGetAllDocumentsWithVersionsRequest: fxIdWebFeaturesDocumentsGetAllDocumentsWithVersionsGetAllDocumentsWithVersionsRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async fxIdWebFeaturesGamesListEndpointRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FxIdWebFeaturesGamesListResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/games/list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FxIdWebFeaturesGamesListResponseFromJSON(jsonValue));
    }

    /**
     */
    async fxIdWebFeaturesGamesListEndpoint(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FxIdWebFeaturesGamesListResponse> {
        const response = await this.fxIdWebFeaturesGamesListEndpointRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async fxIdWebFeaturesGamesListWithConfigsEndpointRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FxIdWebFeaturesGamesListWithConfigsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/games/list_with_configs`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FxIdWebFeaturesGamesListWithConfigsResponseFromJSON(jsonValue));
    }

    /**
     */
    async fxIdWebFeaturesGamesListWithConfigsEndpoint(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FxIdWebFeaturesGamesListWithConfigsResponse> {
        const response = await this.fxIdWebFeaturesGamesListWithConfigsEndpointRaw(initOverrides);
        return await response.value();
    }

}
