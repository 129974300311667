import { EmbeddedCheckoutProvider, EmbeddedCheckout } from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import { closeModal, ContextModalProps, openContextModal } from "@mantine/modals";
import React from "react";
import { useLocation } from "react-router-dom";

// ВНИМАНИЕ: мы не хотим грузить страйп пока не надо.
// https://github.com/stripe/stripe-js/issues/43
// Возможно если импортнуть только типы то будет все ок - но проверьте не появится ли загрузка страйпа на старте страницы
// import { Stripe, StripeError } from "@stripe/stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";

type ModalInnerProps = {
	paymentSecret: string;
	publishableKey: string;
	returnUrl: string;
	email?: string | null | undefined;
	onCompleted: () => void;
	onError: (message: string) => void;
};

export const StripeCheckoutForm = (props: ContextModalProps<ModalInnerProps>) => {
	const [stripePromise, setStripePromise] = useState<Promise<any | null>>(Promise.resolve(null));

	useEffect(() => {
		log.info("Loading stripe");
		setStripePromise(loadStripe(props.innerProps.publishableKey));
	}, []);

	const [clientSecret, setClientSecret] = useState("");

	useEffect(() => {
		setClientSecret(props.innerProps.paymentSecret);
	}, []);

	const options = {
		clientSecret,
		// https://docs.stripe.com/payments/checkout/custom-success-page
		onComplete: () => {
			log.log("Payment completed");
			props.innerProps.onCompleted();
			setTimeout(() => {
				log.log("Closing modal %s after 1seconds", props.id);
				closeModal(props.id);
			}, 1000);
		}
	};
	return (
		<div id="checkout" style={{ backgroundColor: "white" }}>
			<h1>Payment</h1>
			<EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
				<EmbeddedCheckout />
			</EmbeddedCheckoutProvider>
		</div>
	);
};

export const StripeCheckoutFormTest = (props: ContextModalProps<ModalInnerProps>) => {
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);

	const paymentSecret = searchParams.get("paymentSecret");
	const publishableKey = searchParams.get("publishableKey");

	if (!paymentSecret || !publishableKey) {
		return <div>Error: Missing payment secret or publishable key</div>;
	}

	return (
		<StripeCheckoutForm
			{...props}
			innerProps={{
				...props.innerProps,
				paymentSecret,
				publishableKey,
				returnUrl: window.location.origin + "/payment-result", // Adjust this as needed
				onCompleted: () => {
					log.log("Payment completed");
					// Add any additional logic for completion
				},
				onError: (message: string) => {
					log.error("Payment error:", message);
					// Add any additional error handling
				}
			}}
		/>
	);
};

export const openStripeCheckoutModal = (params: ModalInnerProps & { onClose?: () => void }) => {
	const { onClose, ...innerProps } = params;

	openContextModal({
		modal: "stripeCheckoutModal",
		onClose,
		innerProps: innerProps
		// title: String(getI18n().t("stripe_checkout_modal.title"))
	});
};
