/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdDomainEntitiesDocumentsDocumentAccountAccept
 */
export interface FxIdDomainEntitiesDocumentsDocumentAccountAccept {
    /**
     * 
     * @type {number}
     * @memberof FxIdDomainEntitiesDocumentsDocumentAccountAccept
     */
    Id: number;
    /**
     * 
     * @type {number}
     * @memberof FxIdDomainEntitiesDocumentsDocumentAccountAccept
     */
    AccountId: number;
    /**
     * 
     * @type {number}
     * @memberof FxIdDomainEntitiesDocumentsDocumentAccountAccept
     */
    DocumentVersionId: number;
    /**
     * 
     * @type {Date}
     * @memberof FxIdDomainEntitiesDocumentsDocumentAccountAccept
     */
    AcceptedAt: Date;
}

/**
 * Check if a given object implements the FxIdDomainEntitiesDocumentsDocumentAccountAccept interface.
 */
export function instanceOfFxIdDomainEntitiesDocumentsDocumentAccountAccept(value: object): value is FxIdDomainEntitiesDocumentsDocumentAccountAccept {
    if (!('Id' in value) || value['Id'] === undefined) return false;
    if (!('AccountId' in value) || value['AccountId'] === undefined) return false;
    if (!('DocumentVersionId' in value) || value['DocumentVersionId'] === undefined) return false;
    if (!('AcceptedAt' in value) || value['AcceptedAt'] === undefined) return false;
    return true;
}

export function FxIdDomainEntitiesDocumentsDocumentAccountAcceptFromJSON(json: any): FxIdDomainEntitiesDocumentsDocumentAccountAccept {
    return FxIdDomainEntitiesDocumentsDocumentAccountAcceptFromJSONTyped(json, false);
}

export function FxIdDomainEntitiesDocumentsDocumentAccountAcceptFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainEntitiesDocumentsDocumentAccountAccept {
    if (json == null) {
        return json;
    }
    return {
        
        'Id': json['Id'],
        'AccountId': json['AccountId'],
        'DocumentVersionId': json['DocumentVersionId'],
        'AcceptedAt': (new Date(json['AcceptedAt'])),
    };
}

export function FxIdDomainEntitiesDocumentsDocumentAccountAcceptToJSON(json: any): FxIdDomainEntitiesDocumentsDocumentAccountAccept {
    return FxIdDomainEntitiesDocumentsDocumentAccountAcceptToJSONTyped(json, false);
}

export function FxIdDomainEntitiesDocumentsDocumentAccountAcceptToJSONTyped(value?: FxIdDomainEntitiesDocumentsDocumentAccountAccept | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'Id': value['Id'],
        'AccountId': value['AccountId'],
        'DocumentVersionId': value['DocumentVersionId'],
        'AcceptedAt': ((value['AcceptedAt']).toISOString()),
    };
}

