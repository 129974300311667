import {
	ActionIcon,
	Anchor,
	AppShell,
	Button,
	Container,
	Drawer,
	Footer,
	Group,
	Header,
	Space,
	Stack,
	Text,
	useMantineTheme,
	Image
} from "@mantine/core";
import React, { useState } from "react";
import { Link, Outlet } from "react-router-dom";

import styles from "./index.module.scss";

import { useMediaQuery, useViewportSize } from "@mantine/hooks";
import { Trans, useTranslation } from "react-i18next";
import { logout } from "../../Api/Auth";
import { useProfileDataQuery } from "../../Api/Queries";
import UserProfileSettingsButton from "../../Components/UserProfileSettingsButton";
import { MenuDots } from "../../Icons";
import { LanguageSelector } from "../../Components/LanguageSelector";
import { useHeaderLoginState } from "../../Stores/AppStore";
import { useUserStatus } from "../../Utils/Hooks/useUserStatus";
import { openLoginWithFirebaseModal } from "../../Components/Modals";
import { GlobalMessage } from "../../Components/GlobalMessages";
import { useGlobalMessagesQueue } from "../../Stores";
import { USER_STATUSES_WITH_LOGIN_ENABLED } from "../../Utils/Functions";
import { HeaderElement } from "../../Config";
import {
	FxIdWebFeaturesGamesChooseGamePortalConfig,
	FxIdWebFeaturesGamesChooseGameResponsePublicGameData
} from "../../Api/gen";

export interface INewLayoutProps {
	portalConfig: FxIdWebFeaturesGamesChooseGamePortalConfig;
	gameConfig?: FxIdWebFeaturesGamesChooseGameResponsePublicGameData | null;
}

export const NewLayout = (props: INewLayoutProps) => {
	return (
		<AppShell
			classNames={{ root: styles["app-shell-root"], body: styles["app-shell-body"] }}
			fixed={false}
			header={<NewHeader {...props} />}
			footer={<NewFooter {...props} />}
		>
			<Outlet />
		</AppShell>
	);
};

export const NewSmallLayout = (props: INewLayoutProps) => {
	return (
		<AppShell
			classNames={{ root: styles["app-small-shell-root"], body: styles["app-small-shell-body"] }}
			fixed={false}
			header={<NewSmallHeader {...props} />}
			footer={<NewFooter {...props} />}
		>
			<Outlet />
		</AppShell>
	);
};

export const NewSmallHeader = (props: INewLayoutProps) => {
	return <></>;
};

const AppBar = (props: INewLayoutProps) => {
	const { t } = useTranslation();
	const largeScreen = useMediaQuery("(min-width: 576px)");
	const { data } = useProfileDataQuery();
	// useAsyncEffect(() => {
	// 	//вызываю событие открытия портала в надежде, что профиль игрока уже пришел
	// 	void Analytics.site.openFxIdPortal();
	// }, []);

	const {
		Logos: { BrandLogo }
	} = props.portalConfig.PortalPublicWebConfig.CompanyBranding;

	const [drawerOpen, setDrawerOpen] = useState(false);
	return (
		<Container className={styles.appbar} size="lg">
			{!largeScreen && data && (
				<ActionIcon
					size="xl"
					radius="xl"
					className={styles["drawer-button"]}
					onClick={() => setDrawerOpen(true)}
				>
					<MenuDots />
				</ActionIcon>
			)}
			<Link to="/" title={t("back_home")}>
				<Image src={BrandLogo} fit="contain" />
			</Link>
			{data && largeScreen && <UserProfileSettingsButton profileData={data} />}
			<AppDrawer opened={drawerOpen && !largeScreen} onClose={() => setDrawerOpen(false)} />
		</Container>
	);
};

const AppDrawer = (props: { opened: boolean; onClose: () => void }) => {
	const { t } = useTranslation();
	const { opened, onClose } = props;

	const { data } = useProfileDataQuery();

	const handleLogoutButtonClick = async () => {
		await logout();
		window.location.reload();
	};

	return (
		<Drawer
			opened={opened}
			onClose={onClose}
			size="sm"
			overlayOpacity={0.5}
			overlayBlur={2}
			shadow="xl"
			padding="md"
		>
			{data && (
				<>
					<Text color="dimmed" px={9}>
						{<Trans i18nKey="user_settings_menu.email" values={{ email: data.Email }} />}
					</Text>
					<Button
						styles={{ inner: { justifyContent: "flex-start" } }}
						fullWidth
						color="gray"
						variant="subtle"
						compact
						component={Link}
						to="/profile"
						onClick={onClose}
					>
						{t("button_to_profile")}
					</Button>
					<Button
						styles={{ inner: { justifyContent: "flex-start" } }}
						fullWidth
						color="gray"
						variant="subtle"
						compact
						onClick={() => {
							void handleLogoutButtonClick();
							onClose();
						}}
					>
						{t("user_settings_menu.logout")}
					</Button>
				</>
			)}
		</Drawer>
	);
};

const NewHeader = (props: INewLayoutProps) => {
	const { t } = useTranslation();
	const { width } = useViewportSize();
	const largeScreen = useMediaQuery("(min-width: 576px)");

	const { refetch: refetchProfile } = useProfileDataQuery();
	const userStatus = useUserStatus();
	const [headerLoginEnabled] = useHeaderLoginState();
	const { HeaderElementType } = props.portalConfig.PortalPublicWebConfig.CompanyBranding;
	const headerElement = HeaderElement(HeaderElementType);

	const buttonSize = largeScreen ? "xl" : "md";
	const showLoginButton = headerLoginEnabled && USER_STATUSES_WITH_LOGIN_ENABLED.includes(userStatus);
	return (
		<>
			<GlobalMessagesController portalConfig={props.portalConfig} />
			<GlobalMessage />
			<Header
				height={Math.min(Math.max(0.4 * width, 300), 568)}
				sx={{ position: "relative" }}
				px={{ base: 0, xs: "xl" }}
			>
				{headerElement}
				<AppBar {...props} />
				{showLoginButton && (
					<Button
						className={styles["profile-button"]}
						size={buttonSize}
						onClick={() =>
							openLoginWithFirebaseModal({
								onAuthSuccessful: () => {
									void refetchProfile();
								},
								authProviders: { allowed: ["google", "apple", "facebook"] },
								portalConfig: props.portalConfig,
								adapter: "fxid"
							})
						}
					>
						{t("button_sign_in")}
					</Button>
				)}
			</Header>
		</>
	);
};

const NewFooter = (props: INewLayoutProps) => {
	const { t } = useTranslation();
	const theme = useMantineTheme();
	const matches = useMediaQuery("(min-width: 992px)");

	const {
		Logos: { CompanyLogo }
	} = props.portalConfig.PortalPublicWebConfig.CompanyBranding;

	const game = props.gameConfig?.GameSystemName ?? "gc";

	const StackOrGroup = matches ? Group : Stack;
	return (
		<Footer height="fit-content" withBorder={false} className={styles.footer}>
			<Container py="lg">
				<Stack justify="center" align="center" spacing={0}>
					<Image src={CompanyLogo} width={228} height={60} fit="contain" />
					<Space h="sm" />
					<LanguageSelector />
					<Space h="md" />
					<StackOrGroup spacing={matches ? "xl" : "xs"} justify="center" align="center">
						<Anchor color="dimmed" href={`/Docs/${game}/fxid/privacy-policy`} target="_blank">
							{t("pp")}
						</Anchor>
						<Anchor color="dimmed" href={`/Docs/${game}/fxid/terms-of-service`} target="_blank">
							{t("tos")}
						</Anchor>
						<Anchor color="dimmed" href={`/Docs/${game}/fxid/contacts`} target="_blank">
							{t("legal_contacts")}
						</Anchor>
					</StackOrGroup>
				</Stack>
				<Text align="center" size="sm" color={theme.colors.gray[7]}>
					{t("copyright", { now: new Date().getFullYear() })}
				</Text>
			</Container>
		</Footer>
	);
};

const GlobalMessagesController = (props: { portalConfig: FxIdWebFeaturesGamesChooseGamePortalConfig }) => {
	const [, { enqueue, dequeue }] = useGlobalMessagesQueue();
	useProfileDataQuery({
		onSuccess: (data) => {
			dequeue("no-email");
			dequeue("validate-email");
			if (!data.Email && data.HasCompletedOrder) {
				enqueue({
					id: "no-email",
					color: "red",
					priority: 1,
					meta: { portalConfig: props.portalConfig }
				});
			}
			if (data.Email && !data.EmailValidated) {
				enqueue({
					id: "validate-email",
					color: data.HasCompletedOrder ? "red" : "yellow",
					priority: 1,
					meta: { portalConfig: props.portalConfig }
				});
			}
		}
	});
	return null;
};
