/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLaunchBinary
 */
export interface FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLaunchBinary {
    /**
     * бинарник, пусть относительно корня игры
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLaunchBinary
     */
    Binary: string;
    /**
     * параметрыв запуска, если null - возмомем от основного
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLaunchBinary
     */
    Params: string;
    /**
     * название бинаря  для лончера
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLaunchBinary
     */
    Name: string;
}

/**
 * Check if a given object implements the FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLaunchBinary interface.
 */
export function instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLaunchBinary(value: object): value is FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLaunchBinary {
    if (!('Binary' in value) || value['Binary'] === undefined) return false;
    if (!('Params' in value) || value['Params'] === undefined) return false;
    if (!('Name' in value) || value['Name'] === undefined) return false;
    return true;
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLaunchBinaryFromJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLaunchBinary {
    return FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLaunchBinaryFromJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLaunchBinaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLaunchBinary {
    if (json == null) {
        return json;
    }
    return {
        
        'Binary': json['Binary'],
        'Params': json['Params'],
        'Name': json['Name'],
    };
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLaunchBinaryToJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLaunchBinary {
    return FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLaunchBinaryToJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLaunchBinaryToJSONTyped(value?: FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLaunchBinary | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'Binary': value['Binary'],
        'Params': value['Params'],
        'Name': value['Name'],
    };
}

