/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxGamesLibWebPlatformDataOptionsIosOptions } from './FxGamesLibWebPlatformDataOptionsIosOptions';
import {
    instanceOfFxGamesLibWebPlatformDataOptionsIosOptions,
    FxGamesLibWebPlatformDataOptionsIosOptionsFromJSON,
    FxGamesLibWebPlatformDataOptionsIosOptionsFromJSONTyped,
    FxGamesLibWebPlatformDataOptionsIosOptionsToJSON,
} from './FxGamesLibWebPlatformDataOptionsIosOptions';

/**
 * @type FxGamesLibWebPlatformDataOptionsIos
 * 
 * @export
 */
export type FxGamesLibWebPlatformDataOptionsIos = FxGamesLibWebPlatformDataOptionsIosOptions;

export function FxGamesLibWebPlatformDataOptionsIosFromJSON(json: any): FxGamesLibWebPlatformDataOptionsIos {
    return FxGamesLibWebPlatformDataOptionsIosFromJSONTyped(json, false);
}

export function FxGamesLibWebPlatformDataOptionsIosFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxGamesLibWebPlatformDataOptionsIos {
    if (json == null) {
        return json;
    }
    if (instanceOfFxGamesLibWebPlatformDataOptionsIosOptions(json)) {
        return FxGamesLibWebPlatformDataOptionsIosOptionsFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxGamesLibWebPlatformDataOptionsIosToJSON(json: any): any {
    return FxGamesLibWebPlatformDataOptionsIosToJSONTyped(json, false);
}

export function FxGamesLibWebPlatformDataOptionsIosToJSONTyped(value?: FxGamesLibWebPlatformDataOptionsIos | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxGamesLibWebPlatformDataOptionsIosOptions(value)) {
        return FxGamesLibWebPlatformDataOptionsIosOptionsToJSON(value as FxGamesLibWebPlatformDataOptionsIosOptions);
    }

    return {};
}

