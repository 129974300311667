/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FxGamesLibWebRpcContractsResponseResponseError } from './FxGamesLibWebRpcContractsResponseResponseError';
import {
    FxGamesLibWebRpcContractsResponseResponseErrorFromJSON,
    FxGamesLibWebRpcContractsResponseResponseErrorFromJSONTyped,
    FxGamesLibWebRpcContractsResponseResponseErrorToJSON,
    FxGamesLibWebRpcContractsResponseResponseErrorToJSONTyped,
} from './FxGamesLibWebRpcContractsResponseResponseError';
import type { FxIdApplicationStoreYandexGamesRedeemYandexBonusResult } from './FxIdApplicationStoreYandexGamesRedeemYandexBonusResult';
import {
    FxIdApplicationStoreYandexGamesRedeemYandexBonusResultFromJSON,
    FxIdApplicationStoreYandexGamesRedeemYandexBonusResultFromJSONTyped,
    FxIdApplicationStoreYandexGamesRedeemYandexBonusResultToJSON,
    FxIdApplicationStoreYandexGamesRedeemYandexBonusResultToJSONTyped,
} from './FxIdApplicationStoreYandexGamesRedeemYandexBonusResult';

/**
 * 
 * @export
 * @interface FxGamesLibWebRpcContractsFxGamesResponseOfRedeemYandexBonusResult
 */
export interface FxGamesLibWebRpcContractsFxGamesResponseOfRedeemYandexBonusResult {
    /**
     * 
     * @type {boolean}
     * @memberof FxGamesLibWebRpcContractsFxGamesResponseOfRedeemYandexBonusResult
     */
    Success: boolean;
    /**
     * 
     * @type {FxIdApplicationStoreYandexGamesRedeemYandexBonusResult}
     * @memberof FxGamesLibWebRpcContractsFxGamesResponseOfRedeemYandexBonusResult
     */
    Data: FxIdApplicationStoreYandexGamesRedeemYandexBonusResult;
    /**
     * 
     * @type {FxGamesLibWebRpcContractsResponseResponseError}
     * @memberof FxGamesLibWebRpcContractsFxGamesResponseOfRedeemYandexBonusResult
     */
    Error?: FxGamesLibWebRpcContractsResponseResponseError | null;
}

/**
 * Check if a given object implements the FxGamesLibWebRpcContractsFxGamesResponseOfRedeemYandexBonusResult interface.
 */
export function instanceOfFxGamesLibWebRpcContractsFxGamesResponseOfRedeemYandexBonusResult(value: object): value is FxGamesLibWebRpcContractsFxGamesResponseOfRedeemYandexBonusResult {
    if (!('Success' in value) || value['Success'] === undefined) return false;
    if (!('Data' in value) || value['Data'] === undefined) return false;
    return true;
}

export function FxGamesLibWebRpcContractsFxGamesResponseOfRedeemYandexBonusResultFromJSON(json: any): FxGamesLibWebRpcContractsFxGamesResponseOfRedeemYandexBonusResult {
    return FxGamesLibWebRpcContractsFxGamesResponseOfRedeemYandexBonusResultFromJSONTyped(json, false);
}

export function FxGamesLibWebRpcContractsFxGamesResponseOfRedeemYandexBonusResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxGamesLibWebRpcContractsFxGamesResponseOfRedeemYandexBonusResult {
    if (json == null) {
        return json;
    }
    return {
        
        'Success': json['Success'],
        'Data': FxIdApplicationStoreYandexGamesRedeemYandexBonusResultFromJSON(json['Data']),
        'Error': json['Error'] == null ? undefined : FxGamesLibWebRpcContractsResponseResponseErrorFromJSON(json['Error']),
    };
}

export function FxGamesLibWebRpcContractsFxGamesResponseOfRedeemYandexBonusResultToJSON(json: any): FxGamesLibWebRpcContractsFxGamesResponseOfRedeemYandexBonusResult {
    return FxGamesLibWebRpcContractsFxGamesResponseOfRedeemYandexBonusResultToJSONTyped(json, false);
}

export function FxGamesLibWebRpcContractsFxGamesResponseOfRedeemYandexBonusResultToJSONTyped(value?: FxGamesLibWebRpcContractsFxGamesResponseOfRedeemYandexBonusResult | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'Success': value['Success'],
        'Data': FxIdApplicationStoreYandexGamesRedeemYandexBonusResultToJSON(value['Data']),
        'Error': FxGamesLibWebRpcContractsResponseResponseErrorToJSON(value['Error']),
    };
}

