/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxGamesLibWebPlatformDataOptionsIosOptions
 */
export interface FxGamesLibWebPlatformDataOptionsIosOptions {
    /**
     * 
     * @type {string}
     * @memberof FxGamesLibWebPlatformDataOptionsIosOptions
     */
    AppStoreUrl: string;
    /**
     * 
     * @type {string}
     * @memberof FxGamesLibWebPlatformDataOptionsIosOptions
     */
    AppStoreId: string;
    /**
     * 
     * @type {string}
     * @memberof FxGamesLibWebPlatformDataOptionsIosOptions
     */
    AppLinkUrl: string;
    /**
     * 
     * @type {string}
     * @memberof FxGamesLibWebPlatformDataOptionsIosOptions
     */
    BundleId: string;
}

/**
 * Check if a given object implements the FxGamesLibWebPlatformDataOptionsIosOptions interface.
 */
export function instanceOfFxGamesLibWebPlatformDataOptionsIosOptions(value: object): value is FxGamesLibWebPlatformDataOptionsIosOptions {
    if (!('AppStoreUrl' in value) || value['AppStoreUrl'] === undefined) return false;
    if (!('AppStoreId' in value) || value['AppStoreId'] === undefined) return false;
    if (!('AppLinkUrl' in value) || value['AppLinkUrl'] === undefined) return false;
    if (!('BundleId' in value) || value['BundleId'] === undefined) return false;
    return true;
}

export function FxGamesLibWebPlatformDataOptionsIosOptionsFromJSON(json: any): FxGamesLibWebPlatformDataOptionsIosOptions {
    return FxGamesLibWebPlatformDataOptionsIosOptionsFromJSONTyped(json, false);
}

export function FxGamesLibWebPlatformDataOptionsIosOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxGamesLibWebPlatformDataOptionsIosOptions {
    if (json == null) {
        return json;
    }
    return {
        
        'AppStoreUrl': json['AppStoreUrl'],
        'AppStoreId': json['AppStoreId'],
        'AppLinkUrl': json['AppLinkUrl'],
        'BundleId': json['BundleId'],
    };
}

export function FxGamesLibWebPlatformDataOptionsIosOptionsToJSON(json: any): FxGamesLibWebPlatformDataOptionsIosOptions {
    return FxGamesLibWebPlatformDataOptionsIosOptionsToJSONTyped(json, false);
}

export function FxGamesLibWebPlatformDataOptionsIosOptionsToJSONTyped(value?: FxGamesLibWebPlatformDataOptionsIosOptions | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'AppStoreUrl': value['AppStoreUrl'],
        'AppStoreId': value['AppStoreId'],
        'AppLinkUrl': value['AppLinkUrl'],
        'BundleId': value['BundleId'],
    };
}

