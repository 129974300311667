/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdApplicationStoreYandexGamesYandexBonusSuccessResult
 */
export interface FxIdApplicationStoreYandexGamesYandexBonusSuccessResult {
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationStoreYandexGamesYandexBonusSuccessResult
     */
    ConsumeToken: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationStoreYandexGamesYandexBonusSuccessResult
     */
    UniqueId: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationStoreYandexGamesYandexBonusSuccessResult
     */
    Sku: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationStoreYandexGamesYandexBonusSuccessResult
     */
    PublicOrderId: string;
}

/**
 * Check if a given object implements the FxIdApplicationStoreYandexGamesYandexBonusSuccessResult interface.
 */
export function instanceOfFxIdApplicationStoreYandexGamesYandexBonusSuccessResult(value: object): value is FxIdApplicationStoreYandexGamesYandexBonusSuccessResult {
    if (!('ConsumeToken' in value) || value['ConsumeToken'] === undefined) return false;
    if (!('UniqueId' in value) || value['UniqueId'] === undefined) return false;
    if (!('Sku' in value) || value['Sku'] === undefined) return false;
    if (!('PublicOrderId' in value) || value['PublicOrderId'] === undefined) return false;
    return true;
}

export function FxIdApplicationStoreYandexGamesYandexBonusSuccessResultFromJSON(json: any): FxIdApplicationStoreYandexGamesYandexBonusSuccessResult {
    return FxIdApplicationStoreYandexGamesYandexBonusSuccessResultFromJSONTyped(json, false);
}

export function FxIdApplicationStoreYandexGamesYandexBonusSuccessResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationStoreYandexGamesYandexBonusSuccessResult {
    if (json == null) {
        return json;
    }
    return {
        
        'ConsumeToken': json['ConsumeToken'],
        'UniqueId': json['UniqueId'],
        'Sku': json['Sku'],
        'PublicOrderId': json['PublicOrderId'],
    };
}

export function FxIdApplicationStoreYandexGamesYandexBonusSuccessResultToJSON(json: any): FxIdApplicationStoreYandexGamesYandexBonusSuccessResult {
    return FxIdApplicationStoreYandexGamesYandexBonusSuccessResultToJSONTyped(json, false);
}

export function FxIdApplicationStoreYandexGamesYandexBonusSuccessResultToJSONTyped(value?: FxIdApplicationStoreYandexGamesYandexBonusSuccessResult | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'ConsumeToken': value['ConsumeToken'],
        'UniqueId': value['UniqueId'],
        'Sku': value['Sku'],
        'PublicOrderId': value['PublicOrderId'],
    };
}

