/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FastEndpointsErrorResponse,
  FxGamesLibWebPublicDataBase,
  FxIdDomainStoreEnumsSupportedWebPublishingPlatform,
} from '../models/index';
import {
    FastEndpointsErrorResponseFromJSON,
    FastEndpointsErrorResponseToJSON,
    FxGamesLibWebPublicDataBaseFromJSON,
    FxGamesLibWebPublicDataBaseToJSON,
    FxIdDomainStoreEnumsSupportedWebPublishingPlatformFromJSON,
    FxIdDomainStoreEnumsSupportedWebPublishingPlatformToJSON,
} from '../models/index';

export interface FxIdWebFeaturesPlayGamePublicConfigEndpointRequest {
    game: string;
    webPublishingPlatform: FxIdDomainStoreEnumsSupportedWebPublishingPlatform;
}

/**
 * 
 */
export class PublicGameConfigApi extends runtime.BaseAPI {

    /**
     */
    async fxIdWebFeaturesPlayGamePublicConfigEndpointRaw(requestParameters: FxIdWebFeaturesPlayGamePublicConfigEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FxGamesLibWebPublicDataBase>> {
        if (requestParameters['game'] == null) {
            throw new runtime.RequiredError(
                'game',
                'Required parameter "game" was null or undefined when calling fxIdWebFeaturesPlayGamePublicConfigEndpoint().'
            );
        }

        if (requestParameters['webPublishingPlatform'] == null) {
            throw new runtime.RequiredError(
                'webPublishingPlatform',
                'Required parameter "webPublishingPlatform" was null or undefined when calling fxIdWebFeaturesPlayGamePublicConfigEndpoint().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['webPublishingPlatform'] != null) {
            queryParameters['webPublishingPlatform'] = requestParameters['webPublishingPlatform'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/public_game_config/{game}`.replace(`{${"game"}}`, encodeURIComponent(String(requestParameters['game']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FxGamesLibWebPublicDataBaseFromJSON(jsonValue));
    }

    /**
     */
    async fxIdWebFeaturesPlayGamePublicConfigEndpoint(game: string, webPublishingPlatform: FxIdDomainStoreEnumsSupportedWebPublishingPlatform, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FxGamesLibWebPublicDataBase> {
        const response = await this.fxIdWebFeaturesPlayGamePublicConfigEndpointRaw({ game: game, webPublishingPlatform: webPublishingPlatform }, initOverrides);
        return await response.value();
    }

}
