/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxGamesLibWebPlatformDataOptionsPlatformDataOptionsFxId } from './FxGamesLibWebPlatformDataOptionsPlatformDataOptionsFxId';
import {
    instanceOfFxGamesLibWebPlatformDataOptionsPlatformDataOptionsFxId,
    FxGamesLibWebPlatformDataOptionsPlatformDataOptionsFxIdFromJSON,
    FxGamesLibWebPlatformDataOptionsPlatformDataOptionsFxIdFromJSONTyped,
    FxGamesLibWebPlatformDataOptionsPlatformDataOptionsFxIdToJSON,
} from './FxGamesLibWebPlatformDataOptionsPlatformDataOptionsFxId';

/**
 * @type FxGamesLibWebPlatformDataOptionsFxId
 * 
 * @export
 */
export type FxGamesLibWebPlatformDataOptionsFxId = FxGamesLibWebPlatformDataOptionsPlatformDataOptionsFxId;

export function FxGamesLibWebPlatformDataOptionsFxIdFromJSON(json: any): FxGamesLibWebPlatformDataOptionsFxId {
    return FxGamesLibWebPlatformDataOptionsFxIdFromJSONTyped(json, false);
}

export function FxGamesLibWebPlatformDataOptionsFxIdFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxGamesLibWebPlatformDataOptionsFxId {
    if (json == null) {
        return json;
    }
    if (instanceOfFxGamesLibWebPlatformDataOptionsPlatformDataOptionsFxId(json)) {
        return FxGamesLibWebPlatformDataOptionsPlatformDataOptionsFxIdFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxGamesLibWebPlatformDataOptionsFxIdToJSON(json: any): any {
    return FxGamesLibWebPlatformDataOptionsFxIdToJSONTyped(json, false);
}

export function FxGamesLibWebPlatformDataOptionsFxIdToJSONTyped(value?: FxGamesLibWebPlatformDataOptionsFxId | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxGamesLibWebPlatformDataOptionsPlatformDataOptionsFxId(value)) {
        return FxGamesLibWebPlatformDataOptionsPlatformDataOptionsFxIdToJSON(value as FxGamesLibWebPlatformDataOptionsPlatformDataOptionsFxId);
    }

    return {};
}

