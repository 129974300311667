/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxGamesLibWebPlatformDataOptionsPlatformDataOptionsVkontaktePlay } from './FxGamesLibWebPlatformDataOptionsPlatformDataOptionsVkontaktePlay';
import {
    instanceOfFxGamesLibWebPlatformDataOptionsPlatformDataOptionsVkontaktePlay,
    FxGamesLibWebPlatformDataOptionsPlatformDataOptionsVkontaktePlayFromJSON,
    FxGamesLibWebPlatformDataOptionsPlatformDataOptionsVkontaktePlayFromJSONTyped,
    FxGamesLibWebPlatformDataOptionsPlatformDataOptionsVkontaktePlayToJSON,
} from './FxGamesLibWebPlatformDataOptionsPlatformDataOptionsVkontaktePlay';

/**
 * @type FxGamesLibWebPlatformDataOptionsVkontaktePlay
 * 
 * @export
 */
export type FxGamesLibWebPlatformDataOptionsVkontaktePlay = FxGamesLibWebPlatformDataOptionsPlatformDataOptionsVkontaktePlay;

export function FxGamesLibWebPlatformDataOptionsVkontaktePlayFromJSON(json: any): FxGamesLibWebPlatformDataOptionsVkontaktePlay {
    return FxGamesLibWebPlatformDataOptionsVkontaktePlayFromJSONTyped(json, false);
}

export function FxGamesLibWebPlatformDataOptionsVkontaktePlayFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxGamesLibWebPlatformDataOptionsVkontaktePlay {
    if (json == null) {
        return json;
    }
    if (instanceOfFxGamesLibWebPlatformDataOptionsPlatformDataOptionsVkontaktePlay(json)) {
        return FxGamesLibWebPlatformDataOptionsPlatformDataOptionsVkontaktePlayFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxGamesLibWebPlatformDataOptionsVkontaktePlayToJSON(json: any): any {
    return FxGamesLibWebPlatformDataOptionsVkontaktePlayToJSONTyped(json, false);
}

export function FxGamesLibWebPlatformDataOptionsVkontaktePlayToJSONTyped(value?: FxGamesLibWebPlatformDataOptionsVkontaktePlay | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxGamesLibWebPlatformDataOptionsPlatformDataOptionsVkontaktePlay(value)) {
        return FxGamesLibWebPlatformDataOptionsPlatformDataOptionsVkontaktePlayToJSON(value as FxGamesLibWebPlatformDataOptionsPlatformDataOptionsVkontaktePlay);
    }

    return {};
}

