/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdDomainSettingsPaymentPersonalInformationFormOptions
 */
export interface FxIdDomainSettingsPaymentPersonalInformationFormOptions {
    /**
     * Если стоит в тру, то будет использована компактная версия формы (без картинки и описания)
     * @type {boolean}
     * @memberof FxIdDomainSettingsPaymentPersonalInformationFormOptions
     */
    Compact?: boolean | null;
}

/**
 * Check if a given object implements the FxIdDomainSettingsPaymentPersonalInformationFormOptions interface.
 */
export function instanceOfFxIdDomainSettingsPaymentPersonalInformationFormOptions(value: object): value is FxIdDomainSettingsPaymentPersonalInformationFormOptions {
    return true;
}

export function FxIdDomainSettingsPaymentPersonalInformationFormOptionsFromJSON(json: any): FxIdDomainSettingsPaymentPersonalInformationFormOptions {
    return FxIdDomainSettingsPaymentPersonalInformationFormOptionsFromJSONTyped(json, false);
}

export function FxIdDomainSettingsPaymentPersonalInformationFormOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsPaymentPersonalInformationFormOptions {
    if (json == null) {
        return json;
    }
    return {
        
        'Compact': json['Compact'] == null ? undefined : json['Compact'],
    };
}

export function FxIdDomainSettingsPaymentPersonalInformationFormOptionsToJSON(json: any): FxIdDomainSettingsPaymentPersonalInformationFormOptions {
    return FxIdDomainSettingsPaymentPersonalInformationFormOptionsToJSONTyped(json, false);
}

export function FxIdDomainSettingsPaymentPersonalInformationFormOptionsToJSONTyped(value?: FxIdDomainSettingsPaymentPersonalInformationFormOptions | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'Compact': value['Compact'],
    };
}

