/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxGamesLibWebPublicDataBaseSupportOptions } from './FxGamesLibWebPublicDataBaseSupportOptions';
import {
    instanceOfFxGamesLibWebPublicDataBaseSupportOptions,
    FxGamesLibWebPublicDataBaseSupportOptionsFromJSON,
    FxGamesLibWebPublicDataBaseSupportOptionsFromJSONTyped,
    FxGamesLibWebPublicDataBaseSupportOptionsToJSON,
} from './FxGamesLibWebPublicDataBaseSupportOptions';

/**
 * @type FxGamesLibWebPublicDataBaseSupport
 * 
 * @export
 */
export type FxGamesLibWebPublicDataBaseSupport = FxGamesLibWebPublicDataBaseSupportOptions;

export function FxGamesLibWebPublicDataBaseSupportFromJSON(json: any): FxGamesLibWebPublicDataBaseSupport {
    return FxGamesLibWebPublicDataBaseSupportFromJSONTyped(json, false);
}

export function FxGamesLibWebPublicDataBaseSupportFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxGamesLibWebPublicDataBaseSupport {
    if (json == null) {
        return json;
    }
    if (instanceOfFxGamesLibWebPublicDataBaseSupportOptions(json)) {
        return FxGamesLibWebPublicDataBaseSupportOptionsFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxGamesLibWebPublicDataBaseSupportToJSON(json: any): any {
    return FxGamesLibWebPublicDataBaseSupportToJSONTyped(json, false);
}

export function FxGamesLibWebPublicDataBaseSupportToJSONTyped(value?: FxGamesLibWebPublicDataBaseSupport | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxGamesLibWebPublicDataBaseSupportOptions(value)) {
        return FxGamesLibWebPublicDataBaseSupportOptionsToJSON(value as FxGamesLibWebPublicDataBaseSupportOptions);
    }

    return {};
}

