/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MassTransitCommonSupportServiceAttachmentDataEncoding } from './MassTransitCommonSupportServiceAttachmentDataEncoding';
import {
    MassTransitCommonSupportServiceAttachmentDataEncodingFromJSON,
    MassTransitCommonSupportServiceAttachmentDataEncodingFromJSONTyped,
    MassTransitCommonSupportServiceAttachmentDataEncodingToJSON,
    MassTransitCommonSupportServiceAttachmentDataEncodingToJSONTyped,
} from './MassTransitCommonSupportServiceAttachmentDataEncoding';

/**
 * 
 * @export
 * @interface MassTransitCommonSupportServiceAttachmentData
 */
export interface MassTransitCommonSupportServiceAttachmentData {
    /**
     * 
     * @type {string}
     * @memberof MassTransitCommonSupportServiceAttachmentData
     */
    FileName: string;
    /**
     * 
     * @type {string}
     * @memberof MassTransitCommonSupportServiceAttachmentData
     */
    MediaType: string;
    /**
     * 
     * @type {string}
     * @memberof MassTransitCommonSupportServiceAttachmentData
     */
    Data: string;
    /**
     * 
     * @type {MassTransitCommonSupportServiceAttachmentDataEncoding}
     * @memberof MassTransitCommonSupportServiceAttachmentData
     */
    Encoding: MassTransitCommonSupportServiceAttachmentDataEncoding;
}



/**
 * Check if a given object implements the MassTransitCommonSupportServiceAttachmentData interface.
 */
export function instanceOfMassTransitCommonSupportServiceAttachmentData(value: object): value is MassTransitCommonSupportServiceAttachmentData {
    if (!('FileName' in value) || value['FileName'] === undefined) return false;
    if (!('MediaType' in value) || value['MediaType'] === undefined) return false;
    if (!('Data' in value) || value['Data'] === undefined) return false;
    if (!('Encoding' in value) || value['Encoding'] === undefined) return false;
    return true;
}

export function MassTransitCommonSupportServiceAttachmentDataFromJSON(json: any): MassTransitCommonSupportServiceAttachmentData {
    return MassTransitCommonSupportServiceAttachmentDataFromJSONTyped(json, false);
}

export function MassTransitCommonSupportServiceAttachmentDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): MassTransitCommonSupportServiceAttachmentData {
    if (json == null) {
        return json;
    }
    return {
        
        'FileName': json['FileName'],
        'MediaType': json['MediaType'],
        'Data': json['Data'],
        'Encoding': MassTransitCommonSupportServiceAttachmentDataEncodingFromJSON(json['Encoding']),
    };
}

export function MassTransitCommonSupportServiceAttachmentDataToJSON(json: any): MassTransitCommonSupportServiceAttachmentData {
    return MassTransitCommonSupportServiceAttachmentDataToJSONTyped(json, false);
}

export function MassTransitCommonSupportServiceAttachmentDataToJSONTyped(value?: MassTransitCommonSupportServiceAttachmentData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'FileName': value['FileName'],
        'MediaType': value['MediaType'],
        'Data': value['Data'],
        'Encoding': MassTransitCommonSupportServiceAttachmentDataEncodingToJSON(value['Encoding']),
    };
}

