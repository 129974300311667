/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FxIdApplicationHandlersStoreUpdateOrderPIHandlerOrderPersonalInfo } from './FxIdApplicationHandlersStoreUpdateOrderPIHandlerOrderPersonalInfo';
import {
    FxIdApplicationHandlersStoreUpdateOrderPIHandlerOrderPersonalInfoFromJSON,
    FxIdApplicationHandlersStoreUpdateOrderPIHandlerOrderPersonalInfoFromJSONTyped,
    FxIdApplicationHandlersStoreUpdateOrderPIHandlerOrderPersonalInfoToJSON,
    FxIdApplicationHandlersStoreUpdateOrderPIHandlerOrderPersonalInfoToJSONTyped,
} from './FxIdApplicationHandlersStoreUpdateOrderPIHandlerOrderPersonalInfo';

/**
 * 
 * @export
 * @interface FxIdApplicationHandlersStoreUpdateOrderPIHandlerUpdateOrderPersonalInfoCommand
 */
export interface FxIdApplicationHandlersStoreUpdateOrderPIHandlerUpdateOrderPersonalInfoCommand {
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationHandlersStoreUpdateOrderPIHandlerUpdateOrderPersonalInfoCommand
     */
    PublicOrderId: string;
    /**
     * 
     * @type {FxIdApplicationHandlersStoreUpdateOrderPIHandlerOrderPersonalInfo}
     * @memberof FxIdApplicationHandlersStoreUpdateOrderPIHandlerUpdateOrderPersonalInfoCommand
     */
    OrderPersonalInfo: FxIdApplicationHandlersStoreUpdateOrderPIHandlerOrderPersonalInfo;
}

/**
 * Check if a given object implements the FxIdApplicationHandlersStoreUpdateOrderPIHandlerUpdateOrderPersonalInfoCommand interface.
 */
export function instanceOfFxIdApplicationHandlersStoreUpdateOrderPIHandlerUpdateOrderPersonalInfoCommand(value: object): value is FxIdApplicationHandlersStoreUpdateOrderPIHandlerUpdateOrderPersonalInfoCommand {
    if (!('PublicOrderId' in value) || value['PublicOrderId'] === undefined) return false;
    if (!('OrderPersonalInfo' in value) || value['OrderPersonalInfo'] === undefined) return false;
    return true;
}

export function FxIdApplicationHandlersStoreUpdateOrderPIHandlerUpdateOrderPersonalInfoCommandFromJSON(json: any): FxIdApplicationHandlersStoreUpdateOrderPIHandlerUpdateOrderPersonalInfoCommand {
    return FxIdApplicationHandlersStoreUpdateOrderPIHandlerUpdateOrderPersonalInfoCommandFromJSONTyped(json, false);
}

export function FxIdApplicationHandlersStoreUpdateOrderPIHandlerUpdateOrderPersonalInfoCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationHandlersStoreUpdateOrderPIHandlerUpdateOrderPersonalInfoCommand {
    if (json == null) {
        return json;
    }
    return {
        
        'PublicOrderId': json['PublicOrderId'],
        'OrderPersonalInfo': FxIdApplicationHandlersStoreUpdateOrderPIHandlerOrderPersonalInfoFromJSON(json['OrderPersonalInfo']),
    };
}

export function FxIdApplicationHandlersStoreUpdateOrderPIHandlerUpdateOrderPersonalInfoCommandToJSON(json: any): FxIdApplicationHandlersStoreUpdateOrderPIHandlerUpdateOrderPersonalInfoCommand {
    return FxIdApplicationHandlersStoreUpdateOrderPIHandlerUpdateOrderPersonalInfoCommandToJSONTyped(json, false);
}

export function FxIdApplicationHandlersStoreUpdateOrderPIHandlerUpdateOrderPersonalInfoCommandToJSONTyped(value?: FxIdApplicationHandlersStoreUpdateOrderPIHandlerUpdateOrderPersonalInfoCommand | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'PublicOrderId': value['PublicOrderId'],
        'OrderPersonalInfo': FxIdApplicationHandlersStoreUpdateOrderPIHandlerOrderPersonalInfoToJSON(value['OrderPersonalInfo']),
    };
}

