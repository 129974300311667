/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FxIdDomainStoreEnumsMerchant } from './FxIdDomainStoreEnumsMerchant';
import {
    FxIdDomainStoreEnumsMerchantFromJSON,
    FxIdDomainStoreEnumsMerchantFromJSONTyped,
    FxIdDomainStoreEnumsMerchantToJSON,
    FxIdDomainStoreEnumsMerchantToJSONTyped,
} from './FxIdDomainStoreEnumsMerchant';
import type { FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultStripe } from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultStripe';
import {
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultStripeFromJSON,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultStripeFromJSONTyped,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultStripeToJSON,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultStripeToJSONTyped,
} from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultStripe';
import type { FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultPersonalInfoRequirements } from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultPersonalInfoRequirements';
import {
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultPersonalInfoRequirementsFromJSON,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultPersonalInfoRequirementsFromJSONTyped,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultPersonalInfoRequirementsToJSON,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultPersonalInfoRequirementsToJSONTyped,
} from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultPersonalInfoRequirements';
import type { FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegram } from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegram';
import {
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegramFromJSON,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegramFromJSONTyped,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegramToJSON,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegramToJSONTyped,
} from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegram';
import type { FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQ } from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQ';
import {
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQFromJSON,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQFromJSONTyped,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQToJSON,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQToJSONTyped,
} from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQ';
import type { FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookInstantGames } from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookInstantGames';
import {
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookInstantGamesFromJSON,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookInstantGamesFromJSONTyped,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookInstantGamesToJSON,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookInstantGamesToJSONTyped,
} from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookInstantGames';
import type { FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRu } from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRu';
import {
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRuFromJSON,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRuFromJSONTyped,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRuToJSON,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRuToJSONTyped,
} from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRu';
import type { FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultXsolla } from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultXsolla';
import {
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultXsollaFromJSON,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultXsollaFromJSONTyped,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultXsollaToJSON,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultXsollaToJSONTyped,
} from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultXsolla';
import type { FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookGames } from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookGames';
import {
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookGamesFromJSON,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookGamesFromJSONTyped,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookGamesToJSON,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookGamesToJSONTyped,
} from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookGames';
import type { FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultYookassa } from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultYookassa';
import {
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultYookassaFromJSON,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultYookassaFromJSONTyped,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultYookassaToJSON,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultYookassaToJSONTyped,
} from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultYookassa';
import type { FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultVkontaktePlay } from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultVkontaktePlay';
import {
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultVkontaktePlayFromJSON,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultVkontaktePlayFromJSONTyped,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultVkontaktePlayToJSON,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultVkontaktePlayToJSONTyped,
} from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultVkontaktePlay';
import type { FxIdApplicationStoreOrderProductData } from './FxIdApplicationStoreOrderProductData';
import {
    FxIdApplicationStoreOrderProductDataFromJSON,
    FxIdApplicationStoreOrderProductDataFromJSONTyped,
    FxIdApplicationStoreOrderProductDataToJSON,
    FxIdApplicationStoreOrderProductDataToJSONTyped,
} from './FxIdApplicationStoreOrderProductData';
import type { FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultSteam } from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultSteam';
import {
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultSteamFromJSON,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultSteamFromJSONTyped,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultSteamToJSON,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultSteamToJSONTyped,
} from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultSteam';
import type { FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultYandexGames } from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultYandexGames';
import {
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultYandexGamesFromJSON,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultYandexGamesFromJSONTyped,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultYandexGamesToJSON,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultYandexGamesToJSONTyped,
} from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultYandexGames';
import type { FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultOdnoklassniki } from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultOdnoklassniki';
import {
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultOdnoklassnikiFromJSON,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultOdnoklassnikiFromJSONTyped,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultOdnoklassnikiToJSON,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultOdnoklassnikiToJSONTyped,
} from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultOdnoklassniki';
import type { FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultAbsoluteGames } from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultAbsoluteGames';
import {
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultAbsoluteGamesFromJSON,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultAbsoluteGamesFromJSONTyped,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultAbsoluteGamesToJSON,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultAbsoluteGamesToJSONTyped,
} from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultAbsoluteGames';
import type { FxIdApplicationStorePriceData } from './FxIdApplicationStorePriceData';
import {
    FxIdApplicationStorePriceDataFromJSON,
    FxIdApplicationStorePriceDataFromJSONTyped,
    FxIdApplicationStorePriceDataToJSON,
    FxIdApplicationStorePriceDataToJSONTyped,
} from './FxIdApplicationStorePriceData';

/**
 * 
 * @export
 * @interface FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult
 */
export interface FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult {
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult
     */
    ConfirmationUrl: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult
     */
    TransactionId: string;
    /**
     * 
     * @type {FxIdDomainStoreEnumsMerchant}
     * @memberof FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult
     */
    Merchant: FxIdDomainStoreEnumsMerchant;
    /**
     * 
     * @type {FxIdApplicationStoreOrderProductData}
     * @memberof FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult
     */
    OrderProduct: FxIdApplicationStoreOrderProductData;
    /**
     * 
     * @type {number}
     * @memberof FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult
     */
    OrderPrice: number;
    /**
     * 
     * @type {FxIdApplicationStorePriceData}
     * @memberof FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult
     */
    PriceData: FxIdApplicationStorePriceData;
    /**
     * 
     * @type {FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultPersonalInfoRequirements}
     * @memberof FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult
     */
    PersonalInfoRequirements?: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultPersonalInfoRequirements | null;
    /**
     * 
     * @type {FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookGames}
     * @memberof FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult
     */
    FacebookGames?: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookGames | null;
    /**
     * 
     * @type {FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRu}
     * @memberof FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult
     */
    MoneyMailRu?: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRu | null;
    /**
     * 
     * @type {FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegram}
     * @memberof FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult
     */
    Telegram?: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegram | null;
    /**
     * 
     * @type {FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultXsolla}
     * @memberof FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult
     */
    Xsolla?: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultXsolla | null;
    /**
     * 
     * @type {FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultYookassa}
     * @memberof FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult
     */
    Yookassa?: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultYookassa | null;
    /**
     * 
     * @type {FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultOdnoklassniki}
     * @memberof FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult
     */
    Odnoklassniki?: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultOdnoklassniki | null;
    /**
     * 
     * @type {FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultVkontaktePlay}
     * @memberof FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult
     */
    VkontaktePlay?: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultVkontaktePlay | null;
    /**
     * 
     * @type {FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultYandexGames}
     * @memberof FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult
     */
    YandexGames?: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultYandexGames | null;
    /**
     * 
     * @type {FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultAbsoluteGames}
     * @memberof FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult
     */
    AbsoluteGames?: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultAbsoluteGames | null;
    /**
     * 
     * @type {FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookInstantGames}
     * @memberof FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult
     */
    FacebookInstantGames?: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookInstantGames | null;
    /**
     * 
     * @type {FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultStripe}
     * @memberof FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult
     */
    Stripe?: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultStripe | null;
    /**
     * 
     * @type {FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQ}
     * @memberof FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult
     */
    WizQ?: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQ | null;
    /**
     * 
     * @type {FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultSteam}
     * @memberof FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult
     */
    Steam?: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultSteam | null;
}



/**
 * Check if a given object implements the FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult interface.
 */
export function instanceOfFxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult(value: object): value is FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult {
    if (!('ConfirmationUrl' in value) || value['ConfirmationUrl'] === undefined) return false;
    if (!('TransactionId' in value) || value['TransactionId'] === undefined) return false;
    if (!('Merchant' in value) || value['Merchant'] === undefined) return false;
    if (!('OrderProduct' in value) || value['OrderProduct'] === undefined) return false;
    if (!('OrderPrice' in value) || value['OrderPrice'] === undefined) return false;
    if (!('PriceData' in value) || value['PriceData'] === undefined) return false;
    return true;
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFromJSON(json: any): FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult {
    return FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFromJSONTyped(json, false);
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult {
    if (json == null) {
        return json;
    }
    return {
        
        'ConfirmationUrl': json['ConfirmationUrl'],
        'TransactionId': json['TransactionId'],
        'Merchant': FxIdDomainStoreEnumsMerchantFromJSON(json['Merchant']),
        'OrderProduct': FxIdApplicationStoreOrderProductDataFromJSON(json['OrderProduct']),
        'OrderPrice': json['OrderPrice'],
        'PriceData': FxIdApplicationStorePriceDataFromJSON(json['PriceData']),
        'PersonalInfoRequirements': json['PersonalInfoRequirements'] == null ? undefined : FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultPersonalInfoRequirementsFromJSON(json['PersonalInfoRequirements']),
        'FacebookGames': json['FacebookGames'] == null ? undefined : FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookGamesFromJSON(json['FacebookGames']),
        'MoneyMailRu': json['MoneyMailRu'] == null ? undefined : FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRuFromJSON(json['MoneyMailRu']),
        'Telegram': json['Telegram'] == null ? undefined : FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegramFromJSON(json['Telegram']),
        'Xsolla': json['Xsolla'] == null ? undefined : FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultXsollaFromJSON(json['Xsolla']),
        'Yookassa': json['Yookassa'] == null ? undefined : FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultYookassaFromJSON(json['Yookassa']),
        'Odnoklassniki': json['Odnoklassniki'] == null ? undefined : FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultOdnoklassnikiFromJSON(json['Odnoklassniki']),
        'VkontaktePlay': json['VkontaktePlay'] == null ? undefined : FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultVkontaktePlayFromJSON(json['VkontaktePlay']),
        'YandexGames': json['YandexGames'] == null ? undefined : FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultYandexGamesFromJSON(json['YandexGames']),
        'AbsoluteGames': json['AbsoluteGames'] == null ? undefined : FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultAbsoluteGamesFromJSON(json['AbsoluteGames']),
        'FacebookInstantGames': json['FacebookInstantGames'] == null ? undefined : FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookInstantGamesFromJSON(json['FacebookInstantGames']),
        'Stripe': json['Stripe'] == null ? undefined : FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultStripeFromJSON(json['Stripe']),
        'WizQ': json['WizQ'] == null ? undefined : FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQFromJSON(json['WizQ']),
        'Steam': json['Steam'] == null ? undefined : FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultSteamFromJSON(json['Steam']),
    };
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultToJSON(json: any): FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult {
    return FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultToJSONTyped(json, false);
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultToJSONTyped(value?: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'ConfirmationUrl': value['ConfirmationUrl'],
        'TransactionId': value['TransactionId'],
        'Merchant': FxIdDomainStoreEnumsMerchantToJSON(value['Merchant']),
        'OrderProduct': FxIdApplicationStoreOrderProductDataToJSON(value['OrderProduct']),
        'OrderPrice': value['OrderPrice'],
        'PriceData': FxIdApplicationStorePriceDataToJSON(value['PriceData']),
        'PersonalInfoRequirements': FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultPersonalInfoRequirementsToJSON(value['PersonalInfoRequirements']),
        'FacebookGames': FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookGamesToJSON(value['FacebookGames']),
        'MoneyMailRu': FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRuToJSON(value['MoneyMailRu']),
        'Telegram': FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegramToJSON(value['Telegram']),
        'Xsolla': FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultXsollaToJSON(value['Xsolla']),
        'Yookassa': FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultYookassaToJSON(value['Yookassa']),
        'Odnoklassniki': FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultOdnoklassnikiToJSON(value['Odnoklassniki']),
        'VkontaktePlay': FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultVkontaktePlayToJSON(value['VkontaktePlay']),
        'YandexGames': FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultYandexGamesToJSON(value['YandexGames']),
        'AbsoluteGames': FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultAbsoluteGamesToJSON(value['AbsoluteGames']),
        'FacebookInstantGames': FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookInstantGamesToJSON(value['FacebookInstantGames']),
        'Stripe': FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultStripeToJSON(value['Stripe']),
        'WizQ': FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQToJSON(value['WizQ']),
        'Steam': FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultSteamToJSON(value['Steam']),
    };
}

