/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxGamesLibWebFxLauncherOptions } from './FxGamesLibWebFxLauncherOptions';
import {
    instanceOfFxGamesLibWebFxLauncherOptions,
    FxGamesLibWebFxLauncherOptionsFromJSON,
    FxGamesLibWebFxLauncherOptionsFromJSONTyped,
    FxGamesLibWebFxLauncherOptionsToJSON,
} from './FxGamesLibWebFxLauncherOptions';

/**
 * @type FxGamesLibWebPublicDataBaseFxLauncherOptions
 * 
 * @export
 */
export type FxGamesLibWebPublicDataBaseFxLauncherOptions = FxGamesLibWebFxLauncherOptions;

export function FxGamesLibWebPublicDataBaseFxLauncherOptionsFromJSON(json: any): FxGamesLibWebPublicDataBaseFxLauncherOptions {
    return FxGamesLibWebPublicDataBaseFxLauncherOptionsFromJSONTyped(json, false);
}

export function FxGamesLibWebPublicDataBaseFxLauncherOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxGamesLibWebPublicDataBaseFxLauncherOptions {
    if (json == null) {
        return json;
    }
    if (instanceOfFxGamesLibWebFxLauncherOptions(json)) {
        return FxGamesLibWebFxLauncherOptionsFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxGamesLibWebPublicDataBaseFxLauncherOptionsToJSON(json: any): any {
    return FxGamesLibWebPublicDataBaseFxLauncherOptionsToJSONTyped(json, false);
}

export function FxGamesLibWebPublicDataBaseFxLauncherOptionsToJSONTyped(value?: FxGamesLibWebPublicDataBaseFxLauncherOptions | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxGamesLibWebFxLauncherOptions(value)) {
        return FxGamesLibWebFxLauncherOptionsToJSON(value as FxGamesLibWebFxLauncherOptions);
    }

    return {};
}

