/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MassTransitCommonSupportServiceAttachmentData } from './MassTransitCommonSupportServiceAttachmentData';
import {
    MassTransitCommonSupportServiceAttachmentDataFromJSON,
    MassTransitCommonSupportServiceAttachmentDataFromJSONTyped,
    MassTransitCommonSupportServiceAttachmentDataToJSON,
    MassTransitCommonSupportServiceAttachmentDataToJSONTyped,
} from './MassTransitCommonSupportServiceAttachmentData';

/**
 * 
 * @export
 * @interface MassTransitCommonSupportServiceMessageData
 */
export interface MassTransitCommonSupportServiceMessageData {
    /**
     * 
     * @type {string}
     * @memberof MassTransitCommonSupportServiceMessageData
     */
    Name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MassTransitCommonSupportServiceMessageData
     */
    DeviceUid?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MassTransitCommonSupportServiceMessageData
     */
    Body?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MassTransitCommonSupportServiceMessageData
     */
    Subject?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MassTransitCommonSupportServiceMessageData
     * @deprecated
     */
    Attachment?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MassTransitCommonSupportServiceMessageData
     */
    Email?: string | null;
    /**
     * 
     * @type {Array<MassTransitCommonSupportServiceAttachmentData>}
     * @memberof MassTransitCommonSupportServiceMessageData
     */
    AttachmentData?: Array<MassTransitCommonSupportServiceAttachmentData> | null;
}

/**
 * Check if a given object implements the MassTransitCommonSupportServiceMessageData interface.
 */
export function instanceOfMassTransitCommonSupportServiceMessageData(value: object): value is MassTransitCommonSupportServiceMessageData {
    return true;
}

export function MassTransitCommonSupportServiceMessageDataFromJSON(json: any): MassTransitCommonSupportServiceMessageData {
    return MassTransitCommonSupportServiceMessageDataFromJSONTyped(json, false);
}

export function MassTransitCommonSupportServiceMessageDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): MassTransitCommonSupportServiceMessageData {
    if (json == null) {
        return json;
    }
    return {
        
        'Name': json['Name'] == null ? undefined : json['Name'],
        'DeviceUid': json['DeviceUid'] == null ? undefined : json['DeviceUid'],
        'Body': json['Body'] == null ? undefined : json['Body'],
        'Subject': json['Subject'] == null ? undefined : json['Subject'],
        'Attachment': json['Attachment'] == null ? undefined : json['Attachment'],
        'Email': json['Email'] == null ? undefined : json['Email'],
        'AttachmentData': json['AttachmentData'] == null ? undefined : ((json['AttachmentData'] as Array<any>).map(MassTransitCommonSupportServiceAttachmentDataFromJSON)),
    };
}

export function MassTransitCommonSupportServiceMessageDataToJSON(json: any): MassTransitCommonSupportServiceMessageData {
    return MassTransitCommonSupportServiceMessageDataToJSONTyped(json, false);
}

export function MassTransitCommonSupportServiceMessageDataToJSONTyped(value?: MassTransitCommonSupportServiceMessageData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'Name': value['Name'],
        'DeviceUid': value['DeviceUid'],
        'Body': value['Body'],
        'Subject': value['Subject'],
        'Attachment': value['Attachment'],
        'Email': value['Email'],
        'AttachmentData': value['AttachmentData'] == null ? undefined : ((value['AttachmentData'] as Array<any>).map(MassTransitCommonSupportServiceAttachmentDataToJSON)),
    };
}

