/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FastEndpointsErrorResponse,
  FxIdWebFeaturesFtlIntegrationLauncherActivateKeyRequest,
  FxIdWebFeaturesFtlIntegrationLauncherActivateKeyResponse,
  FxIdWebFeaturesFtlIntegrationLauncherAvailableBranchesResponse,
  FxIdWebFeaturesFtlIntegrationLauncherDownloadDirectResponse,
  FxIdWebFeaturesFtlIntegrationLauncherDownloadRequestRequest,
  FxIdWebFeaturesFtlIntegrationLauncherDownloadRequestResponse,
  FxIdWebFeaturesFtlIntegrationLauncherLauncherManifestResponse,
} from '../models/index';
import {
    FastEndpointsErrorResponseFromJSON,
    FastEndpointsErrorResponseToJSON,
    FxIdWebFeaturesFtlIntegrationLauncherActivateKeyRequestFromJSON,
    FxIdWebFeaturesFtlIntegrationLauncherActivateKeyRequestToJSON,
    FxIdWebFeaturesFtlIntegrationLauncherActivateKeyResponseFromJSON,
    FxIdWebFeaturesFtlIntegrationLauncherActivateKeyResponseToJSON,
    FxIdWebFeaturesFtlIntegrationLauncherAvailableBranchesResponseFromJSON,
    FxIdWebFeaturesFtlIntegrationLauncherAvailableBranchesResponseToJSON,
    FxIdWebFeaturesFtlIntegrationLauncherDownloadDirectResponseFromJSON,
    FxIdWebFeaturesFtlIntegrationLauncherDownloadDirectResponseToJSON,
    FxIdWebFeaturesFtlIntegrationLauncherDownloadRequestRequestFromJSON,
    FxIdWebFeaturesFtlIntegrationLauncherDownloadRequestRequestToJSON,
    FxIdWebFeaturesFtlIntegrationLauncherDownloadRequestResponseFromJSON,
    FxIdWebFeaturesFtlIntegrationLauncherDownloadRequestResponseToJSON,
    FxIdWebFeaturesFtlIntegrationLauncherLauncherManifestResponseFromJSON,
    FxIdWebFeaturesFtlIntegrationLauncherLauncherManifestResponseToJSON,
} from '../models/index';

export interface FxIdWebFeaturesFtlIntegrationLauncherActivateKeyEndpointRequest {
    game: string;
    fxIdWebFeaturesFtlIntegrationLauncherActivateKeyRequest: FxIdWebFeaturesFtlIntegrationLauncherActivateKeyRequest;
}

export interface FxIdWebFeaturesFtlIntegrationLauncherAvailableBranchesEndpointRequest {
    game: string;
}

export interface FxIdWebFeaturesFtlIntegrationLauncherDownloadDirectEndpointRequest {
    game: string;
}

export interface FxIdWebFeaturesFtlIntegrationLauncherDownloadEndpointRequest {
    game: string;
    sessionId: string;
}

export interface FxIdWebFeaturesFtlIntegrationLauncherDownloadRequestEndpointRequest {
    game: string;
    fxIdWebFeaturesFtlIntegrationLauncherDownloadRequestRequest: FxIdWebFeaturesFtlIntegrationLauncherDownloadRequestRequest;
}

export interface FxIdWebFeaturesFtlIntegrationLauncherLauncherManifestEndpointRequest {
    game: string;
    branch: string;
}

/**
 * 
 */
export class FtlIntegrationApi extends runtime.BaseAPI {

    /**
     */
    async fxIdWebFeaturesFtlIntegrationLauncherActivateKeyEndpointRaw(requestParameters: FxIdWebFeaturesFtlIntegrationLauncherActivateKeyEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FxIdWebFeaturesFtlIntegrationLauncherActivateKeyResponse>> {
        if (requestParameters['game'] == null) {
            throw new runtime.RequiredError(
                'game',
                'Required parameter "game" was null or undefined when calling fxIdWebFeaturesFtlIntegrationLauncherActivateKeyEndpoint().'
            );
        }

        if (requestParameters['fxIdWebFeaturesFtlIntegrationLauncherActivateKeyRequest'] == null) {
            throw new runtime.RequiredError(
                'fxIdWebFeaturesFtlIntegrationLauncherActivateKeyRequest',
                'Required parameter "fxIdWebFeaturesFtlIntegrationLauncherActivateKeyRequest" was null or undefined when calling fxIdWebFeaturesFtlIntegrationLauncherActivateKeyEndpoint().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWTBearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/ftl_integration/{game}/launcher/activate_key`.replace(`{${"game"}}`, encodeURIComponent(String(requestParameters['game']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FxIdWebFeaturesFtlIntegrationLauncherActivateKeyRequestToJSON(requestParameters['fxIdWebFeaturesFtlIntegrationLauncherActivateKeyRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FxIdWebFeaturesFtlIntegrationLauncherActivateKeyResponseFromJSON(jsonValue));
    }

    /**
     */
    async fxIdWebFeaturesFtlIntegrationLauncherActivateKeyEndpoint(game: string, fxIdWebFeaturesFtlIntegrationLauncherActivateKeyRequest: FxIdWebFeaturesFtlIntegrationLauncherActivateKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FxIdWebFeaturesFtlIntegrationLauncherActivateKeyResponse> {
        const response = await this.fxIdWebFeaturesFtlIntegrationLauncherActivateKeyEndpointRaw({ game: game, fxIdWebFeaturesFtlIntegrationLauncherActivateKeyRequest: fxIdWebFeaturesFtlIntegrationLauncherActivateKeyRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async fxIdWebFeaturesFtlIntegrationLauncherAvailableBranchesEndpointRaw(requestParameters: FxIdWebFeaturesFtlIntegrationLauncherAvailableBranchesEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FxIdWebFeaturesFtlIntegrationLauncherAvailableBranchesResponse>> {
        if (requestParameters['game'] == null) {
            throw new runtime.RequiredError(
                'game',
                'Required parameter "game" was null or undefined when calling fxIdWebFeaturesFtlIntegrationLauncherAvailableBranchesEndpoint().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWTBearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/ftl_integration/{game}/launcher/available_branches`.replace(`{${"game"}}`, encodeURIComponent(String(requestParameters['game']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FxIdWebFeaturesFtlIntegrationLauncherAvailableBranchesResponseFromJSON(jsonValue));
    }

    /**
     */
    async fxIdWebFeaturesFtlIntegrationLauncherAvailableBranchesEndpoint(game: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FxIdWebFeaturesFtlIntegrationLauncherAvailableBranchesResponse> {
        const response = await this.fxIdWebFeaturesFtlIntegrationLauncherAvailableBranchesEndpointRaw({ game: game }, initOverrides);
        return await response.value();
    }

    /**
     */
    async fxIdWebFeaturesFtlIntegrationLauncherDownloadDirectEndpointRaw(requestParameters: FxIdWebFeaturesFtlIntegrationLauncherDownloadDirectEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FxIdWebFeaturesFtlIntegrationLauncherDownloadDirectResponse>> {
        if (requestParameters['game'] == null) {
            throw new runtime.RequiredError(
                'game',
                'Required parameter "game" was null or undefined when calling fxIdWebFeaturesFtlIntegrationLauncherDownloadDirectEndpoint().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/ftl_integration/{game}/launcher/download_direct`.replace(`{${"game"}}`, encodeURIComponent(String(requestParameters['game']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FxIdWebFeaturesFtlIntegrationLauncherDownloadDirectResponseFromJSON(jsonValue));
    }

    /**
     */
    async fxIdWebFeaturesFtlIntegrationLauncherDownloadDirectEndpoint(game: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FxIdWebFeaturesFtlIntegrationLauncherDownloadDirectResponse> {
        const response = await this.fxIdWebFeaturesFtlIntegrationLauncherDownloadDirectEndpointRaw({ game: game }, initOverrides);
        return await response.value();
    }

    /**
     */
    async fxIdWebFeaturesFtlIntegrationLauncherDownloadEndpointRaw(requestParameters: FxIdWebFeaturesFtlIntegrationLauncherDownloadEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['game'] == null) {
            throw new runtime.RequiredError(
                'game',
                'Required parameter "game" was null or undefined when calling fxIdWebFeaturesFtlIntegrationLauncherDownloadEndpoint().'
            );
        }

        if (requestParameters['sessionId'] == null) {
            throw new runtime.RequiredError(
                'sessionId',
                'Required parameter "sessionId" was null or undefined when calling fxIdWebFeaturesFtlIntegrationLauncherDownloadEndpoint().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/ftl_integration/{game}/launcher/download/{sessionId}`.replace(`{${"game"}}`, encodeURIComponent(String(requestParameters['game']))).replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters['sessionId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async fxIdWebFeaturesFtlIntegrationLauncherDownloadEndpoint(game: string, sessionId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.fxIdWebFeaturesFtlIntegrationLauncherDownloadEndpointRaw({ game: game, sessionId: sessionId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async fxIdWebFeaturesFtlIntegrationLauncherDownloadRequestEndpointRaw(requestParameters: FxIdWebFeaturesFtlIntegrationLauncherDownloadRequestEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FxIdWebFeaturesFtlIntegrationLauncherDownloadRequestResponse>> {
        if (requestParameters['game'] == null) {
            throw new runtime.RequiredError(
                'game',
                'Required parameter "game" was null or undefined when calling fxIdWebFeaturesFtlIntegrationLauncherDownloadRequestEndpoint().'
            );
        }

        if (requestParameters['fxIdWebFeaturesFtlIntegrationLauncherDownloadRequestRequest'] == null) {
            throw new runtime.RequiredError(
                'fxIdWebFeaturesFtlIntegrationLauncherDownloadRequestRequest',
                'Required parameter "fxIdWebFeaturesFtlIntegrationLauncherDownloadRequestRequest" was null or undefined when calling fxIdWebFeaturesFtlIntegrationLauncherDownloadRequestEndpoint().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/ftl_integration/{game}/launcher/request_download`.replace(`{${"game"}}`, encodeURIComponent(String(requestParameters['game']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FxIdWebFeaturesFtlIntegrationLauncherDownloadRequestRequestToJSON(requestParameters['fxIdWebFeaturesFtlIntegrationLauncherDownloadRequestRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FxIdWebFeaturesFtlIntegrationLauncherDownloadRequestResponseFromJSON(jsonValue));
    }

    /**
     */
    async fxIdWebFeaturesFtlIntegrationLauncherDownloadRequestEndpoint(game: string, fxIdWebFeaturesFtlIntegrationLauncherDownloadRequestRequest: FxIdWebFeaturesFtlIntegrationLauncherDownloadRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FxIdWebFeaturesFtlIntegrationLauncherDownloadRequestResponse> {
        const response = await this.fxIdWebFeaturesFtlIntegrationLauncherDownloadRequestEndpointRaw({ game: game, fxIdWebFeaturesFtlIntegrationLauncherDownloadRequestRequest: fxIdWebFeaturesFtlIntegrationLauncherDownloadRequestRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async fxIdWebFeaturesFtlIntegrationLauncherLauncherManifestEndpointRaw(requestParameters: FxIdWebFeaturesFtlIntegrationLauncherLauncherManifestEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FxIdWebFeaturesFtlIntegrationLauncherLauncherManifestResponse>> {
        if (requestParameters['game'] == null) {
            throw new runtime.RequiredError(
                'game',
                'Required parameter "game" was null or undefined when calling fxIdWebFeaturesFtlIntegrationLauncherLauncherManifestEndpoint().'
            );
        }

        if (requestParameters['branch'] == null) {
            throw new runtime.RequiredError(
                'branch',
                'Required parameter "branch" was null or undefined when calling fxIdWebFeaturesFtlIntegrationLauncherLauncherManifestEndpoint().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWTBearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/ftl_integration/{game}/launcher/manifest/{branch}`.replace(`{${"game"}}`, encodeURIComponent(String(requestParameters['game']))).replace(`{${"branch"}}`, encodeURIComponent(String(requestParameters['branch']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FxIdWebFeaturesFtlIntegrationLauncherLauncherManifestResponseFromJSON(jsonValue));
    }

    /**
     */
    async fxIdWebFeaturesFtlIntegrationLauncherLauncherManifestEndpoint(game: string, branch: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FxIdWebFeaturesFtlIntegrationLauncherLauncherManifestResponse> {
        const response = await this.fxIdWebFeaturesFtlIntegrationLauncherLauncherManifestEndpointRaw({ game: game, branch: branch }, initOverrides);
        return await response.value();
    }

}
