/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxGamesLibWebPlatformDataOptionsAndroidOptions } from './FxGamesLibWebPlatformDataOptionsAndroidOptions';
import {
    instanceOfFxGamesLibWebPlatformDataOptionsAndroidOptions,
    FxGamesLibWebPlatformDataOptionsAndroidOptionsFromJSON,
    FxGamesLibWebPlatformDataOptionsAndroidOptionsFromJSONTyped,
    FxGamesLibWebPlatformDataOptionsAndroidOptionsToJSON,
} from './FxGamesLibWebPlatformDataOptionsAndroidOptions';

/**
 * @type FxGamesLibWebPlatformDataOptionsAndroid
 * 
 * @export
 */
export type FxGamesLibWebPlatformDataOptionsAndroid = FxGamesLibWebPlatformDataOptionsAndroidOptions;

export function FxGamesLibWebPlatformDataOptionsAndroidFromJSON(json: any): FxGamesLibWebPlatformDataOptionsAndroid {
    return FxGamesLibWebPlatformDataOptionsAndroidFromJSONTyped(json, false);
}

export function FxGamesLibWebPlatformDataOptionsAndroidFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxGamesLibWebPlatformDataOptionsAndroid {
    if (json == null) {
        return json;
    }
    if (instanceOfFxGamesLibWebPlatformDataOptionsAndroidOptions(json)) {
        return FxGamesLibWebPlatformDataOptionsAndroidOptionsFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxGamesLibWebPlatformDataOptionsAndroidToJSON(json: any): any {
    return FxGamesLibWebPlatformDataOptionsAndroidToJSONTyped(json, false);
}

export function FxGamesLibWebPlatformDataOptionsAndroidToJSONTyped(value?: FxGamesLibWebPlatformDataOptionsAndroid | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxGamesLibWebPlatformDataOptionsAndroidOptions(value)) {
        return FxGamesLibWebPlatformDataOptionsAndroidOptionsToJSON(value as FxGamesLibWebPlatformDataOptionsAndroidOptions);
    }

    return {};
}

