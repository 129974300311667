/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FxGamesLibWebPlatformDataOptionsWebOptionsRuntimes } from './FxGamesLibWebPlatformDataOptionsWebOptionsRuntimes';
import {
    FxGamesLibWebPlatformDataOptionsWebOptionsRuntimesFromJSON,
    FxGamesLibWebPlatformDataOptionsWebOptionsRuntimesFromJSONTyped,
    FxGamesLibWebPlatformDataOptionsWebOptionsRuntimesToJSON,
    FxGamesLibWebPlatformDataOptionsWebOptionsRuntimesToJSONTyped,
} from './FxGamesLibWebPlatformDataOptionsWebOptionsRuntimes';

/**
 * 
 * @export
 * @interface FxGamesLibWebPlatformDataOptionsWebOptions
 */
export interface FxGamesLibWebPlatformDataOptionsWebOptions {
    /**
     * 
     * @type {string}
     * @memberof FxGamesLibWebPlatformDataOptionsWebOptions
     */
    PlayUrl: string;
    /**
     * 
     * @type {FxGamesLibWebPlatformDataOptionsWebOptionsRuntimes}
     * @memberof FxGamesLibWebPlatformDataOptionsWebOptions
     */
    Runtimes?: FxGamesLibWebPlatformDataOptionsWebOptionsRuntimes | null;
}

/**
 * Check if a given object implements the FxGamesLibWebPlatformDataOptionsWebOptions interface.
 */
export function instanceOfFxGamesLibWebPlatformDataOptionsWebOptions(value: object): value is FxGamesLibWebPlatformDataOptionsWebOptions {
    if (!('PlayUrl' in value) || value['PlayUrl'] === undefined) return false;
    return true;
}

export function FxGamesLibWebPlatformDataOptionsWebOptionsFromJSON(json: any): FxGamesLibWebPlatformDataOptionsWebOptions {
    return FxGamesLibWebPlatformDataOptionsWebOptionsFromJSONTyped(json, false);
}

export function FxGamesLibWebPlatformDataOptionsWebOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxGamesLibWebPlatformDataOptionsWebOptions {
    if (json == null) {
        return json;
    }
    return {
        
        'PlayUrl': json['PlayUrl'],
        'Runtimes': json['Runtimes'] == null ? undefined : FxGamesLibWebPlatformDataOptionsWebOptionsRuntimesFromJSON(json['Runtimes']),
    };
}

export function FxGamesLibWebPlatformDataOptionsWebOptionsToJSON(json: any): FxGamesLibWebPlatformDataOptionsWebOptions {
    return FxGamesLibWebPlatformDataOptionsWebOptionsToJSONTyped(json, false);
}

export function FxGamesLibWebPlatformDataOptionsWebOptionsToJSONTyped(value?: FxGamesLibWebPlatformDataOptionsWebOptions | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'PlayUrl': value['PlayUrl'],
        'Runtimes': FxGamesLibWebPlatformDataOptionsWebOptionsRuntimesToJSON(value['Runtimes']),
    };
}

