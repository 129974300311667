/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FxIdApplicationStoreYandexGamesYandexBonusResultDataErrorData } from './FxIdApplicationStoreYandexGamesYandexBonusResultDataErrorData';
import {
    FxIdApplicationStoreYandexGamesYandexBonusResultDataErrorDataFromJSON,
    FxIdApplicationStoreYandexGamesYandexBonusResultDataErrorDataFromJSONTyped,
    FxIdApplicationStoreYandexGamesYandexBonusResultDataErrorDataToJSON,
    FxIdApplicationStoreYandexGamesYandexBonusResultDataErrorDataToJSONTyped,
} from './FxIdApplicationStoreYandexGamesYandexBonusResultDataErrorData';
import type { FxIdApplicationStoreYandexGamesYandexBonusResultDataSucccessData } from './FxIdApplicationStoreYandexGamesYandexBonusResultDataSucccessData';
import {
    FxIdApplicationStoreYandexGamesYandexBonusResultDataSucccessDataFromJSON,
    FxIdApplicationStoreYandexGamesYandexBonusResultDataSucccessDataFromJSONTyped,
    FxIdApplicationStoreYandexGamesYandexBonusResultDataSucccessDataToJSON,
    FxIdApplicationStoreYandexGamesYandexBonusResultDataSucccessDataToJSONTyped,
} from './FxIdApplicationStoreYandexGamesYandexBonusResultDataSucccessData';

/**
 * 
 * @export
 * @interface FxIdApplicationStoreYandexGamesYandexBonusResultData
 */
export interface FxIdApplicationStoreYandexGamesYandexBonusResultData {
    /**
     * 
     * @type {FxIdApplicationStoreYandexGamesYandexBonusResultDataSucccessData}
     * @memberof FxIdApplicationStoreYandexGamesYandexBonusResultData
     */
    SucccessData?: FxIdApplicationStoreYandexGamesYandexBonusResultDataSucccessData | null;
    /**
     * 
     * @type {FxIdApplicationStoreYandexGamesYandexBonusResultDataErrorData}
     * @memberof FxIdApplicationStoreYandexGamesYandexBonusResultData
     */
    ErrorData?: FxIdApplicationStoreYandexGamesYandexBonusResultDataErrorData | null;
}

/**
 * Check if a given object implements the FxIdApplicationStoreYandexGamesYandexBonusResultData interface.
 */
export function instanceOfFxIdApplicationStoreYandexGamesYandexBonusResultData(value: object): value is FxIdApplicationStoreYandexGamesYandexBonusResultData {
    return true;
}

export function FxIdApplicationStoreYandexGamesYandexBonusResultDataFromJSON(json: any): FxIdApplicationStoreYandexGamesYandexBonusResultData {
    return FxIdApplicationStoreYandexGamesYandexBonusResultDataFromJSONTyped(json, false);
}

export function FxIdApplicationStoreYandexGamesYandexBonusResultDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationStoreYandexGamesYandexBonusResultData {
    if (json == null) {
        return json;
    }
    return {
        
        'SucccessData': json['SucccessData'] == null ? undefined : FxIdApplicationStoreYandexGamesYandexBonusResultDataSucccessDataFromJSON(json['SucccessData']),
        'ErrorData': json['ErrorData'] == null ? undefined : FxIdApplicationStoreYandexGamesYandexBonusResultDataErrorDataFromJSON(json['ErrorData']),
    };
}

export function FxIdApplicationStoreYandexGamesYandexBonusResultDataToJSON(json: any): FxIdApplicationStoreYandexGamesYandexBonusResultData {
    return FxIdApplicationStoreYandexGamesYandexBonusResultDataToJSONTyped(json, false);
}

export function FxIdApplicationStoreYandexGamesYandexBonusResultDataToJSONTyped(value?: FxIdApplicationStoreYandexGamesYandexBonusResultData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'SucccessData': FxIdApplicationStoreYandexGamesYandexBonusResultDataSucccessDataToJSON(value['SucccessData']),
        'ErrorData': FxIdApplicationStoreYandexGamesYandexBonusResultDataErrorDataToJSON(value['ErrorData']),
    };
}

