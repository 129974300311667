/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FxIdApplicationStoreCreatePaymentHandlerPersonalInfoRequirementsEmailRequirement } from './FxIdApplicationStoreCreatePaymentHandlerPersonalInfoRequirementsEmailRequirement';
import {
    FxIdApplicationStoreCreatePaymentHandlerPersonalInfoRequirementsEmailRequirementFromJSON,
    FxIdApplicationStoreCreatePaymentHandlerPersonalInfoRequirementsEmailRequirementFromJSONTyped,
    FxIdApplicationStoreCreatePaymentHandlerPersonalInfoRequirementsEmailRequirementToJSON,
    FxIdApplicationStoreCreatePaymentHandlerPersonalInfoRequirementsEmailRequirementToJSONTyped,
} from './FxIdApplicationStoreCreatePaymentHandlerPersonalInfoRequirementsEmailRequirement';

/**
 * 
 * @export
 * @interface FxIdApplicationStoreCreatePaymentHandlerPersonalInfoRequirements
 */
export interface FxIdApplicationStoreCreatePaymentHandlerPersonalInfoRequirements {
    /**
     * 
     * @type {FxIdApplicationStoreCreatePaymentHandlerPersonalInfoRequirementsEmailRequirement}
     * @memberof FxIdApplicationStoreCreatePaymentHandlerPersonalInfoRequirements
     */
    EmailRequirement?: FxIdApplicationStoreCreatePaymentHandlerPersonalInfoRequirementsEmailRequirement | null;
}

/**
 * Check if a given object implements the FxIdApplicationStoreCreatePaymentHandlerPersonalInfoRequirements interface.
 */
export function instanceOfFxIdApplicationStoreCreatePaymentHandlerPersonalInfoRequirements(value: object): value is FxIdApplicationStoreCreatePaymentHandlerPersonalInfoRequirements {
    return true;
}

export function FxIdApplicationStoreCreatePaymentHandlerPersonalInfoRequirementsFromJSON(json: any): FxIdApplicationStoreCreatePaymentHandlerPersonalInfoRequirements {
    return FxIdApplicationStoreCreatePaymentHandlerPersonalInfoRequirementsFromJSONTyped(json, false);
}

export function FxIdApplicationStoreCreatePaymentHandlerPersonalInfoRequirementsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationStoreCreatePaymentHandlerPersonalInfoRequirements {
    if (json == null) {
        return json;
    }
    return {
        
        'EmailRequirement': json['EmailRequirement'] == null ? undefined : FxIdApplicationStoreCreatePaymentHandlerPersonalInfoRequirementsEmailRequirementFromJSON(json['EmailRequirement']),
    };
}

export function FxIdApplicationStoreCreatePaymentHandlerPersonalInfoRequirementsToJSON(json: any): FxIdApplicationStoreCreatePaymentHandlerPersonalInfoRequirements {
    return FxIdApplicationStoreCreatePaymentHandlerPersonalInfoRequirementsToJSONTyped(json, false);
}

export function FxIdApplicationStoreCreatePaymentHandlerPersonalInfoRequirementsToJSONTyped(value?: FxIdApplicationStoreCreatePaymentHandlerPersonalInfoRequirements | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'EmailRequirement': FxIdApplicationStoreCreatePaymentHandlerPersonalInfoRequirementsEmailRequirementToJSON(value['EmailRequirement']),
    };
}

