/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FxIdDomainEntitiesNativePlatform } from './FxIdDomainEntitiesNativePlatform';
import {
    FxIdDomainEntitiesNativePlatformFromJSON,
    FxIdDomainEntitiesNativePlatformFromJSONTyped,
    FxIdDomainEntitiesNativePlatformToJSON,
    FxIdDomainEntitiesNativePlatformToJSONTyped,
} from './FxIdDomainEntitiesNativePlatform';
import type { FxIdDomainAnalyticsTsAnalyticsEvent } from './FxIdDomainAnalyticsTsAnalyticsEvent';
import {
    FxIdDomainAnalyticsTsAnalyticsEventFromJSON,
    FxIdDomainAnalyticsTsAnalyticsEventFromJSONTyped,
    FxIdDomainAnalyticsTsAnalyticsEventToJSON,
    FxIdDomainAnalyticsTsAnalyticsEventToJSONTyped,
} from './FxIdDomainAnalyticsTsAnalyticsEvent';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesAnalyticsTrackRequest
 */
export interface FxIdWebFeaturesAnalyticsTrackRequest {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAnalyticsTrackRequest
     */
    SessionId?: string | null;
    /**
     * 
     * @type {FxIdDomainEntitiesNativePlatform}
     * @memberof FxIdWebFeaturesAnalyticsTrackRequest
     */
    Platform: FxIdDomainEntitiesNativePlatform;
    /**
     * 
     * @type {number}
     * @memberof FxIdWebFeaturesAnalyticsTrackRequest
     */
    BuildId: number;
    /**
     * 
     * @type {Array<FxIdDomainAnalyticsTsAnalyticsEvent>}
     * @memberof FxIdWebFeaturesAnalyticsTrackRequest
     */
    Events: Array<FxIdDomainAnalyticsTsAnalyticsEvent>;
}



/**
 * Check if a given object implements the FxIdWebFeaturesAnalyticsTrackRequest interface.
 */
export function instanceOfFxIdWebFeaturesAnalyticsTrackRequest(value: object): value is FxIdWebFeaturesAnalyticsTrackRequest {
    if (!('Platform' in value) || value['Platform'] === undefined) return false;
    if (!('BuildId' in value) || value['BuildId'] === undefined) return false;
    if (!('Events' in value) || value['Events'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesAnalyticsTrackRequestFromJSON(json: any): FxIdWebFeaturesAnalyticsTrackRequest {
    return FxIdWebFeaturesAnalyticsTrackRequestFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAnalyticsTrackRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAnalyticsTrackRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'SessionId': json['SessionId'] == null ? undefined : json['SessionId'],
        'Platform': FxIdDomainEntitiesNativePlatformFromJSON(json['Platform']),
        'BuildId': json['BuildId'],
        'Events': ((json['Events'] as Array<any>).map(FxIdDomainAnalyticsTsAnalyticsEventFromJSON)),
    };
}

export function FxIdWebFeaturesAnalyticsTrackRequestToJSON(json: any): FxIdWebFeaturesAnalyticsTrackRequest {
    return FxIdWebFeaturesAnalyticsTrackRequestToJSONTyped(json, false);
}

export function FxIdWebFeaturesAnalyticsTrackRequestToJSONTyped(value?: FxIdWebFeaturesAnalyticsTrackRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'SessionId': value['SessionId'],
        'Platform': FxIdDomainEntitiesNativePlatformToJSON(value['Platform']),
        'BuildId': value['BuildId'],
        'Events': ((value['Events'] as Array<any>).map(FxIdDomainAnalyticsTsAnalyticsEventToJSON)),
    };
}

