/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FxIdWebFeaturesStoreCreatePaymentRequestVkontaktePlay } from './FxIdWebFeaturesStoreCreatePaymentRequestVkontaktePlay';
import {
    FxIdWebFeaturesStoreCreatePaymentRequestVkontaktePlayFromJSON,
    FxIdWebFeaturesStoreCreatePaymentRequestVkontaktePlayFromJSONTyped,
    FxIdWebFeaturesStoreCreatePaymentRequestVkontaktePlayToJSON,
    FxIdWebFeaturesStoreCreatePaymentRequestVkontaktePlayToJSONTyped,
} from './FxIdWebFeaturesStoreCreatePaymentRequestVkontaktePlay';
import type { FxIdWebFeaturesStoreCreatePaymentRequestSteam } from './FxIdWebFeaturesStoreCreatePaymentRequestSteam';
import {
    FxIdWebFeaturesStoreCreatePaymentRequestSteamFromJSON,
    FxIdWebFeaturesStoreCreatePaymentRequestSteamFromJSONTyped,
    FxIdWebFeaturesStoreCreatePaymentRequestSteamToJSON,
    FxIdWebFeaturesStoreCreatePaymentRequestSteamToJSONTyped,
} from './FxIdWebFeaturesStoreCreatePaymentRequestSteam';
import type { FxIdWebFeaturesStoreCreatePaymentRequestWebPublishingPlatform } from './FxIdWebFeaturesStoreCreatePaymentRequestWebPublishingPlatform';
import {
    FxIdWebFeaturesStoreCreatePaymentRequestWebPublishingPlatformFromJSON,
    FxIdWebFeaturesStoreCreatePaymentRequestWebPublishingPlatformFromJSONTyped,
    FxIdWebFeaturesStoreCreatePaymentRequestWebPublishingPlatformToJSON,
    FxIdWebFeaturesStoreCreatePaymentRequestWebPublishingPlatformToJSONTyped,
} from './FxIdWebFeaturesStoreCreatePaymentRequestWebPublishingPlatform';
import type { FxIdApplicationStoreCreatePaymentHandlerEmbeddingType } from './FxIdApplicationStoreCreatePaymentHandlerEmbeddingType';
import {
    FxIdApplicationStoreCreatePaymentHandlerEmbeddingTypeFromJSON,
    FxIdApplicationStoreCreatePaymentHandlerEmbeddingTypeFromJSONTyped,
    FxIdApplicationStoreCreatePaymentHandlerEmbeddingTypeToJSON,
    FxIdApplicationStoreCreatePaymentHandlerEmbeddingTypeToJSONTyped,
} from './FxIdApplicationStoreCreatePaymentHandlerEmbeddingType';
import type { FxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGames } from './FxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGames';
import {
    FxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGamesFromJSON,
    FxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGamesFromJSONTyped,
    FxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGamesToJSON,
    FxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGamesToJSONTyped,
} from './FxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGames';
import type { FxIdWebFeaturesStoreCreatePaymentRequestDeveloperPayload } from './FxIdWebFeaturesStoreCreatePaymentRequestDeveloperPayload';
import {
    FxIdWebFeaturesStoreCreatePaymentRequestDeveloperPayloadFromJSON,
    FxIdWebFeaturesStoreCreatePaymentRequestDeveloperPayloadFromJSONTyped,
    FxIdWebFeaturesStoreCreatePaymentRequestDeveloperPayloadToJSON,
    FxIdWebFeaturesStoreCreatePaymentRequestDeveloperPayloadToJSONTyped,
} from './FxIdWebFeaturesStoreCreatePaymentRequestDeveloperPayload';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesStoreCreatePaymentRequest
 */
export interface FxIdWebFeaturesStoreCreatePaymentRequest {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesStoreCreatePaymentRequest
     */
    Game: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesStoreCreatePaymentRequest
     */
    Sku: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesStoreCreatePaymentRequest
     */
    Token: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesStoreCreatePaymentRequest
     */
    Currency?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesStoreCreatePaymentRequest
     */
    Country?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesStoreCreatePaymentRequest
     */
    RedirectUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesStoreCreatePaymentRequest
     */
    RedirectOnErrorUrl?: string | null;
    /**
     * 
     * @type {FxIdWebFeaturesStoreCreatePaymentRequestDeveloperPayload}
     * @memberof FxIdWebFeaturesStoreCreatePaymentRequest
     */
    DeveloperPayload?: FxIdWebFeaturesStoreCreatePaymentRequestDeveloperPayload | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesStoreCreatePaymentRequest
     */
    ProductNameHint?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesStoreCreatePaymentRequest
     */
    ProductDescriptionHint?: string | null;
    /**
     * 
     * @type {FxIdWebFeaturesStoreCreatePaymentRequestWebPublishingPlatform}
     * @memberof FxIdWebFeaturesStoreCreatePaymentRequest
     */
    WebPublishingPlatform: FxIdWebFeaturesStoreCreatePaymentRequestWebPublishingPlatform;
    /**
     * 
     * @type {FxIdWebFeaturesStoreCreatePaymentRequestVkontaktePlay}
     * @memberof FxIdWebFeaturesStoreCreatePaymentRequest
     */
    VkontaktePlay?: FxIdWebFeaturesStoreCreatePaymentRequestVkontaktePlay | null;
    /**
     * 
     * @type {FxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGames}
     * @memberof FxIdWebFeaturesStoreCreatePaymentRequest
     */
    AbsoluteGames?: FxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGames | null;
    /**
     * 
     * @type {FxIdWebFeaturesStoreCreatePaymentRequestSteam}
     * @memberof FxIdWebFeaturesStoreCreatePaymentRequest
     */
    Steam?: FxIdWebFeaturesStoreCreatePaymentRequestSteam | null;
    /**
     * 
     * @type {FxIdApplicationStoreCreatePaymentHandlerEmbeddingType}
     * @memberof FxIdWebFeaturesStoreCreatePaymentRequest
     */
    EmbeddingType: FxIdApplicationStoreCreatePaymentHandlerEmbeddingType;
}



/**
 * Check if a given object implements the FxIdWebFeaturesStoreCreatePaymentRequest interface.
 */
export function instanceOfFxIdWebFeaturesStoreCreatePaymentRequest(value: object): value is FxIdWebFeaturesStoreCreatePaymentRequest {
    if (!('Game' in value) || value['Game'] === undefined) return false;
    if (!('Sku' in value) || value['Sku'] === undefined) return false;
    if (!('Token' in value) || value['Token'] === undefined) return false;
    if (!('WebPublishingPlatform' in value) || value['WebPublishingPlatform'] === undefined) return false;
    if (!('EmbeddingType' in value) || value['EmbeddingType'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesStoreCreatePaymentRequestFromJSON(json: any): FxIdWebFeaturesStoreCreatePaymentRequest {
    return FxIdWebFeaturesStoreCreatePaymentRequestFromJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreCreatePaymentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesStoreCreatePaymentRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'Game': json['Game'],
        'Sku': json['Sku'],
        'Token': json['Token'],
        'Currency': json['Currency'] == null ? undefined : json['Currency'],
        'Country': json['Country'] == null ? undefined : json['Country'],
        'RedirectUrl': json['RedirectUrl'] == null ? undefined : json['RedirectUrl'],
        'RedirectOnErrorUrl': json['RedirectOnErrorUrl'] == null ? undefined : json['RedirectOnErrorUrl'],
        'DeveloperPayload': json['DeveloperPayload'] == null ? undefined : FxIdWebFeaturesStoreCreatePaymentRequestDeveloperPayloadFromJSON(json['DeveloperPayload']),
        'ProductNameHint': json['ProductNameHint'] == null ? undefined : json['ProductNameHint'],
        'ProductDescriptionHint': json['ProductDescriptionHint'] == null ? undefined : json['ProductDescriptionHint'],
        'WebPublishingPlatform': FxIdWebFeaturesStoreCreatePaymentRequestWebPublishingPlatformFromJSON(json['WebPublishingPlatform']),
        'VkontaktePlay': json['VkontaktePlay'] == null ? undefined : FxIdWebFeaturesStoreCreatePaymentRequestVkontaktePlayFromJSON(json['VkontaktePlay']),
        'AbsoluteGames': json['AbsoluteGames'] == null ? undefined : FxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGamesFromJSON(json['AbsoluteGames']),
        'Steam': json['Steam'] == null ? undefined : FxIdWebFeaturesStoreCreatePaymentRequestSteamFromJSON(json['Steam']),
        'EmbeddingType': FxIdApplicationStoreCreatePaymentHandlerEmbeddingTypeFromJSON(json['EmbeddingType']),
    };
}

export function FxIdWebFeaturesStoreCreatePaymentRequestToJSON(json: any): FxIdWebFeaturesStoreCreatePaymentRequest {
    return FxIdWebFeaturesStoreCreatePaymentRequestToJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreCreatePaymentRequestToJSONTyped(value?: FxIdWebFeaturesStoreCreatePaymentRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'Game': value['Game'],
        'Sku': value['Sku'],
        'Token': value['Token'],
        'Currency': value['Currency'],
        'Country': value['Country'],
        'RedirectUrl': value['RedirectUrl'],
        'RedirectOnErrorUrl': value['RedirectOnErrorUrl'],
        'DeveloperPayload': FxIdWebFeaturesStoreCreatePaymentRequestDeveloperPayloadToJSON(value['DeveloperPayload']),
        'ProductNameHint': value['ProductNameHint'],
        'ProductDescriptionHint': value['ProductDescriptionHint'],
        'WebPublishingPlatform': FxIdWebFeaturesStoreCreatePaymentRequestWebPublishingPlatformToJSON(value['WebPublishingPlatform']),
        'VkontaktePlay': FxIdWebFeaturesStoreCreatePaymentRequestVkontaktePlayToJSON(value['VkontaktePlay']),
        'AbsoluteGames': FxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGamesToJSON(value['AbsoluteGames']),
        'Steam': FxIdWebFeaturesStoreCreatePaymentRequestSteamToJSON(value['Steam']),
        'EmbeddingType': FxIdApplicationStoreCreatePaymentHandlerEmbeddingTypeToJSON(value['EmbeddingType']),
    };
}

