/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesDocumentsGetAllDocumentsWithVersionsGetAllDocumentsWithVersionsRequest
 */
export interface FxIdWebFeaturesDocumentsGetAllDocumentsWithVersionsGetAllDocumentsWithVersionsRequest {
    /**
     * 
     * @type {number}
     * @memberof FxIdWebFeaturesDocumentsGetAllDocumentsWithVersionsGetAllDocumentsWithVersionsRequest
     */
    GameId: number;
}

/**
 * Check if a given object implements the FxIdWebFeaturesDocumentsGetAllDocumentsWithVersionsGetAllDocumentsWithVersionsRequest interface.
 */
export function instanceOfFxIdWebFeaturesDocumentsGetAllDocumentsWithVersionsGetAllDocumentsWithVersionsRequest(value: object): value is FxIdWebFeaturesDocumentsGetAllDocumentsWithVersionsGetAllDocumentsWithVersionsRequest {
    if (!('GameId' in value) || value['GameId'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesDocumentsGetAllDocumentsWithVersionsGetAllDocumentsWithVersionsRequestFromJSON(json: any): FxIdWebFeaturesDocumentsGetAllDocumentsWithVersionsGetAllDocumentsWithVersionsRequest {
    return FxIdWebFeaturesDocumentsGetAllDocumentsWithVersionsGetAllDocumentsWithVersionsRequestFromJSONTyped(json, false);
}

export function FxIdWebFeaturesDocumentsGetAllDocumentsWithVersionsGetAllDocumentsWithVersionsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesDocumentsGetAllDocumentsWithVersionsGetAllDocumentsWithVersionsRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'GameId': json['GameId'],
    };
}

export function FxIdWebFeaturesDocumentsGetAllDocumentsWithVersionsGetAllDocumentsWithVersionsRequestToJSON(json: any): FxIdWebFeaturesDocumentsGetAllDocumentsWithVersionsGetAllDocumentsWithVersionsRequest {
    return FxIdWebFeaturesDocumentsGetAllDocumentsWithVersionsGetAllDocumentsWithVersionsRequestToJSONTyped(json, false);
}

export function FxIdWebFeaturesDocumentsGetAllDocumentsWithVersionsGetAllDocumentsWithVersionsRequestToJSONTyped(value?: FxIdWebFeaturesDocumentsGetAllDocumentsWithVersionsGetAllDocumentsWithVersionsRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'GameId': value['GameId'],
    };
}

