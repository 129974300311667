/* eslint-disable @typescript-eslint/no-explicit-any */

import { AnalyticsEvents, IAnalyticsEvent, IAnalyticsEventsSender } from "./AnalyticsCodegen";
import OpenApiClient from "./OpenApiClient";
import type {
	FxIdDomainAnalyticsTsAnalyticsEvent,
	FxIdDomainEntitiesNativePlatform,
	FxIdWebFeaturesAnalyticsTrackRequest,
	InitOverrideFunction
} from "./gen";
import * as runtime from "./gen/runtime";
import { getAuthToken } from "@app/Api/Auth";

class AnalyticsSender implements IAnalyticsEventsSender {
	public static SessionId: string | undefined;
	public static BuildId: number | undefined;
	public static UserId: number | undefined;

	// Смотри NativePlatform в FxId
	private static DefaultPlatform = 24;

	BuildEvent = (createdAt?: number) => ({
		UserId: AnalyticsSender.UserId || 0,
		CreatedAt: createdAt ?? new Date().valueOf() / 1000,
		ServerCreatedAt: createdAt ?? new Date().valueOf() / 1000,
		Build: AnalyticsSender.BuildId || 0,
		Platform: AnalyticsSender.DefaultPlatform
	});
	SendEvent = async (analyticsEvent: IAnalyticsEvent) => {
		const authToken = getAuthToken();
		const compressedRequest = new AnalyticsEventCompressor().CompressRequest({
			SessionId: AnalyticsSender.SessionId,
			Platform: AnalyticsSender.DefaultPlatform,
			BuildId: AnalyticsSender.BuildId || 0,
			Events: [AnalyticsSender.MapEventToApiEvent(analyticsEvent)]
		});

		const initOverride: InitOverrideFunction = async (context) => {
			context.init.headers = {
				Authorization: `Bearer ${authToken}`,
				"Content-Type": "application/json"
			};
			context.init.body = compressedRequest;
			return context.init;
		};
		await OpenApiClient.Analytics.fxIdWebFeaturesAnalyticsTrackEndpoint(
			{
				SessionId: AnalyticsSender.SessionId,
				Platform: AnalyticsSender.DefaultPlatform,
				BuildId: AnalyticsSender.BuildId || 0,
				Events: [AnalyticsSender.MapEventToApiEvent(analyticsEvent)]
			},
			initOverride
		);
	};

	public static MapEventToApiEvent(event: IAnalyticsEvent): FxIdDomainAnalyticsTsAnalyticsEvent {
		return {
			UserId: event.UserId,
			CreatedAt: event.CreatedAt,
			ServerCreatedAt: event.ServerCreatedAt,
			Build: event.Build,
			Platform: event.Platform,
			EventId: event.EventId,
			I1: event.I1 ?? null,
			I2: event.I2 ?? null,
			I3: event.I3 ?? null,
			I4: event.I4 ?? null,
			I5: event.I5 ?? null,
			I6: event.I6 ?? null,
			I7: event.I7 ?? null,
			I8: event.I8 ?? null,
			I9: event.I9 ?? null,
			I10: event.I10 ?? null,
			F1: event.F1 ?? null,
			F2: event.F2 ?? null,
			F3: event.F3 ?? null,
			F4: event.F4 ?? null,
			F5: event.F5 ?? null,
			F6: event.F6 ?? null,
			F7: event.F7 ?? null,
			F8: event.F8 ?? null,
			F9: event.F9 ?? null,
			F10: event.F10 ?? null,
			S1: event.S1 ?? null,
			S2: event.S2 ?? null,
			S3: event.S3 ?? null,
			S4: event.S4 ?? null,
			S5: event.S5 ?? null,
			S6: event.S6 ?? null,
			S7: event.S7 ?? null,
			S8: event.S8 ?? null,
			S9: event.S9 ?? null,
			S10: event.S10 ?? null,
			S11: event.S11 ?? null,
			S12: event.S12 ?? null,
			S13: event.S13 ?? null,
			S14: event.S14 ?? null,
			S15: event.S15 ?? null,
			J1: event.J1 ?? null,
			J2: event.J2 ?? null,
			J3: event.J3 ?? null,
			J4: event.J4 ?? null
		};
	}
}

const sender = new AnalyticsEvents(new AnalyticsSender());

export default sender;

interface CompressedAnalyticsEventRequest {
	SessionId?: string | null;
	Platform: FxIdDomainEntitiesNativePlatform;
	BuildId: number;
	Events: Array<any>;
}

class AnalyticsEventCompressor extends runtime.BaseAPI {
	public CompressRequest(request: FxIdWebFeaturesAnalyticsTrackRequest): CompressedAnalyticsEventRequest {
		return {
			SessionId: request.SessionId,
			Platform: request.Platform,
			BuildId: request.BuildId,
			Events: this.compressEvents(request.Events)
		};
	}

	compressEvents(events: Array<FxIdDomainAnalyticsTsAnalyticsEvent>): Array<any> {
		return events.map((event) => this.removeNullFields(event));
	}

	removeNullFields(event: FxIdDomainAnalyticsTsAnalyticsEvent): any {
		return Object.entries(event).reduce((acc, [key, value]) => {
			if (value !== null && value !== undefined) {
				acc[key] = value;
			}
			return acc;
		}, {} as any);
	}
}
