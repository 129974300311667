/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FxGamesLibWebPublicDataBaseStatisticsOptionsDevToDev } from './FxGamesLibWebPublicDataBaseStatisticsOptionsDevToDev';
import {
    FxGamesLibWebPublicDataBaseStatisticsOptionsDevToDevFromJSON,
    FxGamesLibWebPublicDataBaseStatisticsOptionsDevToDevFromJSONTyped,
    FxGamesLibWebPublicDataBaseStatisticsOptionsDevToDevToJSON,
    FxGamesLibWebPublicDataBaseStatisticsOptionsDevToDevToJSONTyped,
} from './FxGamesLibWebPublicDataBaseStatisticsOptionsDevToDev';

/**
 * 
 * @export
 * @interface FxGamesLibWebPublicDataBaseStatisticsOptions
 */
export interface FxGamesLibWebPublicDataBaseStatisticsOptions {
    /**
     * 
     * @type {FxGamesLibWebPublicDataBaseStatisticsOptionsDevToDev}
     * @memberof FxGamesLibWebPublicDataBaseStatisticsOptions
     */
    DevToDev?: FxGamesLibWebPublicDataBaseStatisticsOptionsDevToDev | null;
}

/**
 * Check if a given object implements the FxGamesLibWebPublicDataBaseStatisticsOptions interface.
 */
export function instanceOfFxGamesLibWebPublicDataBaseStatisticsOptions(value: object): value is FxGamesLibWebPublicDataBaseStatisticsOptions {
    return true;
}

export function FxGamesLibWebPublicDataBaseStatisticsOptionsFromJSON(json: any): FxGamesLibWebPublicDataBaseStatisticsOptions {
    return FxGamesLibWebPublicDataBaseStatisticsOptionsFromJSONTyped(json, false);
}

export function FxGamesLibWebPublicDataBaseStatisticsOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxGamesLibWebPublicDataBaseStatisticsOptions {
    if (json == null) {
        return json;
    }
    return {
        
        'DevToDev': json['DevToDev'] == null ? undefined : FxGamesLibWebPublicDataBaseStatisticsOptionsDevToDevFromJSON(json['DevToDev']),
    };
}

export function FxGamesLibWebPublicDataBaseStatisticsOptionsToJSON(json: any): FxGamesLibWebPublicDataBaseStatisticsOptions {
    return FxGamesLibWebPublicDataBaseStatisticsOptionsToJSONTyped(json, false);
}

export function FxGamesLibWebPublicDataBaseStatisticsOptionsToJSONTyped(value?: FxGamesLibWebPublicDataBaseStatisticsOptions | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'DevToDev': FxGamesLibWebPublicDataBaseStatisticsOptionsDevToDevToJSON(value['DevToDev']),
    };
}

