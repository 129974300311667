/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdApplicationStoreCreatePaymentHandlerPersonalInfoRequirements } from './FxIdApplicationStoreCreatePaymentHandlerPersonalInfoRequirements';
import {
    instanceOfFxIdApplicationStoreCreatePaymentHandlerPersonalInfoRequirements,
    FxIdApplicationStoreCreatePaymentHandlerPersonalInfoRequirementsFromJSON,
    FxIdApplicationStoreCreatePaymentHandlerPersonalInfoRequirementsFromJSONTyped,
    FxIdApplicationStoreCreatePaymentHandlerPersonalInfoRequirementsToJSON,
} from './FxIdApplicationStoreCreatePaymentHandlerPersonalInfoRequirements';

/**
 * @type FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultPersonalInfoRequirements
 * 
 * @export
 */
export type FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultPersonalInfoRequirements = FxIdApplicationStoreCreatePaymentHandlerPersonalInfoRequirements;

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultPersonalInfoRequirementsFromJSON(json: any): FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultPersonalInfoRequirements {
    return FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultPersonalInfoRequirementsFromJSONTyped(json, false);
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultPersonalInfoRequirementsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultPersonalInfoRequirements {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdApplicationStoreCreatePaymentHandlerPersonalInfoRequirements(json)) {
        return FxIdApplicationStoreCreatePaymentHandlerPersonalInfoRequirementsFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultPersonalInfoRequirementsToJSON(json: any): any {
    return FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultPersonalInfoRequirementsToJSONTyped(json, false);
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultPersonalInfoRequirementsToJSONTyped(value?: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultPersonalInfoRequirements | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdApplicationStoreCreatePaymentHandlerPersonalInfoRequirements(value)) {
        return FxIdApplicationStoreCreatePaymentHandlerPersonalInfoRequirementsToJSON(value as FxIdApplicationStoreCreatePaymentHandlerPersonalInfoRequirements);
    }

    return {};
}

