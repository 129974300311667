/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FxGamesLibWebPlatformDataOptionsWindows } from './FxGamesLibWebPlatformDataOptionsWindows';
import {
    FxGamesLibWebPlatformDataOptionsWindowsFromJSON,
    FxGamesLibWebPlatformDataOptionsWindowsFromJSONTyped,
    FxGamesLibWebPlatformDataOptionsWindowsToJSON,
    FxGamesLibWebPlatformDataOptionsWindowsToJSONTyped,
} from './FxGamesLibWebPlatformDataOptionsWindows';
import type { FxGamesLibWebPlatformDataOptionsVkontaktePlay } from './FxGamesLibWebPlatformDataOptionsVkontaktePlay';
import {
    FxGamesLibWebPlatformDataOptionsVkontaktePlayFromJSON,
    FxGamesLibWebPlatformDataOptionsVkontaktePlayFromJSONTyped,
    FxGamesLibWebPlatformDataOptionsVkontaktePlayToJSON,
    FxGamesLibWebPlatformDataOptionsVkontaktePlayToJSONTyped,
} from './FxGamesLibWebPlatformDataOptionsVkontaktePlay';
import type { FxGamesLibWebPlatformDataOptionsVkontakteCom } from './FxGamesLibWebPlatformDataOptionsVkontakteCom';
import {
    FxGamesLibWebPlatformDataOptionsVkontakteComFromJSON,
    FxGamesLibWebPlatformDataOptionsVkontakteComFromJSONTyped,
    FxGamesLibWebPlatformDataOptionsVkontakteComToJSON,
    FxGamesLibWebPlatformDataOptionsVkontakteComToJSONTyped,
} from './FxGamesLibWebPlatformDataOptionsVkontakteCom';
import type { FxGamesLibWebPlatformDataOptionsIos } from './FxGamesLibWebPlatformDataOptionsIos';
import {
    FxGamesLibWebPlatformDataOptionsIosFromJSON,
    FxGamesLibWebPlatformDataOptionsIosFromJSONTyped,
    FxGamesLibWebPlatformDataOptionsIosToJSON,
    FxGamesLibWebPlatformDataOptionsIosToJSONTyped,
} from './FxGamesLibWebPlatformDataOptionsIos';
import type { FxGamesLibWebPlatformDataOptionsAndroid } from './FxGamesLibWebPlatformDataOptionsAndroid';
import {
    FxGamesLibWebPlatformDataOptionsAndroidFromJSON,
    FxGamesLibWebPlatformDataOptionsAndroidFromJSONTyped,
    FxGamesLibWebPlatformDataOptionsAndroidToJSON,
    FxGamesLibWebPlatformDataOptionsAndroidToJSONTyped,
} from './FxGamesLibWebPlatformDataOptionsAndroid';
import type { FxGamesLibWebPlatformDataOptionsFacebookGames } from './FxGamesLibWebPlatformDataOptionsFacebookGames';
import {
    FxGamesLibWebPlatformDataOptionsFacebookGamesFromJSON,
    FxGamesLibWebPlatformDataOptionsFacebookGamesFromJSONTyped,
    FxGamesLibWebPlatformDataOptionsFacebookGamesToJSON,
    FxGamesLibWebPlatformDataOptionsFacebookGamesToJSONTyped,
} from './FxGamesLibWebPlatformDataOptionsFacebookGames';
import type { FxGamesLibWebPlatformDataOptionsOdnoklassniki } from './FxGamesLibWebPlatformDataOptionsOdnoklassniki';
import {
    FxGamesLibWebPlatformDataOptionsOdnoklassnikiFromJSON,
    FxGamesLibWebPlatformDataOptionsOdnoklassnikiFromJSONTyped,
    FxGamesLibWebPlatformDataOptionsOdnoklassnikiToJSON,
    FxGamesLibWebPlatformDataOptionsOdnoklassnikiToJSONTyped,
} from './FxGamesLibWebPlatformDataOptionsOdnoklassniki';
import type { FxGamesLibWebPlatformDataOptionsSteam } from './FxGamesLibWebPlatformDataOptionsSteam';
import {
    FxGamesLibWebPlatformDataOptionsSteamFromJSON,
    FxGamesLibWebPlatformDataOptionsSteamFromJSONTyped,
    FxGamesLibWebPlatformDataOptionsSteamToJSON,
    FxGamesLibWebPlatformDataOptionsSteamToJSONTyped,
} from './FxGamesLibWebPlatformDataOptionsSteam';
import type { FxGamesLibWebPlatformDataOptionsFxId } from './FxGamesLibWebPlatformDataOptionsFxId';
import {
    FxGamesLibWebPlatformDataOptionsFxIdFromJSON,
    FxGamesLibWebPlatformDataOptionsFxIdFromJSONTyped,
    FxGamesLibWebPlatformDataOptionsFxIdToJSON,
    FxGamesLibWebPlatformDataOptionsFxIdToJSONTyped,
} from './FxGamesLibWebPlatformDataOptionsFxId';
import type { FxGamesLibWebPlatformDataOptionsWeb } from './FxGamesLibWebPlatformDataOptionsWeb';
import {
    FxGamesLibWebPlatformDataOptionsWebFromJSON,
    FxGamesLibWebPlatformDataOptionsWebFromJSONTyped,
    FxGamesLibWebPlatformDataOptionsWebToJSON,
    FxGamesLibWebPlatformDataOptionsWebToJSONTyped,
} from './FxGamesLibWebPlatformDataOptionsWeb';

/**
 * 
 * @export
 * @interface FxGamesLibWebPlatformDataOptions
 */
export interface FxGamesLibWebPlatformDataOptions {
    /**
     * 
     * @type {FxGamesLibWebPlatformDataOptionsFacebookGames}
     * @memberof FxGamesLibWebPlatformDataOptions
     */
    FacebookGames?: FxGamesLibWebPlatformDataOptionsFacebookGames | null;
    /**
     * 
     * @type {FxGamesLibWebPlatformDataOptionsFxId}
     * @memberof FxGamesLibWebPlatformDataOptions
     */
    FxId?: FxGamesLibWebPlatformDataOptionsFxId | null;
    /**
     * 
     * @type {FxGamesLibWebPlatformDataOptionsVkontaktePlay}
     * @memberof FxGamesLibWebPlatformDataOptions
     */
    VkontaktePlay?: FxGamesLibWebPlatformDataOptionsVkontaktePlay | null;
    /**
     * 
     * @type {FxGamesLibWebPlatformDataOptionsVkontakteCom}
     * @memberof FxGamesLibWebPlatformDataOptions
     */
    VkontakteCom?: FxGamesLibWebPlatformDataOptionsVkontakteCom | null;
    /**
     * 
     * @type {FxGamesLibWebPlatformDataOptionsOdnoklassniki}
     * @memberof FxGamesLibWebPlatformDataOptions
     */
    Odnoklassniki?: FxGamesLibWebPlatformDataOptionsOdnoklassniki | null;
    /**
     * 
     * @type {FxGamesLibWebPlatformDataOptionsAndroid}
     * @memberof FxGamesLibWebPlatformDataOptions
     */
    Android?: FxGamesLibWebPlatformDataOptionsAndroid | null;
    /**
     * 
     * @type {FxGamesLibWebPlatformDataOptionsIos}
     * @memberof FxGamesLibWebPlatformDataOptions
     */
    Ios?: FxGamesLibWebPlatformDataOptionsIos | null;
    /**
     * 
     * @type {FxGamesLibWebPlatformDataOptionsWindows}
     * @memberof FxGamesLibWebPlatformDataOptions
     */
    Windows?: FxGamesLibWebPlatformDataOptionsWindows | null;
    /**
     * 
     * @type {FxGamesLibWebPlatformDataOptionsWeb}
     * @memberof FxGamesLibWebPlatformDataOptions
     */
    Web?: FxGamesLibWebPlatformDataOptionsWeb | null;
    /**
     * 
     * @type {FxGamesLibWebPlatformDataOptionsSteam}
     * @memberof FxGamesLibWebPlatformDataOptions
     */
    Steam?: FxGamesLibWebPlatformDataOptionsSteam | null;
}

/**
 * Check if a given object implements the FxGamesLibWebPlatformDataOptions interface.
 */
export function instanceOfFxGamesLibWebPlatformDataOptions(value: object): value is FxGamesLibWebPlatformDataOptions {
    return true;
}

export function FxGamesLibWebPlatformDataOptionsFromJSON(json: any): FxGamesLibWebPlatformDataOptions {
    return FxGamesLibWebPlatformDataOptionsFromJSONTyped(json, false);
}

export function FxGamesLibWebPlatformDataOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxGamesLibWebPlatformDataOptions {
    if (json == null) {
        return json;
    }
    return {
        
        'FacebookGames': json['FacebookGames'] == null ? undefined : FxGamesLibWebPlatformDataOptionsFacebookGamesFromJSON(json['FacebookGames']),
        'FxId': json['FxId'] == null ? undefined : FxGamesLibWebPlatformDataOptionsFxIdFromJSON(json['FxId']),
        'VkontaktePlay': json['VkontaktePlay'] == null ? undefined : FxGamesLibWebPlatformDataOptionsVkontaktePlayFromJSON(json['VkontaktePlay']),
        'VkontakteCom': json['VkontakteCom'] == null ? undefined : FxGamesLibWebPlatformDataOptionsVkontakteComFromJSON(json['VkontakteCom']),
        'Odnoklassniki': json['Odnoklassniki'] == null ? undefined : FxGamesLibWebPlatformDataOptionsOdnoklassnikiFromJSON(json['Odnoklassniki']),
        'Android': json['Android'] == null ? undefined : FxGamesLibWebPlatformDataOptionsAndroidFromJSON(json['Android']),
        'Ios': json['Ios'] == null ? undefined : FxGamesLibWebPlatformDataOptionsIosFromJSON(json['Ios']),
        'Windows': json['Windows'] == null ? undefined : FxGamesLibWebPlatformDataOptionsWindowsFromJSON(json['Windows']),
        'Web': json['Web'] == null ? undefined : FxGamesLibWebPlatformDataOptionsWebFromJSON(json['Web']),
        'Steam': json['Steam'] == null ? undefined : FxGamesLibWebPlatformDataOptionsSteamFromJSON(json['Steam']),
    };
}

export function FxGamesLibWebPlatformDataOptionsToJSON(json: any): FxGamesLibWebPlatformDataOptions {
    return FxGamesLibWebPlatformDataOptionsToJSONTyped(json, false);
}

export function FxGamesLibWebPlatformDataOptionsToJSONTyped(value?: FxGamesLibWebPlatformDataOptions | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'FacebookGames': FxGamesLibWebPlatformDataOptionsFacebookGamesToJSON(value['FacebookGames']),
        'FxId': FxGamesLibWebPlatformDataOptionsFxIdToJSON(value['FxId']),
        'VkontaktePlay': FxGamesLibWebPlatformDataOptionsVkontaktePlayToJSON(value['VkontaktePlay']),
        'VkontakteCom': FxGamesLibWebPlatformDataOptionsVkontakteComToJSON(value['VkontakteCom']),
        'Odnoklassniki': FxGamesLibWebPlatformDataOptionsOdnoklassnikiToJSON(value['Odnoklassniki']),
        'Android': FxGamesLibWebPlatformDataOptionsAndroidToJSON(value['Android']),
        'Ios': FxGamesLibWebPlatformDataOptionsIosToJSON(value['Ios']),
        'Windows': FxGamesLibWebPlatformDataOptionsWindowsToJSON(value['Windows']),
        'Web': FxGamesLibWebPlatformDataOptionsWebToJSON(value['Web']),
        'Steam': FxGamesLibWebPlatformDataOptionsSteamToJSON(value['Steam']),
    };
}

