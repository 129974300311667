/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FxIdDomainEntitiesDocumentsDocumentType } from './FxIdDomainEntitiesDocumentsDocumentType';
import {
    FxIdDomainEntitiesDocumentsDocumentTypeFromJSON,
    FxIdDomainEntitiesDocumentsDocumentTypeFromJSONTyped,
    FxIdDomainEntitiesDocumentsDocumentTypeToJSON,
    FxIdDomainEntitiesDocumentsDocumentTypeToJSONTyped,
} from './FxIdDomainEntitiesDocumentsDocumentType';
import type { FxIdDomainEntitiesDocumentsDocumentAccountAccept } from './FxIdDomainEntitiesDocumentsDocumentAccountAccept';
import {
    FxIdDomainEntitiesDocumentsDocumentAccountAcceptFromJSON,
    FxIdDomainEntitiesDocumentsDocumentAccountAcceptFromJSONTyped,
    FxIdDomainEntitiesDocumentsDocumentAccountAcceptToJSON,
    FxIdDomainEntitiesDocumentsDocumentAccountAcceptToJSONTyped,
} from './FxIdDomainEntitiesDocumentsDocumentAccountAccept';
import type { FxIdDomainEntitiesDocumentsGameDocumentVersion } from './FxIdDomainEntitiesDocumentsGameDocumentVersion';
import {
    FxIdDomainEntitiesDocumentsGameDocumentVersionFromJSON,
    FxIdDomainEntitiesDocumentsGameDocumentVersionFromJSONTyped,
    FxIdDomainEntitiesDocumentsGameDocumentVersionToJSON,
    FxIdDomainEntitiesDocumentsGameDocumentVersionToJSONTyped,
} from './FxIdDomainEntitiesDocumentsGameDocumentVersion';

/**
 * 
 * @export
 * @interface FxIdDomainEntitiesDocumentsDocument
 */
export interface FxIdDomainEntitiesDocumentsDocument {
    /**
     * 
     * @type {number}
     * @memberof FxIdDomainEntitiesDocumentsDocument
     */
    Id: number;
    /**
     * 
     * @type {FxIdDomainEntitiesDocumentsDocumentType}
     * @memberof FxIdDomainEntitiesDocumentsDocument
     */
    Type: FxIdDomainEntitiesDocumentsDocumentType;
    /**
     * 
     * @type {Date}
     * @memberof FxIdDomainEntitiesDocumentsDocument
     */
    CreatedAt: Date;
    /**
     * 
     * @type {boolean}
     * @memberof FxIdDomainEntitiesDocumentsDocument
     */
    IsActive: boolean;
    /**
     * 
     * @type {number}
     * @memberof FxIdDomainEntitiesDocumentsDocument
     */
    GameId: number;
    /**
     * 
     * @type {Array<FxIdDomainEntitiesDocumentsGameDocumentVersion>}
     * @memberof FxIdDomainEntitiesDocumentsDocument
     */
    Versions: Array<FxIdDomainEntitiesDocumentsGameDocumentVersion>;
    /**
     * 
     * @type {Array<FxIdDomainEntitiesDocumentsDocumentAccountAccept>}
     * @memberof FxIdDomainEntitiesDocumentsDocument
     */
    Accepts: Array<FxIdDomainEntitiesDocumentsDocumentAccountAccept>;
}

/**
 * Check if a given object implements the FxIdDomainEntitiesDocumentsDocument interface.
 */
export function instanceOfFxIdDomainEntitiesDocumentsDocument(value: object): value is FxIdDomainEntitiesDocumentsDocument {
    if (!('Id' in value) || value['Id'] === undefined) return false;
    if (!('Type' in value) || value['Type'] === undefined) return false;
    if (!('CreatedAt' in value) || value['CreatedAt'] === undefined) return false;
    if (!('IsActive' in value) || value['IsActive'] === undefined) return false;
    if (!('GameId' in value) || value['GameId'] === undefined) return false;
    if (!('Versions' in value) || value['Versions'] === undefined) return false;
    if (!('Accepts' in value) || value['Accepts'] === undefined) return false;
    return true;
}

export function FxIdDomainEntitiesDocumentsDocumentFromJSON(json: any): FxIdDomainEntitiesDocumentsDocument {
    return FxIdDomainEntitiesDocumentsDocumentFromJSONTyped(json, false);
}

export function FxIdDomainEntitiesDocumentsDocumentFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainEntitiesDocumentsDocument {
    if (json == null) {
        return json;
    }
    return {
        
        'Id': json['Id'],
        'Type': FxIdDomainEntitiesDocumentsDocumentTypeFromJSON(json['Type']),
        'CreatedAt': (new Date(json['CreatedAt'])),
        'IsActive': json['IsActive'],
        'GameId': json['GameId'],
        'Versions': ((json['Versions'] as Array<any>).map(FxIdDomainEntitiesDocumentsGameDocumentVersionFromJSON)),
        'Accepts': ((json['Accepts'] as Array<any>).map(FxIdDomainEntitiesDocumentsDocumentAccountAcceptFromJSON)),
    };
}

export function FxIdDomainEntitiesDocumentsDocumentToJSON(json: any): FxIdDomainEntitiesDocumentsDocument {
    return FxIdDomainEntitiesDocumentsDocumentToJSONTyped(json, false);
}

export function FxIdDomainEntitiesDocumentsDocumentToJSONTyped(value?: FxIdDomainEntitiesDocumentsDocument | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'Id': value['Id'],
        'Type': FxIdDomainEntitiesDocumentsDocumentTypeToJSON(value['Type']),
        'CreatedAt': ((value['CreatedAt']).toISOString()),
        'IsActive': value['IsActive'],
        'GameId': value['GameId'],
        'Versions': ((value['Versions'] as Array<any>).map(FxIdDomainEntitiesDocumentsGameDocumentVersionToJSON)),
        'Accepts': ((value['Accepts'] as Array<any>).map(FxIdDomainEntitiesDocumentsDocumentAccountAcceptToJSON)),
    };
}

