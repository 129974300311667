/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLeaderboardConfigDescriptionMarkdown } from './FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLeaderboardConfigDescriptionMarkdown';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLeaderboardConfigDescriptionMarkdownFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLeaderboardConfigDescriptionMarkdownFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLeaderboardConfigDescriptionMarkdownToJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLeaderboardConfigDescriptionMarkdownToJSONTyped,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLeaderboardConfigDescriptionMarkdown';
import type { FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLeaderboardConfigName } from './FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLeaderboardConfigName';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLeaderboardConfigNameFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLeaderboardConfigNameFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLeaderboardConfigNameToJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLeaderboardConfigNameToJSONTyped,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLeaderboardConfigName';

/**
 * 
 * @export
 * @interface FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLeaderboardConfig
 */
export interface FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLeaderboardConfig {
    /**
     * 
     * @type {FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLeaderboardConfigName}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLeaderboardConfig
     */
    Name: FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLeaderboardConfigName;
    /**
     * Запрос для получения данных лидерборда
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLeaderboardConfig
     */
    Query: string;
    /**
     * 
     * @type {FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLeaderboardConfigDescriptionMarkdown}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLeaderboardConfig
     */
    DescriptionMarkdown: FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLeaderboardConfigDescriptionMarkdown;
    /**
     * Запрос для предварительного просмотра данных лидерборда
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLeaderboardConfig
     */
    QueryPreview: string;
    /**
     * URL изображения для лидерборда
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLeaderboardConfig
     */
    Image: string;
}

/**
 * Check if a given object implements the FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLeaderboardConfig interface.
 */
export function instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLeaderboardConfig(value: object): value is FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLeaderboardConfig {
    if (!('Name' in value) || value['Name'] === undefined) return false;
    if (!('Query' in value) || value['Query'] === undefined) return false;
    if (!('DescriptionMarkdown' in value) || value['DescriptionMarkdown'] === undefined) return false;
    if (!('QueryPreview' in value) || value['QueryPreview'] === undefined) return false;
    if (!('Image' in value) || value['Image'] === undefined) return false;
    return true;
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLeaderboardConfigFromJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLeaderboardConfig {
    return FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLeaderboardConfigFromJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLeaderboardConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLeaderboardConfig {
    if (json == null) {
        return json;
    }
    return {
        
        'Name': FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLeaderboardConfigNameFromJSON(json['Name']),
        'Query': json['Query'],
        'DescriptionMarkdown': FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLeaderboardConfigDescriptionMarkdownFromJSON(json['DescriptionMarkdown']),
        'QueryPreview': json['QueryPreview'],
        'Image': json['Image'],
    };
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLeaderboardConfigToJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLeaderboardConfig {
    return FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLeaderboardConfigToJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLeaderboardConfigToJSONTyped(value?: FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLeaderboardConfig | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'Name': FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLeaderboardConfigNameToJSON(value['Name']),
        'Query': value['Query'],
        'DescriptionMarkdown': FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLeaderboardConfigDescriptionMarkdownToJSON(value['DescriptionMarkdown']),
        'QueryPreview': value['QueryPreview'],
        'Image': value['Image'],
    };
}

