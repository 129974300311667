/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdDomainEntitiesDocumentsDocumentType
 */
export interface FxIdDomainEntitiesDocumentsDocumentType {
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainEntitiesDocumentsDocumentType
     */
    Value: string;
    /**
     * 
     * @type {boolean}
     * @memberof FxIdDomainEntitiesDocumentsDocumentType
     */
    NeedUserAcceptance: boolean;
}

/**
 * Check if a given object implements the FxIdDomainEntitiesDocumentsDocumentType interface.
 */
export function instanceOfFxIdDomainEntitiesDocumentsDocumentType(value: object): value is FxIdDomainEntitiesDocumentsDocumentType {
    if (!('Value' in value) || value['Value'] === undefined) return false;
    if (!('NeedUserAcceptance' in value) || value['NeedUserAcceptance'] === undefined) return false;
    return true;
}

export function FxIdDomainEntitiesDocumentsDocumentTypeFromJSON(json: any): FxIdDomainEntitiesDocumentsDocumentType {
    return FxIdDomainEntitiesDocumentsDocumentTypeFromJSONTyped(json, false);
}

export function FxIdDomainEntitiesDocumentsDocumentTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainEntitiesDocumentsDocumentType {
    if (json == null) {
        return json;
    }
    return {
        
        'Value': json['Value'],
        'NeedUserAcceptance': json['NeedUserAcceptance'],
    };
}

export function FxIdDomainEntitiesDocumentsDocumentTypeToJSON(json: any): FxIdDomainEntitiesDocumentsDocumentType {
    return FxIdDomainEntitiesDocumentsDocumentTypeToJSONTyped(json, false);
}

export function FxIdDomainEntitiesDocumentsDocumentTypeToJSONTyped(value?: FxIdDomainEntitiesDocumentsDocumentType | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'Value': value['Value'],
        'NeedUserAcceptance': value['NeedUserAcceptance'],
    };
}

