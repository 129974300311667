import { FxGamesLibWebPublicDataBase, instanceOfFxGamesLibWebPublicDataBase } from "../../Api/gen";

export const getPublicConfigFromMeta = (meta?: Record<string, unknown>): FxGamesLibWebPublicDataBase | undefined => {
	if (
		typeof meta === "object" &&
		meta !== null &&
		"publicConfig" in meta &&
		typeof meta.publicConfig === "object" &&
		meta.publicConfig !== null &&
		instanceOfFxGamesLibWebPublicDataBase(meta.publicConfig)
	)
		return meta.publicConfig as FxGamesLibWebPublicDataBase;
};
