/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdDomainAnalyticsTsAnalyticsEvent
 */
export interface FxIdDomainAnalyticsTsAnalyticsEvent {
    /**
     * 
     * @type {number}
     * @memberof FxIdDomainAnalyticsTsAnalyticsEvent
     */
    UserId: number;
    /**
     * 
     * @type {number}
     * @memberof FxIdDomainAnalyticsTsAnalyticsEvent
     */
    CreatedAt: number;
    /**
     * 
     * @type {number}
     * @memberof FxIdDomainAnalyticsTsAnalyticsEvent
     */
    ServerCreatedAt: number;
    /**
     * 
     * @type {number}
     * @memberof FxIdDomainAnalyticsTsAnalyticsEvent
     */
    Build: number;
    /**
     * 
     * @type {number}
     * @memberof FxIdDomainAnalyticsTsAnalyticsEvent
     */
    Platform: number;
    /**
     * 
     * @type {number}
     * @memberof FxIdDomainAnalyticsTsAnalyticsEvent
     */
    EventId: number;
    /**
     * 
     * @type {number}
     * @memberof FxIdDomainAnalyticsTsAnalyticsEvent
     */
    I1?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FxIdDomainAnalyticsTsAnalyticsEvent
     */
    I2?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FxIdDomainAnalyticsTsAnalyticsEvent
     */
    I3?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FxIdDomainAnalyticsTsAnalyticsEvent
     */
    I4?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FxIdDomainAnalyticsTsAnalyticsEvent
     */
    I5?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FxIdDomainAnalyticsTsAnalyticsEvent
     */
    I6?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FxIdDomainAnalyticsTsAnalyticsEvent
     */
    I7?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FxIdDomainAnalyticsTsAnalyticsEvent
     */
    I8?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FxIdDomainAnalyticsTsAnalyticsEvent
     */
    I9?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FxIdDomainAnalyticsTsAnalyticsEvent
     */
    I10?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FxIdDomainAnalyticsTsAnalyticsEvent
     */
    F1?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FxIdDomainAnalyticsTsAnalyticsEvent
     */
    F2?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FxIdDomainAnalyticsTsAnalyticsEvent
     */
    F3?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FxIdDomainAnalyticsTsAnalyticsEvent
     */
    F4?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FxIdDomainAnalyticsTsAnalyticsEvent
     */
    F5?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FxIdDomainAnalyticsTsAnalyticsEvent
     */
    F6?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FxIdDomainAnalyticsTsAnalyticsEvent
     */
    F7?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FxIdDomainAnalyticsTsAnalyticsEvent
     */
    F8?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FxIdDomainAnalyticsTsAnalyticsEvent
     */
    F9?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FxIdDomainAnalyticsTsAnalyticsEvent
     */
    F10?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainAnalyticsTsAnalyticsEvent
     */
    S1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainAnalyticsTsAnalyticsEvent
     */
    S2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainAnalyticsTsAnalyticsEvent
     */
    S3?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainAnalyticsTsAnalyticsEvent
     */
    S4?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainAnalyticsTsAnalyticsEvent
     */
    S5?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainAnalyticsTsAnalyticsEvent
     */
    S6?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainAnalyticsTsAnalyticsEvent
     */
    S7?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainAnalyticsTsAnalyticsEvent
     */
    S8?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainAnalyticsTsAnalyticsEvent
     */
    S9?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainAnalyticsTsAnalyticsEvent
     */
    S10?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainAnalyticsTsAnalyticsEvent
     */
    S11?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainAnalyticsTsAnalyticsEvent
     */
    S12?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainAnalyticsTsAnalyticsEvent
     */
    S13?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainAnalyticsTsAnalyticsEvent
     */
    S14?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainAnalyticsTsAnalyticsEvent
     */
    S15?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainAnalyticsTsAnalyticsEvent
     */
    J1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainAnalyticsTsAnalyticsEvent
     */
    J2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainAnalyticsTsAnalyticsEvent
     */
    J3?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainAnalyticsTsAnalyticsEvent
     */
    J4?: string | null;
}

/**
 * Check if a given object implements the FxIdDomainAnalyticsTsAnalyticsEvent interface.
 */
export function instanceOfFxIdDomainAnalyticsTsAnalyticsEvent(value: object): value is FxIdDomainAnalyticsTsAnalyticsEvent {
    if (!('UserId' in value) || value['UserId'] === undefined) return false;
    if (!('CreatedAt' in value) || value['CreatedAt'] === undefined) return false;
    if (!('ServerCreatedAt' in value) || value['ServerCreatedAt'] === undefined) return false;
    if (!('Build' in value) || value['Build'] === undefined) return false;
    if (!('Platform' in value) || value['Platform'] === undefined) return false;
    if (!('EventId' in value) || value['EventId'] === undefined) return false;
    return true;
}

export function FxIdDomainAnalyticsTsAnalyticsEventFromJSON(json: any): FxIdDomainAnalyticsTsAnalyticsEvent {
    return FxIdDomainAnalyticsTsAnalyticsEventFromJSONTyped(json, false);
}

export function FxIdDomainAnalyticsTsAnalyticsEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainAnalyticsTsAnalyticsEvent {
    if (json == null) {
        return json;
    }
    return {
        
        'UserId': json['UserId'],
        'CreatedAt': json['CreatedAt'],
        'ServerCreatedAt': json['ServerCreatedAt'],
        'Build': json['Build'],
        'Platform': json['Platform'],
        'EventId': json['EventId'],
        'I1': json['I1'] == null ? undefined : json['I1'],
        'I2': json['I2'] == null ? undefined : json['I2'],
        'I3': json['I3'] == null ? undefined : json['I3'],
        'I4': json['I4'] == null ? undefined : json['I4'],
        'I5': json['I5'] == null ? undefined : json['I5'],
        'I6': json['I6'] == null ? undefined : json['I6'],
        'I7': json['I7'] == null ? undefined : json['I7'],
        'I8': json['I8'] == null ? undefined : json['I8'],
        'I9': json['I9'] == null ? undefined : json['I9'],
        'I10': json['I10'] == null ? undefined : json['I10'],
        'F1': json['F1'] == null ? undefined : json['F1'],
        'F2': json['F2'] == null ? undefined : json['F2'],
        'F3': json['F3'] == null ? undefined : json['F3'],
        'F4': json['F4'] == null ? undefined : json['F4'],
        'F5': json['F5'] == null ? undefined : json['F5'],
        'F6': json['F6'] == null ? undefined : json['F6'],
        'F7': json['F7'] == null ? undefined : json['F7'],
        'F8': json['F8'] == null ? undefined : json['F8'],
        'F9': json['F9'] == null ? undefined : json['F9'],
        'F10': json['F10'] == null ? undefined : json['F10'],
        'S1': json['S1'] == null ? undefined : json['S1'],
        'S2': json['S2'] == null ? undefined : json['S2'],
        'S3': json['S3'] == null ? undefined : json['S3'],
        'S4': json['S4'] == null ? undefined : json['S4'],
        'S5': json['S5'] == null ? undefined : json['S5'],
        'S6': json['S6'] == null ? undefined : json['S6'],
        'S7': json['S7'] == null ? undefined : json['S7'],
        'S8': json['S8'] == null ? undefined : json['S8'],
        'S9': json['S9'] == null ? undefined : json['S9'],
        'S10': json['S10'] == null ? undefined : json['S10'],
        'S11': json['S11'] == null ? undefined : json['S11'],
        'S12': json['S12'] == null ? undefined : json['S12'],
        'S13': json['S13'] == null ? undefined : json['S13'],
        'S14': json['S14'] == null ? undefined : json['S14'],
        'S15': json['S15'] == null ? undefined : json['S15'],
        'J1': json['J1'] == null ? undefined : json['J1'],
        'J2': json['J2'] == null ? undefined : json['J2'],
        'J3': json['J3'] == null ? undefined : json['J3'],
        'J4': json['J4'] == null ? undefined : json['J4'],
    };
}

export function FxIdDomainAnalyticsTsAnalyticsEventToJSON(json: any): FxIdDomainAnalyticsTsAnalyticsEvent {
    return FxIdDomainAnalyticsTsAnalyticsEventToJSONTyped(json, false);
}

export function FxIdDomainAnalyticsTsAnalyticsEventToJSONTyped(value?: FxIdDomainAnalyticsTsAnalyticsEvent | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'UserId': value['UserId'],
        'CreatedAt': value['CreatedAt'],
        'ServerCreatedAt': value['ServerCreatedAt'],
        'Build': value['Build'],
        'Platform': value['Platform'],
        'EventId': value['EventId'],
        'I1': value['I1'],
        'I2': value['I2'],
        'I3': value['I3'],
        'I4': value['I4'],
        'I5': value['I5'],
        'I6': value['I6'],
        'I7': value['I7'],
        'I8': value['I8'],
        'I9': value['I9'],
        'I10': value['I10'],
        'F1': value['F1'],
        'F2': value['F2'],
        'F3': value['F3'],
        'F4': value['F4'],
        'F5': value['F5'],
        'F6': value['F6'],
        'F7': value['F7'],
        'F8': value['F8'],
        'F9': value['F9'],
        'F10': value['F10'],
        'S1': value['S1'],
        'S2': value['S2'],
        'S3': value['S3'],
        'S4': value['S4'],
        'S5': value['S5'],
        'S6': value['S6'],
        'S7': value['S7'],
        'S8': value['S8'],
        'S9': value['S9'],
        'S10': value['S10'],
        'S11': value['S11'],
        'S12': value['S12'],
        'S13': value['S13'],
        'S14': value['S14'],
        'S15': value['S15'],
        'J1': value['J1'],
        'J2': value['J2'],
        'J3': value['J3'],
        'J4': value['J4'],
    };
}

