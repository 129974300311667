/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FxIdDomainEntitiesDocumentsDocumentAccountAccept } from './FxIdDomainEntitiesDocumentsDocumentAccountAccept';
import {
    FxIdDomainEntitiesDocumentsDocumentAccountAcceptFromJSON,
    FxIdDomainEntitiesDocumentsDocumentAccountAcceptFromJSONTyped,
    FxIdDomainEntitiesDocumentsDocumentAccountAcceptToJSON,
    FxIdDomainEntitiesDocumentsDocumentAccountAcceptToJSONTyped,
} from './FxIdDomainEntitiesDocumentsDocumentAccountAccept';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesDocumentsGetAcceptedDocumentsGetAcceptedDocumentsResponse
 */
export interface FxIdWebFeaturesDocumentsGetAcceptedDocumentsGetAcceptedDocumentsResponse {
    /**
     * 
     * @type {Array<FxIdDomainEntitiesDocumentsDocumentAccountAccept>}
     * @memberof FxIdWebFeaturesDocumentsGetAcceptedDocumentsGetAcceptedDocumentsResponse
     */
    GameDocumentAccountAccepts: Array<FxIdDomainEntitiesDocumentsDocumentAccountAccept>;
}

/**
 * Check if a given object implements the FxIdWebFeaturesDocumentsGetAcceptedDocumentsGetAcceptedDocumentsResponse interface.
 */
export function instanceOfFxIdWebFeaturesDocumentsGetAcceptedDocumentsGetAcceptedDocumentsResponse(value: object): value is FxIdWebFeaturesDocumentsGetAcceptedDocumentsGetAcceptedDocumentsResponse {
    if (!('GameDocumentAccountAccepts' in value) || value['GameDocumentAccountAccepts'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesDocumentsGetAcceptedDocumentsGetAcceptedDocumentsResponseFromJSON(json: any): FxIdWebFeaturesDocumentsGetAcceptedDocumentsGetAcceptedDocumentsResponse {
    return FxIdWebFeaturesDocumentsGetAcceptedDocumentsGetAcceptedDocumentsResponseFromJSONTyped(json, false);
}

export function FxIdWebFeaturesDocumentsGetAcceptedDocumentsGetAcceptedDocumentsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesDocumentsGetAcceptedDocumentsGetAcceptedDocumentsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'GameDocumentAccountAccepts': ((json['GameDocumentAccountAccepts'] as Array<any>).map(FxIdDomainEntitiesDocumentsDocumentAccountAcceptFromJSON)),
    };
}

export function FxIdWebFeaturesDocumentsGetAcceptedDocumentsGetAcceptedDocumentsResponseToJSON(json: any): FxIdWebFeaturesDocumentsGetAcceptedDocumentsGetAcceptedDocumentsResponse {
    return FxIdWebFeaturesDocumentsGetAcceptedDocumentsGetAcceptedDocumentsResponseToJSONTyped(json, false);
}

export function FxIdWebFeaturesDocumentsGetAcceptedDocumentsGetAcceptedDocumentsResponseToJSONTyped(value?: FxIdWebFeaturesDocumentsGetAcceptedDocumentsGetAcceptedDocumentsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'GameDocumentAccountAccepts': ((value['GameDocumentAccountAccepts'] as Array<any>).map(FxIdDomainEntitiesDocumentsDocumentAccountAcceptToJSON)),
    };
}

