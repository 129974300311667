/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FxGamesLibWebPublicDataBaseStoreOptions } from './FxGamesLibWebPublicDataBaseStoreOptions';
import {
    FxGamesLibWebPublicDataBaseStoreOptionsFromJSON,
    FxGamesLibWebPublicDataBaseStoreOptionsFromJSONTyped,
    FxGamesLibWebPublicDataBaseStoreOptionsToJSON,
    FxGamesLibWebPublicDataBaseStoreOptionsToJSONTyped,
} from './FxGamesLibWebPublicDataBaseStoreOptions';
import type { FxGamesLibWebPublicDataBaseSupport } from './FxGamesLibWebPublicDataBaseSupport';
import {
    FxGamesLibWebPublicDataBaseSupportFromJSON,
    FxGamesLibWebPublicDataBaseSupportFromJSONTyped,
    FxGamesLibWebPublicDataBaseSupportToJSON,
    FxGamesLibWebPublicDataBaseSupportToJSONTyped,
} from './FxGamesLibWebPublicDataBaseSupport';
import type { FxIdDomainSettingsUrlOpenMethod } from './FxIdDomainSettingsUrlOpenMethod';
import {
    FxIdDomainSettingsUrlOpenMethodFromJSON,
    FxIdDomainSettingsUrlOpenMethodFromJSONTyped,
    FxIdDomainSettingsUrlOpenMethodToJSON,
    FxIdDomainSettingsUrlOpenMethodToJSONTyped,
} from './FxIdDomainSettingsUrlOpenMethod';
import type { FxGamesLibWebPlatformDataOptions } from './FxGamesLibWebPlatformDataOptions';
import {
    FxGamesLibWebPlatformDataOptionsFromJSON,
    FxGamesLibWebPlatformDataOptionsFromJSONTyped,
    FxGamesLibWebPlatformDataOptionsToJSON,
    FxGamesLibWebPlatformDataOptionsToJSONTyped,
} from './FxGamesLibWebPlatformDataOptions';
import type { FxGamesLibWebPublicDataBasePresentation } from './FxGamesLibWebPublicDataBasePresentation';
import {
    FxGamesLibWebPublicDataBasePresentationFromJSON,
    FxGamesLibWebPublicDataBasePresentationFromJSONTyped,
    FxGamesLibWebPublicDataBasePresentationToJSON,
    FxGamesLibWebPublicDataBasePresentationToJSONTyped,
} from './FxGamesLibWebPublicDataBasePresentation';
import type { FxGamesLibWebPublicDataBaseStatistics } from './FxGamesLibWebPublicDataBaseStatistics';
import {
    FxGamesLibWebPublicDataBaseStatisticsFromJSON,
    FxGamesLibWebPublicDataBaseStatisticsFromJSONTyped,
    FxGamesLibWebPublicDataBaseStatisticsToJSON,
    FxGamesLibWebPublicDataBaseStatisticsToJSONTyped,
} from './FxGamesLibWebPublicDataBaseStatistics';
import type { FxGamesLibWebPublicDataBaseFxLauncherOptions } from './FxGamesLibWebPublicDataBaseFxLauncherOptions';
import {
    FxGamesLibWebPublicDataBaseFxLauncherOptionsFromJSON,
    FxGamesLibWebPublicDataBaseFxLauncherOptionsFromJSONTyped,
    FxGamesLibWebPublicDataBaseFxLauncherOptionsToJSON,
    FxGamesLibWebPublicDataBaseFxLauncherOptionsToJSONTyped,
} from './FxGamesLibWebPublicDataBaseFxLauncherOptions';
import type { FxIdDomainSettingsEGameOrientation } from './FxIdDomainSettingsEGameOrientation';
import {
    FxIdDomainSettingsEGameOrientationFromJSON,
    FxIdDomainSettingsEGameOrientationFromJSONTyped,
    FxIdDomainSettingsEGameOrientationToJSON,
    FxIdDomainSettingsEGameOrientationToJSONTyped,
} from './FxIdDomainSettingsEGameOrientation';
import type { FxIdDomainSettingsPublicWebClientConfigOptions } from './FxIdDomainSettingsPublicWebClientConfigOptions';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsToJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsToJSONTyped,
} from './FxIdDomainSettingsPublicWebClientConfigOptions';

/**
 * 
 * @export
 * @interface FxGamesLibWebPublicDataBase
 */
export interface FxGamesLibWebPublicDataBase {
    /**
     * 
     * @type {FxGamesLibWebPlatformDataOptions}
     * @memberof FxGamesLibWebPublicDataBase
     */
    PlatformData: FxGamesLibWebPlatformDataOptions;
    /**
     * 
     * @type {FxGamesLibWebPublicDataBaseStatistics}
     * @memberof FxGamesLibWebPublicDataBase
     */
    Statistics?: FxGamesLibWebPublicDataBaseStatistics | null;
    /**
     * 
     * @type {FxIdDomainSettingsEGameOrientation}
     * @memberof FxGamesLibWebPublicDataBase
     */
    GameOrientation: FxIdDomainSettingsEGameOrientation;
    /**
     * 
     * @type {FxIdDomainSettingsEGameOrientation}
     * @memberof FxGamesLibWebPublicDataBase
     */
    MobileGameOrientation: FxIdDomainSettingsEGameOrientation;
    /**
     * 
     * @type {FxGamesLibWebPublicDataBasePresentation}
     * @memberof FxGamesLibWebPublicDataBase
     */
    Presentation?: FxGamesLibWebPublicDataBasePresentation | null;
    /**
     * 
     * @type {FxGamesLibWebPublicDataBasePresentation}
     * @memberof FxGamesLibWebPublicDataBase
     */
    MobilePresentation?: FxGamesLibWebPublicDataBasePresentation | null;
    /**
     * 
     * @type {FxGamesLibWebPublicDataBasePresentation}
     * @memberof FxGamesLibWebPublicDataBase
     */
    ViewportPresentation?: FxGamesLibWebPublicDataBasePresentation | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FxGamesLibWebPublicDataBase
     */
    SupportedLanguages: Array<string>;
    /**
     * 
     * @type {FxGamesLibWebPublicDataBaseSupport}
     * @memberof FxGamesLibWebPublicDataBase
     */
    Support?: FxGamesLibWebPublicDataBaseSupport | null;
    /**
     * 
     * @type {FxGamesLibWebPublicDataBaseStoreOptions}
     * @memberof FxGamesLibWebPublicDataBase
     */
    Store: FxGamesLibWebPublicDataBaseStoreOptions;
    /**
     * 
     * @type {FxGamesLibWebPublicDataBaseFxLauncherOptions}
     * @memberof FxGamesLibWebPublicDataBase
     */
    FxLauncherOptions?: FxGamesLibWebPublicDataBaseFxLauncherOptions | null;
    /**
     * 
     * @type {string}
     * @memberof FxGamesLibWebPublicDataBase
     */
    CompanyName: string;
    /**
     * 
     * @type {string}
     * @memberof FxGamesLibWebPublicDataBase
     */
    TosUrl: string;
    /**
     * 
     * @type {FxIdDomainSettingsUrlOpenMethod}
     * @memberof FxGamesLibWebPublicDataBase
     */
    TosUrlOpenMethod: FxIdDomainSettingsUrlOpenMethod;
    /**
     * 
     * @type {string}
     * @memberof FxGamesLibWebPublicDataBase
     */
    PpUrl: string;
    /**
     * 
     * @type {FxIdDomainSettingsUrlOpenMethod}
     * @memberof FxGamesLibWebPublicDataBase
     */
    PpUrlOpenMethod: FxIdDomainSettingsUrlOpenMethod;
    /**
     * 
     * @type {FxIdDomainSettingsPublicWebClientConfigOptions}
     * @memberof FxGamesLibWebPublicDataBase
     */
    PublicWebClientConfig: FxIdDomainSettingsPublicWebClientConfigOptions;
    /**
     * 
     * @type {string}
     * @memberof FxGamesLibWebPublicDataBase
     */
    GameSystemName: string;
    /**
     * 
     * @type {boolean}
     * @memberof FxGamesLibWebPublicDataBase
     */
    NamespacedProductsL10N: boolean;
}



/**
 * Check if a given object implements the FxGamesLibWebPublicDataBase interface.
 */
export function instanceOfFxGamesLibWebPublicDataBase(value: object): value is FxGamesLibWebPublicDataBase {
    if (!('PlatformData' in value) || value['PlatformData'] === undefined) return false;
    if (!('GameOrientation' in value) || value['GameOrientation'] === undefined) return false;
    if (!('MobileGameOrientation' in value) || value['MobileGameOrientation'] === undefined) return false;
    if (!('SupportedLanguages' in value) || value['SupportedLanguages'] === undefined) return false;
    if (!('Store' in value) || value['Store'] === undefined) return false;
    if (!('CompanyName' in value) || value['CompanyName'] === undefined) return false;
    if (!('TosUrl' in value) || value['TosUrl'] === undefined) return false;
    if (!('TosUrlOpenMethod' in value) || value['TosUrlOpenMethod'] === undefined) return false;
    if (!('PpUrl' in value) || value['PpUrl'] === undefined) return false;
    if (!('PpUrlOpenMethod' in value) || value['PpUrlOpenMethod'] === undefined) return false;
    if (!('PublicWebClientConfig' in value) || value['PublicWebClientConfig'] === undefined) return false;
    if (!('GameSystemName' in value) || value['GameSystemName'] === undefined) return false;
    if (!('NamespacedProductsL10N' in value) || value['NamespacedProductsL10N'] === undefined) return false;
    return true;
}

export function FxGamesLibWebPublicDataBaseFromJSON(json: any): FxGamesLibWebPublicDataBase {
    return FxGamesLibWebPublicDataBaseFromJSONTyped(json, false);
}

export function FxGamesLibWebPublicDataBaseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxGamesLibWebPublicDataBase {
    if (json == null) {
        return json;
    }
    return {
        
        'PlatformData': FxGamesLibWebPlatformDataOptionsFromJSON(json['PlatformData']),
        'Statistics': json['Statistics'] == null ? undefined : FxGamesLibWebPublicDataBaseStatisticsFromJSON(json['Statistics']),
        'GameOrientation': FxIdDomainSettingsEGameOrientationFromJSON(json['GameOrientation']),
        'MobileGameOrientation': FxIdDomainSettingsEGameOrientationFromJSON(json['MobileGameOrientation']),
        'Presentation': json['Presentation'] == null ? undefined : FxGamesLibWebPublicDataBasePresentationFromJSON(json['Presentation']),
        'MobilePresentation': json['MobilePresentation'] == null ? undefined : FxGamesLibWebPublicDataBasePresentationFromJSON(json['MobilePresentation']),
        'ViewportPresentation': json['ViewportPresentation'] == null ? undefined : FxGamesLibWebPublicDataBasePresentationFromJSON(json['ViewportPresentation']),
        'SupportedLanguages': json['SupportedLanguages'],
        'Support': json['Support'] == null ? undefined : FxGamesLibWebPublicDataBaseSupportFromJSON(json['Support']),
        'Store': FxGamesLibWebPublicDataBaseStoreOptionsFromJSON(json['Store']),
        'FxLauncherOptions': json['FxLauncherOptions'] == null ? undefined : FxGamesLibWebPublicDataBaseFxLauncherOptionsFromJSON(json['FxLauncherOptions']),
        'CompanyName': json['CompanyName'],
        'TosUrl': json['TosUrl'],
        'TosUrlOpenMethod': FxIdDomainSettingsUrlOpenMethodFromJSON(json['TosUrlOpenMethod']),
        'PpUrl': json['PpUrl'],
        'PpUrlOpenMethod': FxIdDomainSettingsUrlOpenMethodFromJSON(json['PpUrlOpenMethod']),
        'PublicWebClientConfig': FxIdDomainSettingsPublicWebClientConfigOptionsFromJSON(json['PublicWebClientConfig']),
        'GameSystemName': json['GameSystemName'],
        'NamespacedProductsL10N': json['NamespacedProductsL10N'],
    };
}

export function FxGamesLibWebPublicDataBaseToJSON(json: any): FxGamesLibWebPublicDataBase {
    return FxGamesLibWebPublicDataBaseToJSONTyped(json, false);
}

export function FxGamesLibWebPublicDataBaseToJSONTyped(value?: FxGamesLibWebPublicDataBase | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'PlatformData': FxGamesLibWebPlatformDataOptionsToJSON(value['PlatformData']),
        'Statistics': FxGamesLibWebPublicDataBaseStatisticsToJSON(value['Statistics']),
        'GameOrientation': FxIdDomainSettingsEGameOrientationToJSON(value['GameOrientation']),
        'MobileGameOrientation': FxIdDomainSettingsEGameOrientationToJSON(value['MobileGameOrientation']),
        'Presentation': FxGamesLibWebPublicDataBasePresentationToJSON(value['Presentation']),
        'MobilePresentation': FxGamesLibWebPublicDataBasePresentationToJSON(value['MobilePresentation']),
        'ViewportPresentation': FxGamesLibWebPublicDataBasePresentationToJSON(value['ViewportPresentation']),
        'SupportedLanguages': value['SupportedLanguages'],
        'Support': FxGamesLibWebPublicDataBaseSupportToJSON(value['Support']),
        'Store': FxGamesLibWebPublicDataBaseStoreOptionsToJSON(value['Store']),
        'FxLauncherOptions': FxGamesLibWebPublicDataBaseFxLauncherOptionsToJSON(value['FxLauncherOptions']),
        'CompanyName': value['CompanyName'],
        'TosUrl': value['TosUrl'],
        'TosUrlOpenMethod': FxIdDomainSettingsUrlOpenMethodToJSON(value['TosUrlOpenMethod']),
        'PpUrl': value['PpUrl'],
        'PpUrlOpenMethod': FxIdDomainSettingsUrlOpenMethodToJSON(value['PpUrlOpenMethod']),
        'PublicWebClientConfig': FxIdDomainSettingsPublicWebClientConfigOptionsToJSON(value['PublicWebClientConfig']),
        'GameSystemName': value['GameSystemName'],
        'NamespacedProductsL10N': value['NamespacedProductsL10N'],
    };
}

