/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxGamesLibWebPlatformDataOptionsPlatformDataOptionsVkontakteCom
 */
export interface FxGamesLibWebPlatformDataOptionsPlatformDataOptionsVkontakteCom {
    /**
     * 
     * @type {number}
     * @memberof FxGamesLibWebPlatformDataOptionsPlatformDataOptionsVkontakteCom
     */
    AppId: number;
}

/**
 * Check if a given object implements the FxGamesLibWebPlatformDataOptionsPlatformDataOptionsVkontakteCom interface.
 */
export function instanceOfFxGamesLibWebPlatformDataOptionsPlatformDataOptionsVkontakteCom(value: object): value is FxGamesLibWebPlatformDataOptionsPlatformDataOptionsVkontakteCom {
    if (!('AppId' in value) || value['AppId'] === undefined) return false;
    return true;
}

export function FxGamesLibWebPlatformDataOptionsPlatformDataOptionsVkontakteComFromJSON(json: any): FxGamesLibWebPlatformDataOptionsPlatformDataOptionsVkontakteCom {
    return FxGamesLibWebPlatformDataOptionsPlatformDataOptionsVkontakteComFromJSONTyped(json, false);
}

export function FxGamesLibWebPlatformDataOptionsPlatformDataOptionsVkontakteComFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxGamesLibWebPlatformDataOptionsPlatformDataOptionsVkontakteCom {
    if (json == null) {
        return json;
    }
    return {
        
        'AppId': json['AppId'],
    };
}

export function FxGamesLibWebPlatformDataOptionsPlatformDataOptionsVkontakteComToJSON(json: any): FxGamesLibWebPlatformDataOptionsPlatformDataOptionsVkontakteCom {
    return FxGamesLibWebPlatformDataOptionsPlatformDataOptionsVkontakteComToJSONTyped(json, false);
}

export function FxGamesLibWebPlatformDataOptionsPlatformDataOptionsVkontakteComToJSONTyped(value?: FxGamesLibWebPlatformDataOptionsPlatformDataOptionsVkontakteCom | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'AppId': value['AppId'],
    };
}

