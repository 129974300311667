/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdApplicationStoreYandexGamesYandexBonusErrorResult } from './FxIdApplicationStoreYandexGamesYandexBonusErrorResult';
import {
    instanceOfFxIdApplicationStoreYandexGamesYandexBonusErrorResult,
    FxIdApplicationStoreYandexGamesYandexBonusErrorResultFromJSON,
    FxIdApplicationStoreYandexGamesYandexBonusErrorResultFromJSONTyped,
    FxIdApplicationStoreYandexGamesYandexBonusErrorResultToJSON,
} from './FxIdApplicationStoreYandexGamesYandexBonusErrorResult';

/**
 * @type FxIdApplicationStoreYandexGamesYandexBonusResultDataErrorData
 * 
 * @export
 */
export type FxIdApplicationStoreYandexGamesYandexBonusResultDataErrorData = FxIdApplicationStoreYandexGamesYandexBonusErrorResult;

export function FxIdApplicationStoreYandexGamesYandexBonusResultDataErrorDataFromJSON(json: any): FxIdApplicationStoreYandexGamesYandexBonusResultDataErrorData {
    return FxIdApplicationStoreYandexGamesYandexBonusResultDataErrorDataFromJSONTyped(json, false);
}

export function FxIdApplicationStoreYandexGamesYandexBonusResultDataErrorDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationStoreYandexGamesYandexBonusResultDataErrorData {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdApplicationStoreYandexGamesYandexBonusErrorResult(json)) {
        return FxIdApplicationStoreYandexGamesYandexBonusErrorResultFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdApplicationStoreYandexGamesYandexBonusResultDataErrorDataToJSON(json: any): any {
    return FxIdApplicationStoreYandexGamesYandexBonusResultDataErrorDataToJSONTyped(json, false);
}

export function FxIdApplicationStoreYandexGamesYandexBonusResultDataErrorDataToJSONTyped(value?: FxIdApplicationStoreYandexGamesYandexBonusResultDataErrorData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdApplicationStoreYandexGamesYandexBonusErrorResult(value)) {
        return FxIdApplicationStoreYandexGamesYandexBonusErrorResultToJSON(value as FxIdApplicationStoreYandexGamesYandexBonusErrorResult);
    }

    return {};
}

