/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FxIdDomainEntitiesDocumentsDocument } from './FxIdDomainEntitiesDocumentsDocument';
import {
    FxIdDomainEntitiesDocumentsDocumentFromJSON,
    FxIdDomainEntitiesDocumentsDocumentFromJSONTyped,
    FxIdDomainEntitiesDocumentsDocumentToJSON,
    FxIdDomainEntitiesDocumentsDocumentToJSONTyped,
} from './FxIdDomainEntitiesDocumentsDocument';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesDocumentsGetAllDocumentsWithVersionsGetAllDocumentsWithVersionsResponse
 */
export interface FxIdWebFeaturesDocumentsGetAllDocumentsWithVersionsGetAllDocumentsWithVersionsResponse {
    /**
     * 
     * @type {Array<FxIdDomainEntitiesDocumentsDocument>}
     * @memberof FxIdWebFeaturesDocumentsGetAllDocumentsWithVersionsGetAllDocumentsWithVersionsResponse
     */
    Documents: Array<FxIdDomainEntitiesDocumentsDocument>;
}

/**
 * Check if a given object implements the FxIdWebFeaturesDocumentsGetAllDocumentsWithVersionsGetAllDocumentsWithVersionsResponse interface.
 */
export function instanceOfFxIdWebFeaturesDocumentsGetAllDocumentsWithVersionsGetAllDocumentsWithVersionsResponse(value: object): value is FxIdWebFeaturesDocumentsGetAllDocumentsWithVersionsGetAllDocumentsWithVersionsResponse {
    if (!('Documents' in value) || value['Documents'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesDocumentsGetAllDocumentsWithVersionsGetAllDocumentsWithVersionsResponseFromJSON(json: any): FxIdWebFeaturesDocumentsGetAllDocumentsWithVersionsGetAllDocumentsWithVersionsResponse {
    return FxIdWebFeaturesDocumentsGetAllDocumentsWithVersionsGetAllDocumentsWithVersionsResponseFromJSONTyped(json, false);
}

export function FxIdWebFeaturesDocumentsGetAllDocumentsWithVersionsGetAllDocumentsWithVersionsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesDocumentsGetAllDocumentsWithVersionsGetAllDocumentsWithVersionsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'Documents': ((json['Documents'] as Array<any>).map(FxIdDomainEntitiesDocumentsDocumentFromJSON)),
    };
}

export function FxIdWebFeaturesDocumentsGetAllDocumentsWithVersionsGetAllDocumentsWithVersionsResponseToJSON(json: any): FxIdWebFeaturesDocumentsGetAllDocumentsWithVersionsGetAllDocumentsWithVersionsResponse {
    return FxIdWebFeaturesDocumentsGetAllDocumentsWithVersionsGetAllDocumentsWithVersionsResponseToJSONTyped(json, false);
}

export function FxIdWebFeaturesDocumentsGetAllDocumentsWithVersionsGetAllDocumentsWithVersionsResponseToJSONTyped(value?: FxIdWebFeaturesDocumentsGetAllDocumentsWithVersionsGetAllDocumentsWithVersionsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'Documents': ((value['Documents'] as Array<any>).map(FxIdDomainEntitiesDocumentsDocumentToJSON)),
    };
}

