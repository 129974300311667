/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxGamesLibWebPublicDataBaseStatisticsOptionsDevToDevOptions } from './FxGamesLibWebPublicDataBaseStatisticsOptionsDevToDevOptions';
import {
    instanceOfFxGamesLibWebPublicDataBaseStatisticsOptionsDevToDevOptions,
    FxGamesLibWebPublicDataBaseStatisticsOptionsDevToDevOptionsFromJSON,
    FxGamesLibWebPublicDataBaseStatisticsOptionsDevToDevOptionsFromJSONTyped,
    FxGamesLibWebPublicDataBaseStatisticsOptionsDevToDevOptionsToJSON,
} from './FxGamesLibWebPublicDataBaseStatisticsOptionsDevToDevOptions';

/**
 * @type FxGamesLibWebPublicDataBaseStatisticsOptionsDevToDev
 * 
 * @export
 */
export type FxGamesLibWebPublicDataBaseStatisticsOptionsDevToDev = FxGamesLibWebPublicDataBaseStatisticsOptionsDevToDevOptions;

export function FxGamesLibWebPublicDataBaseStatisticsOptionsDevToDevFromJSON(json: any): FxGamesLibWebPublicDataBaseStatisticsOptionsDevToDev {
    return FxGamesLibWebPublicDataBaseStatisticsOptionsDevToDevFromJSONTyped(json, false);
}

export function FxGamesLibWebPublicDataBaseStatisticsOptionsDevToDevFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxGamesLibWebPublicDataBaseStatisticsOptionsDevToDev {
    if (json == null) {
        return json;
    }
    if (instanceOfFxGamesLibWebPublicDataBaseStatisticsOptionsDevToDevOptions(json)) {
        return FxGamesLibWebPublicDataBaseStatisticsOptionsDevToDevOptionsFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxGamesLibWebPublicDataBaseStatisticsOptionsDevToDevToJSON(json: any): any {
    return FxGamesLibWebPublicDataBaseStatisticsOptionsDevToDevToJSONTyped(json, false);
}

export function FxGamesLibWebPublicDataBaseStatisticsOptionsDevToDevToJSONTyped(value?: FxGamesLibWebPublicDataBaseStatisticsOptionsDevToDev | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxGamesLibWebPublicDataBaseStatisticsOptionsDevToDevOptions(value)) {
        return FxGamesLibWebPublicDataBaseStatisticsOptionsDevToDevOptionsToJSON(value as FxGamesLibWebPublicDataBaseStatisticsOptionsDevToDevOptions);
    }

    return {};
}

