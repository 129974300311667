/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxGamesLibWebFxLauncherOptions
 */
export interface FxGamesLibWebFxLauncherOptions {
    /**
     * 
     * @type {string}
     * @memberof FxGamesLibWebFxLauncherOptions
     */
    AdditionalLauncherArguments?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FxGamesLibWebFxLauncherOptions
     */
    GameSearchPaths: Array<string>;
}

/**
 * Check if a given object implements the FxGamesLibWebFxLauncherOptions interface.
 */
export function instanceOfFxGamesLibWebFxLauncherOptions(value: object): value is FxGamesLibWebFxLauncherOptions {
    if (!('GameSearchPaths' in value) || value['GameSearchPaths'] === undefined) return false;
    return true;
}

export function FxGamesLibWebFxLauncherOptionsFromJSON(json: any): FxGamesLibWebFxLauncherOptions {
    return FxGamesLibWebFxLauncherOptionsFromJSONTyped(json, false);
}

export function FxGamesLibWebFxLauncherOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxGamesLibWebFxLauncherOptions {
    if (json == null) {
        return json;
    }
    return {
        
        'AdditionalLauncherArguments': json['AdditionalLauncherArguments'] == null ? undefined : json['AdditionalLauncherArguments'],
        'GameSearchPaths': json['GameSearchPaths'],
    };
}

export function FxGamesLibWebFxLauncherOptionsToJSON(json: any): FxGamesLibWebFxLauncherOptions {
    return FxGamesLibWebFxLauncherOptionsToJSONTyped(json, false);
}

export function FxGamesLibWebFxLauncherOptionsToJSONTyped(value?: FxGamesLibWebFxLauncherOptions | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'AdditionalLauncherArguments': value['AdditionalLauncherArguments'],
        'GameSearchPaths': value['GameSearchPaths'],
    };
}

