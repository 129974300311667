/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxGamesLibWebPlatformDataOptionsPlatformDataOptionsFacebookGames } from './FxGamesLibWebPlatformDataOptionsPlatformDataOptionsFacebookGames';
import {
    instanceOfFxGamesLibWebPlatformDataOptionsPlatformDataOptionsFacebookGames,
    FxGamesLibWebPlatformDataOptionsPlatformDataOptionsFacebookGamesFromJSON,
    FxGamesLibWebPlatformDataOptionsPlatformDataOptionsFacebookGamesFromJSONTyped,
    FxGamesLibWebPlatformDataOptionsPlatformDataOptionsFacebookGamesToJSON,
} from './FxGamesLibWebPlatformDataOptionsPlatformDataOptionsFacebookGames';

/**
 * @type FxGamesLibWebPlatformDataOptionsFacebookGames
 * 
 * @export
 */
export type FxGamesLibWebPlatformDataOptionsFacebookGames = FxGamesLibWebPlatformDataOptionsPlatformDataOptionsFacebookGames;

export function FxGamesLibWebPlatformDataOptionsFacebookGamesFromJSON(json: any): FxGamesLibWebPlatformDataOptionsFacebookGames {
    return FxGamesLibWebPlatformDataOptionsFacebookGamesFromJSONTyped(json, false);
}

export function FxGamesLibWebPlatformDataOptionsFacebookGamesFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxGamesLibWebPlatformDataOptionsFacebookGames {
    if (json == null) {
        return json;
    }
    if (instanceOfFxGamesLibWebPlatformDataOptionsPlatformDataOptionsFacebookGames(json)) {
        return FxGamesLibWebPlatformDataOptionsPlatformDataOptionsFacebookGamesFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxGamesLibWebPlatformDataOptionsFacebookGamesToJSON(json: any): any {
    return FxGamesLibWebPlatformDataOptionsFacebookGamesToJSONTyped(json, false);
}

export function FxGamesLibWebPlatformDataOptionsFacebookGamesToJSONTyped(value?: FxGamesLibWebPlatformDataOptionsFacebookGames | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxGamesLibWebPlatformDataOptionsPlatformDataOptionsFacebookGames(value)) {
        return FxGamesLibWebPlatformDataOptionsPlatformDataOptionsFacebookGamesToJSON(value as FxGamesLibWebPlatformDataOptionsPlatformDataOptionsFacebookGames);
    }

    return {};
}

