/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxGamesLibWebPlatformDataOptionsWebOptionsWebRuntimesOptions } from './FxGamesLibWebPlatformDataOptionsWebOptionsWebRuntimesOptions';
import {
    instanceOfFxGamesLibWebPlatformDataOptionsWebOptionsWebRuntimesOptions,
    FxGamesLibWebPlatformDataOptionsWebOptionsWebRuntimesOptionsFromJSON,
    FxGamesLibWebPlatformDataOptionsWebOptionsWebRuntimesOptionsFromJSONTyped,
    FxGamesLibWebPlatformDataOptionsWebOptionsWebRuntimesOptionsToJSON,
} from './FxGamesLibWebPlatformDataOptionsWebOptionsWebRuntimesOptions';

/**
 * @type FxGamesLibWebPlatformDataOptionsWebOptionsRuntimes
 * 
 * @export
 */
export type FxGamesLibWebPlatformDataOptionsWebOptionsRuntimes = FxGamesLibWebPlatformDataOptionsWebOptionsWebRuntimesOptions;

export function FxGamesLibWebPlatformDataOptionsWebOptionsRuntimesFromJSON(json: any): FxGamesLibWebPlatformDataOptionsWebOptionsRuntimes {
    return FxGamesLibWebPlatformDataOptionsWebOptionsRuntimesFromJSONTyped(json, false);
}

export function FxGamesLibWebPlatformDataOptionsWebOptionsRuntimesFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxGamesLibWebPlatformDataOptionsWebOptionsRuntimes {
    if (json == null) {
        return json;
    }
    if (instanceOfFxGamesLibWebPlatformDataOptionsWebOptionsWebRuntimesOptions(json)) {
        return FxGamesLibWebPlatformDataOptionsWebOptionsWebRuntimesOptionsFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxGamesLibWebPlatformDataOptionsWebOptionsRuntimesToJSON(json: any): any {
    return FxGamesLibWebPlatformDataOptionsWebOptionsRuntimesToJSONTyped(json, false);
}

export function FxGamesLibWebPlatformDataOptionsWebOptionsRuntimesToJSONTyped(value?: FxGamesLibWebPlatformDataOptionsWebOptionsRuntimes | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxGamesLibWebPlatformDataOptionsWebOptionsWebRuntimesOptions(value)) {
        return FxGamesLibWebPlatformDataOptionsWebOptionsWebRuntimesOptionsToJSON(value as FxGamesLibWebPlatformDataOptionsWebOptionsWebRuntimesOptions);
    }

    return {};
}

