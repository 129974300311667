import {
	ActionIcon,
	Avatar,
	Box,
	Button,
	CloseButton,
	Group,
	Space,
	Stack,
	Text,
	useMantineTheme
} from "@mantine/core";
import { useElementSize, useMediaQuery } from "@mantine/hooks";
import { ContextModalProps, closeModal, openContextModal } from "@mantine/modals";
import React from "react";
import { MdAddCircle } from "react-icons/md";
import { useFriendsQuery } from "../../Api/Queries/friendsQuery";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { FxIdWebFeaturesFriendsGetFriendsResponseFriendData } from "../../Api/gen";
import { IFxIdSdkAdapterInviteFriendsRequest } from "../../SDK/FxIdSdkBaseAdapter";

const PLACEHOLDER_INVITE_TEXT = "";

type ModalProps = { game: string };

export const SocialModal = (props: ContextModalProps<ModalProps>) => {
	return (
		<>
			<Header game={props.innerProps.game} />
			<Box sx={{ overflowY: "auto" }}>
				<FriendsInGame game={props.innerProps.game} />
			</Box>
		</>
	);
};

const Header = (props: { game: string; withCloseButton?: boolean }) => {
	const { t } = useTranslation();
	const { game, withCloseButton = true } = props;
	const { data } = useFriendsQuery();
	const friendsInGameAccountIds =
		data?.filter((f) => f.Game === game).reduce((acc, cur) => acc.add(cur.FriendAccountId), new Set<number>()) ??
		new Set<number>();

	const { ref, width } = useElementSize();
	const theme = useMantineTheme();
	const mdScreen = useMediaQuery(theme.fn.largerThan("xs").split("@media ")[1]);
	return (
		<Box>
			<Group spacing={0} position="apart" sx={{ position: "relative" }}>
				<Box ref={ref}>
					{mdScreen ? (
						<Button
							leftIcon={<MdAddCircle size={24} />}
							variant="gradient"
							gradient={{ from: "blue", to: "green" }}
							onClick={() => inviteFriends()}
						>
							{t("social_modal.invite_friends")}
						</Button>
					) : (
						<ActionIcon variant="gradient" gradient={{ from: "blue", to: "green" }} size={36}>
							<MdAddCircle size={24} />
						</ActionIcon>
					)}
				</Box>
				<Text fw="bold" size="lg">
					{t("social_modal.title", { amount: friendsInGameAccountIds.size })}
				</Text>
				<Box sx={{ width, display: "flex" }}>
					<Space sx={{ flexGrow: 1 }} />
					{withCloseButton && (
						<CloseButton
							sx={{
								position: "absolute",
								top: 0,
								right: 0,
								bottom: 0,
								marginTop: "auto",
								marginBottom: "auto"
							}}
							onClick={() => closeModal("social")}
						/>
					)}
				</Box>
			</Group>
			<Space h="sm" />
		</Box>
	);
};

const FriendsInGame = (props: { game: string }) => {
	const { t } = useTranslation();
	const { game } = props;
	const { data } = useFriendsQuery();

	const minUpdateDate = new Date();
	minUpdateDate.setFullYear(minUpdateDate.getFullYear() - 1);

	const friendUpdates = data?.filter((f) => f.Game === game && f.LastUpdatedAt > minUpdateDate) ?? [];
	return (
		<Box>
			{friendUpdates.length === 0 && (
				<Text fw="bold" size="lg" align="center">
					{t("social_modal.no_friends_yet")}
				</Text>
			)}
			{friendUpdates.length > 0 && (
				<>
					<Text fw="bold" size="lg">
						{t("social_modal.status_updates")}
					</Text>
					<Space h="sm" />
					<Stack>
						{friendUpdates.map((f, idx) => (
							<StatusUpdate key={idx} friend={f} />
						))}
					</Stack>
				</>
			)}
		</Box>
	);
};

const StatusUpdate = (props: { friend: FxIdWebFeaturesFriendsGetFriendsResponseFriendData }) => {
	const { t } = useTranslation();
	const { friend } = props;
	const name = friend.Name ?? t("player");
	const status = friend.Status ?? t("social_modal.status_update_text.online", { name });
	return (
		<Group align="flex-start">
			<Avatar
				radius="xl"
				src={`https://avatars.dicebear.com/api/initials/${encodeURIComponent(String(name))}.svg`}
			/>
			<Stack spacing={0} sx={{ flexGrow: 1 }}>
				<Text fw="bold">[{name}]</Text>
				<Text size="xs">{dayjs.utc(friend.LastUpdatedAt).fromNow()}</Text>
				<Text>{status}</Text>
			</Stack>
		</Group>
	);
};

export const openSocialModal = (game: string) =>
	openContextModal({
		modal: "social",
		innerProps: { game },
		withCloseButton: false,
		overflow: "inside",
		styles: { body: { overflowY: "unset", display: "flex", flexDirection: "column" } }
	});

export const inviteFriends = async () => {
	const options: IFxIdSdkAdapterInviteFriendsRequest = { inviteText: PLACEHOLDER_INVITE_TEXT };
	const request = JSON.stringify(options);
	closeModal("social");
	await window.FxIdSdk?.InviteFriends(request);
};
