/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdDomainEntitiesDocumentsGameDocumentVersion
 */
export interface FxIdDomainEntitiesDocumentsGameDocumentVersion {
    /**
     * 
     * @type {number}
     * @memberof FxIdDomainEntitiesDocumentsGameDocumentVersion
     */
    Id: number;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainEntitiesDocumentsGameDocumentVersion
     */
    Url: string;
    /**
     * 
     * @type {Date}
     * @memberof FxIdDomainEntitiesDocumentsGameDocumentVersion
     */
    CreatedAt: Date;
    /**
     * 
     * @type {number}
     * @memberof FxIdDomainEntitiesDocumentsGameDocumentVersion
     */
    DocumentVersionId: number;
}

/**
 * Check if a given object implements the FxIdDomainEntitiesDocumentsGameDocumentVersion interface.
 */
export function instanceOfFxIdDomainEntitiesDocumentsGameDocumentVersion(value: object): value is FxIdDomainEntitiesDocumentsGameDocumentVersion {
    if (!('Id' in value) || value['Id'] === undefined) return false;
    if (!('Url' in value) || value['Url'] === undefined) return false;
    if (!('CreatedAt' in value) || value['CreatedAt'] === undefined) return false;
    if (!('DocumentVersionId' in value) || value['DocumentVersionId'] === undefined) return false;
    return true;
}

export function FxIdDomainEntitiesDocumentsGameDocumentVersionFromJSON(json: any): FxIdDomainEntitiesDocumentsGameDocumentVersion {
    return FxIdDomainEntitiesDocumentsGameDocumentVersionFromJSONTyped(json, false);
}

export function FxIdDomainEntitiesDocumentsGameDocumentVersionFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainEntitiesDocumentsGameDocumentVersion {
    if (json == null) {
        return json;
    }
    return {
        
        'Id': json['Id'],
        'Url': json['Url'],
        'CreatedAt': (new Date(json['CreatedAt'])),
        'DocumentVersionId': json['DocumentVersionId'],
    };
}

export function FxIdDomainEntitiesDocumentsGameDocumentVersionToJSON(json: any): FxIdDomainEntitiesDocumentsGameDocumentVersion {
    return FxIdDomainEntitiesDocumentsGameDocumentVersionToJSONTyped(json, false);
}

export function FxIdDomainEntitiesDocumentsGameDocumentVersionToJSONTyped(value?: FxIdDomainEntitiesDocumentsGameDocumentVersion | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'Id': value['Id'],
        'Url': value['Url'],
        'CreatedAt': ((value['CreatedAt']).toISOString()),
        'DocumentVersionId': value['DocumentVersionId'],
    };
}

