/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxGamesLibWebPlatformDataOptionsWebOptions } from './FxGamesLibWebPlatformDataOptionsWebOptions';
import {
    instanceOfFxGamesLibWebPlatformDataOptionsWebOptions,
    FxGamesLibWebPlatformDataOptionsWebOptionsFromJSON,
    FxGamesLibWebPlatformDataOptionsWebOptionsFromJSONTyped,
    FxGamesLibWebPlatformDataOptionsWebOptionsToJSON,
} from './FxGamesLibWebPlatformDataOptionsWebOptions';

/**
 * @type FxGamesLibWebPlatformDataOptionsWeb
 * 
 * @export
 */
export type FxGamesLibWebPlatformDataOptionsWeb = FxGamesLibWebPlatformDataOptionsWebOptions;

export function FxGamesLibWebPlatformDataOptionsWebFromJSON(json: any): FxGamesLibWebPlatformDataOptionsWeb {
    return FxGamesLibWebPlatformDataOptionsWebFromJSONTyped(json, false);
}

export function FxGamesLibWebPlatformDataOptionsWebFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxGamesLibWebPlatformDataOptionsWeb {
    if (json == null) {
        return json;
    }
    if (instanceOfFxGamesLibWebPlatformDataOptionsWebOptions(json)) {
        return FxGamesLibWebPlatformDataOptionsWebOptionsFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxGamesLibWebPlatformDataOptionsWebToJSON(json: any): any {
    return FxGamesLibWebPlatformDataOptionsWebToJSONTyped(json, false);
}

export function FxGamesLibWebPlatformDataOptionsWebToJSONTyped(value?: FxGamesLibWebPlatformDataOptionsWeb | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxGamesLibWebPlatformDataOptionsWebOptions(value)) {
        return FxGamesLibWebPlatformDataOptionsWebOptionsToJSON(value as FxGamesLibWebPlatformDataOptionsWebOptions);
    }

    return {};
}

