/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FastEndpointsErrorResponse,
  FxGamesLibWebRpcContractsResponse,
  FxIdApplicationHandlersStoreUpdateOrderPIHandlerUpdateOrderPersonalInfoCommand,
  FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult,
  FxIdDomainStoreEnumsSupportedWebPublishingPlatform,
  FxIdWebFeaturesStoreContinueRedirectWithPiRequest,
  FxIdWebFeaturesStoreContinueRedirectWithPiResponse,
  FxIdWebFeaturesStoreCreatePaymentRequest,
  FxIdWebFeaturesStoreGetPaymentStatusRequest,
  FxIdWebFeaturesStoreGetPaymentStatusResponse,
  FxIdWebFeaturesStoreMerchantPaymentCompletedRedirectRequest,
  FxIdWebFeaturesStoreMerchantPaymentCompletedRedirectResponse,
  FxIdWebFeaturesStoreStoreAvailabilityResponse,
  FxIdWebFeaturesStoreStoreDataRequest,
  FxIdWebFeaturesStoreStoreDataResponse,
} from '../models/index';
import {
    FastEndpointsErrorResponseFromJSON,
    FastEndpointsErrorResponseToJSON,
    FxGamesLibWebRpcContractsResponseFromJSON,
    FxGamesLibWebRpcContractsResponseToJSON,
    FxIdApplicationHandlersStoreUpdateOrderPIHandlerUpdateOrderPersonalInfoCommandFromJSON,
    FxIdApplicationHandlersStoreUpdateOrderPIHandlerUpdateOrderPersonalInfoCommandToJSON,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFromJSON,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultToJSON,
    FxIdDomainStoreEnumsSupportedWebPublishingPlatformFromJSON,
    FxIdDomainStoreEnumsSupportedWebPublishingPlatformToJSON,
    FxIdWebFeaturesStoreContinueRedirectWithPiRequestFromJSON,
    FxIdWebFeaturesStoreContinueRedirectWithPiRequestToJSON,
    FxIdWebFeaturesStoreContinueRedirectWithPiResponseFromJSON,
    FxIdWebFeaturesStoreContinueRedirectWithPiResponseToJSON,
    FxIdWebFeaturesStoreCreatePaymentRequestFromJSON,
    FxIdWebFeaturesStoreCreatePaymentRequestToJSON,
    FxIdWebFeaturesStoreGetPaymentStatusRequestFromJSON,
    FxIdWebFeaturesStoreGetPaymentStatusRequestToJSON,
    FxIdWebFeaturesStoreGetPaymentStatusResponseFromJSON,
    FxIdWebFeaturesStoreGetPaymentStatusResponseToJSON,
    FxIdWebFeaturesStoreMerchantPaymentCompletedRedirectRequestFromJSON,
    FxIdWebFeaturesStoreMerchantPaymentCompletedRedirectRequestToJSON,
    FxIdWebFeaturesStoreMerchantPaymentCompletedRedirectResponseFromJSON,
    FxIdWebFeaturesStoreMerchantPaymentCompletedRedirectResponseToJSON,
    FxIdWebFeaturesStoreStoreAvailabilityResponseFromJSON,
    FxIdWebFeaturesStoreStoreAvailabilityResponseToJSON,
    FxIdWebFeaturesStoreStoreDataRequestFromJSON,
    FxIdWebFeaturesStoreStoreDataRequestToJSON,
    FxIdWebFeaturesStoreStoreDataResponseFromJSON,
    FxIdWebFeaturesStoreStoreDataResponseToJSON,
} from '../models/index';

export interface FxIdWebFeaturesStoreContinueRedirectWithPiEndpointRequest {
    fxIdWebFeaturesStoreContinueRedirectWithPiRequest: FxIdWebFeaturesStoreContinueRedirectWithPiRequest;
}

export interface FxIdWebFeaturesStoreCreatePaymentEndpointRequest {
    fxIdWebFeaturesStoreCreatePaymentRequest: FxIdWebFeaturesStoreCreatePaymentRequest;
}

export interface FxIdWebFeaturesStoreCreatePaymentWithRedirectEndpointRequest {
    game: string;
    sku: string;
    token: string;
    webPublishingPlatform: FxIdDomainStoreEnumsSupportedWebPublishingPlatform;
    currency?: string | null;
    country?: string | null;
    developerPayload?: string | null;
    productNameHint?: string | null;
    productDescriptionHint?: string | null;
}

export interface FxIdWebFeaturesStoreGetPaymentStatusEndpointRequest {
    fxIdWebFeaturesStoreGetPaymentStatusRequest: FxIdWebFeaturesStoreGetPaymentStatusRequest;
}

export interface FxIdWebFeaturesStoreMerchantPaymentCompletedRedirectEndpointRequest {
    fxIdWebFeaturesStoreMerchantPaymentCompletedRedirectRequest: FxIdWebFeaturesStoreMerchantPaymentCompletedRedirectRequest;
}

export interface FxIdWebFeaturesStoreStoreDataEndpointRequest {
    fxIdWebFeaturesStoreStoreDataRequest: FxIdWebFeaturesStoreStoreDataRequest;
}

export interface FxIdWebFeaturesStoreUpdateOrderPISendSupportMessageEndpointRequest {
    fxIdApplicationHandlersStoreUpdateOrderPIHandlerUpdateOrderPersonalInfoCommand: FxIdApplicationHandlersStoreUpdateOrderPIHandlerUpdateOrderPersonalInfoCommand;
}

/**
 * 
 */
export class StoreApi extends runtime.BaseAPI {

    /**
     * Это роут для поддержки сбора персональных данных пользователя при работе через FxNative и CreatePaymentWithRedirect
     */
    async fxIdWebFeaturesStoreContinueRedirectWithPiEndpointRaw(requestParameters: FxIdWebFeaturesStoreContinueRedirectWithPiEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FxIdWebFeaturesStoreContinueRedirectWithPiResponse>> {
        if (requestParameters['fxIdWebFeaturesStoreContinueRedirectWithPiRequest'] == null) {
            throw new runtime.RequiredError(
                'fxIdWebFeaturesStoreContinueRedirectWithPiRequest',
                'Required parameter "fxIdWebFeaturesStoreContinueRedirectWithPiRequest" was null or undefined when calling fxIdWebFeaturesStoreContinueRedirectWithPiEndpoint().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/store/continue_redirect_with_pi`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FxIdWebFeaturesStoreContinueRedirectWithPiRequestToJSON(requestParameters['fxIdWebFeaturesStoreContinueRedirectWithPiRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FxIdWebFeaturesStoreContinueRedirectWithPiResponseFromJSON(jsonValue));
    }

    /**
     * Это роут для поддержки сбора персональных данных пользователя при работе через FxNative и CreatePaymentWithRedirect
     */
    async fxIdWebFeaturesStoreContinueRedirectWithPiEndpoint(fxIdWebFeaturesStoreContinueRedirectWithPiRequest: FxIdWebFeaturesStoreContinueRedirectWithPiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FxIdWebFeaturesStoreContinueRedirectWithPiResponse> {
        const response = await this.fxIdWebFeaturesStoreContinueRedirectWithPiEndpointRaw({ fxIdWebFeaturesStoreContinueRedirectWithPiRequest: fxIdWebFeaturesStoreContinueRedirectWithPiRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async fxIdWebFeaturesStoreCreatePaymentEndpointRaw(requestParameters: FxIdWebFeaturesStoreCreatePaymentEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult>> {
        if (requestParameters['fxIdWebFeaturesStoreCreatePaymentRequest'] == null) {
            throw new runtime.RequiredError(
                'fxIdWebFeaturesStoreCreatePaymentRequest',
                'Required parameter "fxIdWebFeaturesStoreCreatePaymentRequest" was null or undefined when calling fxIdWebFeaturesStoreCreatePaymentEndpoint().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWTBearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/store/create_payment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FxIdWebFeaturesStoreCreatePaymentRequestToJSON(requestParameters['fxIdWebFeaturesStoreCreatePaymentRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFromJSON(jsonValue));
    }

    /**
     */
    async fxIdWebFeaturesStoreCreatePaymentEndpoint(fxIdWebFeaturesStoreCreatePaymentRequest: FxIdWebFeaturesStoreCreatePaymentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult> {
        const response = await this.fxIdWebFeaturesStoreCreatePaymentEndpointRaw({ fxIdWebFeaturesStoreCreatePaymentRequest: fxIdWebFeaturesStoreCreatePaymentRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async fxIdWebFeaturesStoreCreatePaymentWithRedirectEndpointRaw(requestParameters: FxIdWebFeaturesStoreCreatePaymentWithRedirectEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['game'] == null) {
            throw new runtime.RequiredError(
                'game',
                'Required parameter "game" was null or undefined when calling fxIdWebFeaturesStoreCreatePaymentWithRedirectEndpoint().'
            );
        }

        if (requestParameters['sku'] == null) {
            throw new runtime.RequiredError(
                'sku',
                'Required parameter "sku" was null or undefined when calling fxIdWebFeaturesStoreCreatePaymentWithRedirectEndpoint().'
            );
        }

        if (requestParameters['token'] == null) {
            throw new runtime.RequiredError(
                'token',
                'Required parameter "token" was null or undefined when calling fxIdWebFeaturesStoreCreatePaymentWithRedirectEndpoint().'
            );
        }

        if (requestParameters['webPublishingPlatform'] == null) {
            throw new runtime.RequiredError(
                'webPublishingPlatform',
                'Required parameter "webPublishingPlatform" was null or undefined when calling fxIdWebFeaturesStoreCreatePaymentWithRedirectEndpoint().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['token'] != null) {
            queryParameters['token'] = requestParameters['token'];
        }

        if (requestParameters['currency'] != null) {
            queryParameters['currency'] = requestParameters['currency'];
        }

        if (requestParameters['country'] != null) {
            queryParameters['country'] = requestParameters['country'];
        }

        if (requestParameters['webPublishingPlatform'] != null) {
            queryParameters['webPublishingPlatform'] = requestParameters['webPublishingPlatform'];
        }

        if (requestParameters['developerPayload'] != null) {
            queryParameters['developerPayload'] = requestParameters['developerPayload'];
        }

        if (requestParameters['productNameHint'] != null) {
            queryParameters['productNameHint'] = requestParameters['productNameHint'];
        }

        if (requestParameters['productDescriptionHint'] != null) {
            queryParameters['productDescriptionHint'] = requestParameters['productDescriptionHint'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/store/create_payment_with_redirect/{game}/{sku}`.replace(`{${"game"}}`, encodeURIComponent(String(requestParameters['game']))).replace(`{${"sku"}}`, encodeURIComponent(String(requestParameters['sku']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async fxIdWebFeaturesStoreCreatePaymentWithRedirectEndpoint(game: string, sku: string, token: string, webPublishingPlatform: FxIdDomainStoreEnumsSupportedWebPublishingPlatform, currency?: string | null, country?: string | null, developerPayload?: string | null, productNameHint?: string | null, productDescriptionHint?: string | null, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.fxIdWebFeaturesStoreCreatePaymentWithRedirectEndpointRaw({ game: game, sku: sku, token: token, webPublishingPlatform: webPublishingPlatform, currency: currency, country: country, developerPayload: developerPayload, productNameHint: productNameHint, productDescriptionHint: productDescriptionHint }, initOverrides);
        return await response.value();
    }

    /**
     */
    async fxIdWebFeaturesStoreGetPaymentStatusEndpointRaw(requestParameters: FxIdWebFeaturesStoreGetPaymentStatusEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FxIdWebFeaturesStoreGetPaymentStatusResponse>> {
        if (requestParameters['fxIdWebFeaturesStoreGetPaymentStatusRequest'] == null) {
            throw new runtime.RequiredError(
                'fxIdWebFeaturesStoreGetPaymentStatusRequest',
                'Required parameter "fxIdWebFeaturesStoreGetPaymentStatusRequest" was null or undefined when calling fxIdWebFeaturesStoreGetPaymentStatusEndpoint().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWTBearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/store/get_payment_status`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FxIdWebFeaturesStoreGetPaymentStatusRequestToJSON(requestParameters['fxIdWebFeaturesStoreGetPaymentStatusRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FxIdWebFeaturesStoreGetPaymentStatusResponseFromJSON(jsonValue));
    }

    /**
     */
    async fxIdWebFeaturesStoreGetPaymentStatusEndpoint(fxIdWebFeaturesStoreGetPaymentStatusRequest: FxIdWebFeaturesStoreGetPaymentStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FxIdWebFeaturesStoreGetPaymentStatusResponse> {
        const response = await this.fxIdWebFeaturesStoreGetPaymentStatusEndpointRaw({ fxIdWebFeaturesStoreGetPaymentStatusRequest: fxIdWebFeaturesStoreGetPaymentStatusRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async fxIdWebFeaturesStoreMerchantPaymentCompletedRedirectEndpointRaw(requestParameters: FxIdWebFeaturesStoreMerchantPaymentCompletedRedirectEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FxIdWebFeaturesStoreMerchantPaymentCompletedRedirectResponse>> {
        if (requestParameters['fxIdWebFeaturesStoreMerchantPaymentCompletedRedirectRequest'] == null) {
            throw new runtime.RequiredError(
                'fxIdWebFeaturesStoreMerchantPaymentCompletedRedirectRequest',
                'Required parameter "fxIdWebFeaturesStoreMerchantPaymentCompletedRedirectRequest" was null or undefined when calling fxIdWebFeaturesStoreMerchantPaymentCompletedRedirectEndpoint().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/store/merchant_payment_completed`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FxIdWebFeaturesStoreMerchantPaymentCompletedRedirectRequestToJSON(requestParameters['fxIdWebFeaturesStoreMerchantPaymentCompletedRedirectRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FxIdWebFeaturesStoreMerchantPaymentCompletedRedirectResponseFromJSON(jsonValue));
    }

    /**
     */
    async fxIdWebFeaturesStoreMerchantPaymentCompletedRedirectEndpoint(fxIdWebFeaturesStoreMerchantPaymentCompletedRedirectRequest: FxIdWebFeaturesStoreMerchantPaymentCompletedRedirectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FxIdWebFeaturesStoreMerchantPaymentCompletedRedirectResponse> {
        const response = await this.fxIdWebFeaturesStoreMerchantPaymentCompletedRedirectEndpointRaw({ fxIdWebFeaturesStoreMerchantPaymentCompletedRedirectRequest: fxIdWebFeaturesStoreMerchantPaymentCompletedRedirectRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async fxIdWebFeaturesStoreStoreAvailabilityEndpointRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FxIdWebFeaturesStoreStoreAvailabilityResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/store/store_availability`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FxIdWebFeaturesStoreStoreAvailabilityResponseFromJSON(jsonValue));
    }

    /**
     */
    async fxIdWebFeaturesStoreStoreAvailabilityEndpoint(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FxIdWebFeaturesStoreStoreAvailabilityResponse> {
        const response = await this.fxIdWebFeaturesStoreStoreAvailabilityEndpointRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async fxIdWebFeaturesStoreStoreDataEndpointRaw(requestParameters: FxIdWebFeaturesStoreStoreDataEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FxIdWebFeaturesStoreStoreDataResponse>> {
        if (requestParameters['fxIdWebFeaturesStoreStoreDataRequest'] == null) {
            throw new runtime.RequiredError(
                'fxIdWebFeaturesStoreStoreDataRequest',
                'Required parameter "fxIdWebFeaturesStoreStoreDataRequest" was null or undefined when calling fxIdWebFeaturesStoreStoreDataEndpoint().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/store/store_data`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FxIdWebFeaturesStoreStoreDataRequestToJSON(requestParameters['fxIdWebFeaturesStoreStoreDataRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FxIdWebFeaturesStoreStoreDataResponseFromJSON(jsonValue));
    }

    /**
     */
    async fxIdWebFeaturesStoreStoreDataEndpoint(fxIdWebFeaturesStoreStoreDataRequest: FxIdWebFeaturesStoreStoreDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FxIdWebFeaturesStoreStoreDataResponse> {
        const response = await this.fxIdWebFeaturesStoreStoreDataEndpointRaw({ fxIdWebFeaturesStoreStoreDataRequest: fxIdWebFeaturesStoreStoreDataRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async fxIdWebFeaturesStoreUpdateOrderPISendSupportMessageEndpointRaw(requestParameters: FxIdWebFeaturesStoreUpdateOrderPISendSupportMessageEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FxGamesLibWebRpcContractsResponse>> {
        if (requestParameters['fxIdApplicationHandlersStoreUpdateOrderPIHandlerUpdateOrderPersonalInfoCommand'] == null) {
            throw new runtime.RequiredError(
                'fxIdApplicationHandlersStoreUpdateOrderPIHandlerUpdateOrderPersonalInfoCommand',
                'Required parameter "fxIdApplicationHandlersStoreUpdateOrderPIHandlerUpdateOrderPersonalInfoCommand" was null or undefined when calling fxIdWebFeaturesStoreUpdateOrderPISendSupportMessageEndpoint().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWTBearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/store/update-order-pi`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FxIdApplicationHandlersStoreUpdateOrderPIHandlerUpdateOrderPersonalInfoCommandToJSON(requestParameters['fxIdApplicationHandlersStoreUpdateOrderPIHandlerUpdateOrderPersonalInfoCommand']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FxGamesLibWebRpcContractsResponseFromJSON(jsonValue));
    }

    /**
     */
    async fxIdWebFeaturesStoreUpdateOrderPISendSupportMessageEndpoint(fxIdApplicationHandlersStoreUpdateOrderPIHandlerUpdateOrderPersonalInfoCommand: FxIdApplicationHandlersStoreUpdateOrderPIHandlerUpdateOrderPersonalInfoCommand, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FxGamesLibWebRpcContractsResponse> {
        const response = await this.fxIdWebFeaturesStoreUpdateOrderPISendSupportMessageEndpointRaw({ fxIdApplicationHandlersStoreUpdateOrderPIHandlerUpdateOrderPersonalInfoCommand: fxIdApplicationHandlersStoreUpdateOrderPIHandlerUpdateOrderPersonalInfoCommand }, initOverrides);
        return await response.value();
    }

}
